import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Home, ChevronRight } from "lucide-react";
// import { clearBreadcrumbs, handleBreadcumsClick } from "./BreadcrumbsSlice";
import { motion } from "framer-motion";
import { handleBreadcumsClick } from "./BreadcrumbsSlice";

interface Breadcrumb {
  title: string;
  url: string;
  icon?: React.ReactNode;
}

const Breadcrumbs: React.FC = () => {
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(
    (state: any) => state?.breadcrumbs?.breadcrumbs
  );

  const breadcrumbList: Breadcrumb[] = [
    {
      title: "Home",
      url: "/dashboard",
      icon: <Home className="w-4 h-4" />,
    },
    ...breadcrumbs,
  ];
  const navigate = useNavigate();
  const handleDispatch = (url: string, index: number) => {
    navigate(url);
    const filter: any = [];
    for (let i = 0; i < breadcrumbs.length; i++) {
      const ele = breadcrumbs[i];
      if (i === index) {
        break;
      }
      filter.push(ele);
    }

    dispatch(handleBreadcumsClick(filter));
  };

  return (
    <nav className="flex items-center p-4">
      {breadcrumbList.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && <ChevronRight className="w-4 h-4 mx-2 text-gray-400" />}
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <span
              onClick={() => handleDispatch(breadcrumb.url, index)}
              //   to={{breadcrumb.url}}
              className={`flex items-center text-sm group transition-all duration-300 ease-in-out ${
                index === breadcrumbList.length - 1
                  ? "font-semibold text-orange-600"
                  : "text-gray-600 hover:text-orange-500"
              }`}
            >
              {breadcrumb.icon && (
                <span className="mr-1.5 group-hover:text-orange-500 transition-colors duration-300">
                  {breadcrumb.icon}
                </span>
              )}
              <span className="relative">
                {breadcrumb.title}
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-orange-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
              </span>
            </span>
          </motion.div>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
