import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_IMAGE, getData, postData } from "../../../services/apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";

const ParticularFeedbackEdit = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const reviewID = searchParams.get("review_id");
  const initialReviewText = searchParams.get("review_txt") || "";
  const userName = searchParams.get("first_name");
  const navigate = useNavigate();

  console.log(reviewID);

  const [reviewText, setReviewText] = useState(initialReviewText);

  const { mutate, isPending } = useMutation({
    mutationFn: (data: { reviewId: string; reviewText: string }) =>
      postData(`review/update-review-text/${data.reviewId}`, {
        review_txt: data.reviewText,
      }),
    onSuccess: () => {
      toast.success("User Feedback successfully");
      setReviewText("");
      setModalOpen(false);
      navigate(-1);
    },
    onError: () => {
      toast.error("Failed to update review");
    },
  });

  const { data: Media, refetch: getMediaRefetch } = useQuery({
    queryKey: ["getMediabyReviewID", reviewID],
    queryFn: () => getData(`media/get-media-by-review-id/${reviewID}`),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewText(event.target.value);
  };

  const handleSubmit = (reviewID: any) => {
    if (reviewID) {
      mutate({ reviewId: reviewID, reviewText });
    }
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const deleteMediaMutation = useMutation({
    mutationFn: (mediaId: number) => postData(`media/delete-media/${mediaId}`),
    onSuccess: () => {
      toast.success("Media deleted successfully");
      setModalOpen(false);
      getMediaRefetch();
    },
    onError: (error) => {
      toast.error("Failed to delete media");
      setModalOpen(false);
      console.error("Delete media error:", error);
    },
  });

  const handleMediaDelete = (mediaId: number) => {
    deleteMediaMutation.mutate(mediaId);
  };

  return (
    <div className="mt-4">
      <div className="bg-white rounded-md border border-gray-300 p-4">
        <div>
          <p className="text-black font-medium">Edit {userName} Feedback</p>
        </div>
        <div className="ml-2 border border-gray-300 mt-4 rounded-md">
          <input
            value={reviewText}
            onChange={handleInputChange}
            type="text"
            className="w-full h-10 focus:outline-none focus:border-none rounded-md p-4"
          />
        </div>

        <div>
          {Media?.data?.map((item: any, i: any) => (
            <div key={i} className="ml-4 mt-4 relative inline-block">
              {item?.type === "video" ? (
                <video
                  src={`${BASE_IMAGE}${item?.normal_img}`}
                  className="h-28 w-28 rounded-md"
                  controls
                ></video>
              ) : (
                <img
                  src={`${BASE_IMAGE}${item?.normal_img}`}
                  alt=""
                  className="h-28 w-28 rounded-md"
                />
              )}

              <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger>
                  <button className="absolute -top-2 -right-2 bg-red-600 h-6 w-6 rounded-full flex items-center justify-center">
                    <FaTimes className="text-sm text-white" />
                  </button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Delete Media Item?</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to delete this media item? This
                      action cannot be undone and will permanently remove the
                      item from your library.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="flex justify-end space-x-4 mt-4">
                    <button
                      className="bg-red-600 text-white px-4 py-2 rounded-md"
                      onClick={() => handleMediaDelete(item?.media_id)}
                    >
                      Delete
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          ))}
        </div>

        <div className="w-full flex justify-end items-center gap-4 mt-4">
          <p className="text-blue-500 cursor-pointer" onClick={handleNavigate}>
            Cancel
          </p>
          <button
            className={`text-orange-500 cursor-pointer ${
              isPending ? "opacity-50" : ""
            }`}
            onClick={() => handleSubmit(reviewID)}
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParticularFeedbackEdit;
