import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify"; // For notifications, if you're using react-toastify
import { getData, postData } from "../../services/apiService";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const AdminAddRateCard = ({ refetch }: any) => {
  const [selectedProfession, setSelectedProfession] = useState<any>(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      rate: "",
      type: "",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      postData(`rate-card/create-new-global-rate-card`, data),
    onSuccess: () => {
      toast.success("Rate card created successfully");
      navigate("/all-rate-cards");
      reset();
    },
  });

  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  const getAllcategoriesValue =
    getAllcategories?.data?.data?.map((item: any) => ({
      label: item?.cat_title,
      value: item?.cat_id,
    })) || [];

  const onSubmit = (data: any) => {
    mutate({
      ...data,
      category_id: selectedProfession?.value,
    });
  };

  return (
    <div className="bg-white p-4 rounded-lg border-gray-200 border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Title</label>
          <input
            {...register("title", { required: "Title is required" })}
            type="text"
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.title ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter title"
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">
            Description
          </label>
          <textarea
            {...register("description", {
              required: "Description is required",
            })}
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.description ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">
              {errors.description.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Rate</label>
          <input
            {...register("rate", { required: "Rate is required" })}
            type="text"
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.rate ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter rate"
          />
          {errors.rate && (
            <p className="text-red-500 text-sm mt-1">{errors.rate.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Type</label>
          <select
            {...register("type", { required: "Type is required" })}
            className={`mt-1 block w-full p-2 bg-white border ${
              errors.type ? "border-red-500" : "border-gray-300"
            } rounded-md`}
          >
            <option disabled>Select Rate Type</option>
            <option value="Per Hour">Per Hour</option>
            <option value="Per Month">Per Month</option>
            <option value="Per Day">Per Day</option>
            <option value="Per Service">Per Service</option>
          </select>
          {errors.type && (
            <p className="text-red-500 text-sm mt-1">{errors.type.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="category"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Category Select
          </label>
          <Select
            options={getAllcategoriesValue}
            value={selectedProfession}
            onChange={(option) => setSelectedProfession(option)}
            placeholder="Select Category"
            className="w-full border border-gray-300 rounded-md focus:outline-none"
          />
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-[#F24E1E] text-white p-2 rounded-md"
            disabled={isPending}
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminAddRateCard;
