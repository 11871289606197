import { format } from "date-fns";
import Loader from "../../../common/Loader/Loader";
import { useState } from "react";
import SupportTicketModal from "../../../common/SupportModal/SupportModal"; // Adjust the import path as necessary
import { Eye } from "lucide-react";

const SupportTable = ({
  data,
  isLoading,
  refetch,
}: {
  data: any[];
  isLoading: boolean;
  refetch: any;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const openModal = (ticket: any) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTicket(null);
    setIsModalOpen(false);
  };

  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a") // Formatting date
      : "N/A";
  };

  return (
    <>
      <div className="overflow-x-auto rounded-md shadow-md mt-6">
        {/* Show loader if loading, otherwise show table */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader />
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Phone
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Query
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Ticket Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.length > 0 ? (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td
                      className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500 "
                      // Open modal with row data
                    >
                      {row.name || "N/A"}
                    </td>
                    <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                      {row.phone || "N/A"}
                    </td>
                    <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                      {row.query || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.ticket_status === 1 ? (
                        <button className="w-24 h-8 bg-red-200 hover:bg-red-300 text-red-700 font-semibold rounded-md flex items-center justify-center">
                          Open
                        </button>
                      ) : row.ticket_status === 2 ? (
                        <button className="w-24 h-8 bg-blue-200 hover:bg-blue-300 text-blue-700 font-semibold rounded-md flex items-center justify-center">
                          In Progress
                        </button>
                      ) : row.ticket_status === 3 ? (
                        <button className="w-24 h-8 bg-green-200 hover:bg-green-300 text-green-700 font-semibold rounded-md flex items-center justify-center">
                          Resolved
                        </button>
                      ) : row.ticket_status === 4 ? ( // Added Closed case
                        <button className="w-24 h-8 bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold rounded-md flex items-center justify-center">
                          Closed
                        </button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(row.datetime)}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-sm text-black flex gap-3 items-center cursor-pointer hover:text-red-600"
                      onClick={() => openModal(row)}
                    >
                      View
                      <Eye size={20} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No support available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Support Ticket Modal */}
      <SupportTicketModal
        isOpen={isModalOpen}
        onClose={closeModal}
        ticket={selectedTicket}
        refetch={refetch}
      />
    </>
  );
};

export default SupportTable;
