import React from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { TabProps } from "../../../common/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../common/Slice/BreadcrumbsSlice";

const TabData: React.FC<TabProps> = ({ title, number, growth }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formattedNumber = number.toLocaleString();

  const BreadCrumbObject: any = {
    title: "All Antoilers",
    url: "/all-antoilers",
  };

  const BreadCrumbObject2: any = {
    title: "All Users",
    url: "/all-users",
  };

  const handleNavigate = (title: any) => {
    if (title === "TOTAL REGISTERED ANTOILERS") {
      dispatch(setBreadcrumbs(BreadCrumbObject));
      navigate("/all-antoilers");
    }
    if (title === "TOTAL ACTIVE USERS") {
      dispatch(setBreadcrumbs(BreadCrumbObject2));
      navigate("/all-users");
    }
  };

  return (
    <div
      className="w-52 bg-white rounded-lg border border-gray-200 p-2 cursor-pointer"
      style={{ borderRadius: "10px" }}
      onClick={() => handleNavigate(title)}
    >
      <h2 className="text-gray-500 mb-2 text-xs tracking-wider ">{title}</h2>
      <div className="flex items-center justify-between">
        <span className=" font-extrabold">{formattedNumber}</span>
        <div
          className={`flex items-center ${
            growth > 0 ? "text-green-500" : "text-red-500"
          }`}
        >
          <span className="ml-1 text-sm font-semibold">{growth}%</span>
          {growth > 0 ? <FaArrowUp size={12} /> : <FaArrowDown size={12} />}
        </div>
      </div>
    </div>
  );
};

export default TabData;
