import React, { useState, useMemo, useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

import {
  BASE_IMAGE,
  getData,
  postData,
  postFileData,
} from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";

interface Media {
  media_id: number;
  normal_img: string;
}

interface SelectedMedia {
  url: string;
  type: "image" | "video";
}

const WorkDone: React.FC = () => {
  const [tab, setTab] = useState<"workdone" | "editworkdone">("workdone");
  const antoiler = useSelector((state: any) => state?.AntoilerReview);
  const antoilerId = antoiler?.data?.user_id;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<SelectedMedia | null>(
    null
  );
  const [files, setFiles] = useState<File[]>([]);
  const [isSaving, setIsSaving] = useState(false); // New state for showing loader on save

  console.log("Loading", { antoilerId });

  const {
    data: mediaGet,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getMediaByAntoiler", antoilerId],
    queryFn: () => getData(`media/get-media-by-antoiler-id/${antoilerId}`),
    enabled: !!antoilerId,
  });
  const deleteMediaMutation = useMutation({
    mutationFn: (mediaId: number) => postData(`media/delete-media/${mediaId}`),
    onSuccess: () => {
      toast.success("Media deleted successfully");
      refetch().then(() => {
        // Check the length of the media data after refetching
        const remainingMedia = mediaGet?.data || [];
        if (remainingMedia.length === 1) {
          // If only one image remains
          setTab("workdone");
        }
      });
    },
    onError: (error) => {
      toast.error("Failed to delete media");
      console.error("Delete media error:", error);
    },
  });

  const uploadFilesMutation = useMutation({
    mutationFn: () => {
      setIsSaving(true); // Show loader
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      return postFileData(`single-image-upload`, formData);
    },
    onSuccess: (response: any) => {
      console.log("response", { response });
      createMediaMutation.mutate(response?.data?.outputPath);
    },
    onError: (error) => {
      toast.error("Please upload a Image or Video");
      console.error("File upload error:", error);
      setIsSaving(false); // Hide loader on error
    },
  });

  const createMediaMutation = useMutation({
    mutationFn: (fileData: any) => {
      return postData(`media/create-new-media/${antoilerId}`, {
        files: fileData,
      });
    },
    onSuccess: () => {
      toast.success("Edits saved successfully");
      setFiles([]);
      refetch();
      setTab("workdone");
      setIsSaving(false); // Hide loader on success
    },
    onError: (error) => {
      toast.error("Failed to save edits");
      console.error("Save edits error:", error);
      setIsSaving(false); // Hide loader on error
    },
  });

  const handleTabChange = useCallback(() => {
    if (tab === "editworkdone") {
      uploadFilesMutation.mutate();
    } else {
      setTab("editworkdone");
    }
  }, [tab, uploadFilesMutation]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleMediaClick = (url: string, type: "image" | "video") => {
    setSelectedMedia({ url, type });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedMedia(null);
  };

  const handleMediaDelete = (mediaId: number) => {
    deleteMediaMutation.mutate(mediaId);
  };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleBackClick = () => {
    setTab("workdone");
  };

  const renderMedia = useMemo(
    () => (mediaData: Media[], onDelete?: (mediaId: number) => void) => {
      return mediaData.map((item, index) => {
        const mediaUrl = `${BASE_IMAGE}${item.normal_img}`;
        const mediaType = item.normal_img?.endsWith(".mp4") ? "video" : "image";
        return (
          <div key={index} className="relative">
            {onDelete && (
              <button
                className="absolute -top-1 -right-1 bg-red-600 rounded-full w-6 h-6 flex items-center justify-center border"
                onClick={() => onDelete(item.media_id)}
                aria-label="Delete media"
              >
                <FaTimes className="text-sm text-white" />
              </button>
            )}
            <div onClick={() => handleMediaClick(mediaUrl, mediaType)}>
              {mediaType === "image" ? (
                <img
                  src={mediaUrl}
                  alt={`Work item ${index + 1}`}
                  className="h-52 w-52 object-cover rounded-md cursor-pointer"
                />
              ) : (
                <video
                  src={mediaUrl}
                  className="h-52 w-52 object-cover rounded-md cursor-pointer"
                  controls
                />
              )}
            </div>
          </div>
        );
      });
    },
    []
  );

  const NoDataIllustration = () => (
    <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-gray-50 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0085FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <polyline points="21 15 16 10 5 21" />
      </svg>
      <p className="text-lg font-semibold text-gray-700">No work done yet</p>
      <p className="text-sm text-gray-500">
        Start uploading your work to see it here!
      </p>
    </div>
  );

  return (
    <div className="mt-6 bg-white rounded-md">
      <div className="flex justify-between items-center p-4">
        <div>
          <h2 className="font-bold text-lg">WorkDone</h2>
          <p className="text-sm text-gray-500">
            Section where Antoilers can add their work for other people to view.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          {tab === "editworkdone" && (
            <button
              onClick={handleBackClick}
              className="text-gray-500 hover:text-gray-700"
            >
              Back
            </button>
          )}
          <Link
            to="#"
            onClick={handleTabChange}
            className="text-[#0085FF] flex items-center"
          >
            {isSaving ? (
              <Loader />
            ) : tab === "workdone" ? (
              "Edit Work Done"
            ) : (
              "Save Edits"
            )}
          </Link>
        </div>
      </div>

      {tab === "workdone" &&
        (isLoading ? (
          <div className="flex justify-center items-center h-52">
            <Loader />
          </div>
        ) : !mediaGet?.data?.length ? (
          <NoDataIllustration />
        ) : (
          <div className="flex justify-start items-center flex-wrap gap-4 p-2">
            {renderMedia(mediaGet?.data || [])}
          </div>
        ))}

      {tab === "editworkdone" && (
        <div>
          <div className="mt-4 p-4">
            <h3 className="font-semibold">Upload WorkDone</h3>
            <div className="border border-dashed flex items-center justify-center p-6 mt-4 border-gray-400">
              <input
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={handleFileChange}
                className="hidden"
                id="fileInput"
              />
              <label htmlFor="fileInput" className="cursor-pointer">
                <div className="p-2 text-gray-600 rounded-md">
                  Upload Pictures & Videos
                </div>
              </label>
            </div>
          </div>
          <div className="flex justify-start items-center flex-wrap gap-4 p-2 max-h-52">
            {files.map((file, index) => {
              const fileUrl = URL.createObjectURL(file);
              const fileType = file.type.startsWith("video/")
                ? "video"
                : "image";
              return (
                <div className="relative" key={index}>
                  {fileType === "image" ? (
                    <img
                      src={fileUrl}
                      alt={`Uploaded item ${index}`}
                      className="h-52 w-52 object-cover rounded-md"
                    />
                  ) : (
                    <video
                      src={fileUrl}
                      className="h-52 w-52 object-cover rounded-md"
                      controls
                    />
                  )}
                  <button
                    className="absolute -top-1 -right-1 bg-red-600 rounded-full w-6 h-6 flex items-center justify-center border"
                    onClick={() => handleFileRemove(index)}
                    aria-label="Remove file"
                  >
                    <FaTimes className="text-white" />
                  </button>
                </div>
              );
            })}
          </div>

          <div className="flex justify-around items-center flex-wrap gap-4 p-2">
            {renderMedia(mediaGet?.data || [], handleMediaDelete)}
          </div>
        </div>
      )}

      {isModalOpen && selectedMedia && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white p-4 rounded-md">
            <button
              className="absolute -top-3 -right-3 cursor-pointer w-8 h-8 bg-red-600 rounded-full flex items-center justify-center"
              onClick={handleModalClose}
              aria-label="Close modal"
            >
              <FaTimes className="text-sm text-white" />
            </button>
            {selectedMedia.type === "image" ? (
              <img
                src={selectedMedia.url}
                alt="Modal Content"
                className="w-[600px] h-[600px] object-cover"
              />
            ) : (
              <video
                src={selectedMedia.url}
                className="w-[600px] h-[600px] object-cover"
                controls
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkDone;
