import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import RatingStars from "../../../common/Rating/Rating";
import { Bluelesser } from "../../../common/icons";
import { BASE_IMAGE, getData } from "../../../services/apiService";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useDispatch } from "react-redux";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import Loader from "../../../common/Loader/Loader";

const NoReviewsSVG = () => (
  <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-gray-50 rounded-md">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#0085FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
      <path d="M7 8h10" />
      <path d="M7 12h4" />
      <circle cx="16" cy="12" r="2" />
      <path d="M16 14v2" />
    </svg>
    <p className="text-xl font-semibold text-gray-700">No reviews yet</p>
    <p className="text-sm text-gray-500 text-center">
      Be the first to share your experience!
    </p>
  </div>
);

const AllReviews = ({ antoilerID }: any) => {
  const [openReviewId, setOpenReviewId] = useState(null);
  const antoiler: any = useSelector((state: any) => state?.AntoilerReview);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const BreadCrumbObject: any = {
    title: "View All Reviews",
    url: `/antoiler-feedback`,
  };

  const formatDate = (dateStr: any) => moment(dateStr).format("D MMMM, h:mmA");

  const { data: mediaData, isLoading } = useQuery({
    queryKey: ["getMediaByReviewId", openReviewId],
    queryFn: () =>
      openReviewId
        ? getData(`media/get-media-by-review-id/${openReviewId}`)
        : null,
    enabled: !!openReviewId,
  });

  const handleViewMedia = (id: any) =>
    setOpenReviewId(openReviewId === id ? null : id);

  const renderReviewMedia = () => {
    if (isLoading) return <Loader />;

    if (!mediaData?.data?.length) return <p>No media available</p>;

    return mediaData.data.map((mediaItem: any, mediaIndex: any) => (
      <div key={mediaIndex} className="relative">
        {mediaItem.type === "img" ? (
          <img
            src={`${BASE_IMAGE}${mediaItem.normal_img}`}
            alt={`Antoiler ${mediaIndex}`}
            className="w-20 h-20 object-cover"
          />
        ) : (
          <video controls className="w-20 h-20 object-cover">
            <source src={`${BASE_IMAGE}${mediaItem.normal_img}`} />
          </video>
        )}
      </div>
    ));
  };

  const renderReviewItem = (item: any) => (
    <div
      className="flex items-start gap-4 mb-6 mt-2 shadow-md p-4 border-2 border-gray-200"
      key={item.review_id}
    >
      <img
        src={`${BASE_IMAGE}${item?.profile_pic}`}
        className="w-16 h-16 rounded-full object-cover"
        alt="Antoiler"
      />
      <div className="flex-1">
        <div className="flex items-center gap-2 mb-2">
          <RatingStars
            count={5}
            value={item?.overall_review_rate}
            size={21}
            color2={"#F24E1E"}
            edit={false}
          />
          <p className="text-sm font-semibold">{item?.first_name}</p>
        </div>
        <p className="text-sm text-gray-400 mb-1">
          {formatDate(item?.rev_date_time)}
        </p>
        <p className="text-sm text-gray-600">{item?.review_txt}</p>

        <div
          className="mt-4 font-semibold flex items-center cursor-pointer"
          onClick={() => handleViewMedia(item?.review_id)}
        >
          <p className="text-indigo-600">View Media</p>
          <div className="ml-4">
            <Bluelesser />
          </div>
        </div>
        {openReviewId === item?.review_id && (
          <div className="mt-4 grid grid-cols-5 gap-4">
            {renderReviewMedia()}
          </div>
        )}
      </div>
    </div>
  );

  const renderReviews = () => {
    const reviews = antoiler?.data?.reviews;
    if (!reviews || reviews.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <NoReviewsSVG />
        </div>
      );
    }

    return reviews.map(renderReviewItem);
  };

  const handleNavigate = () => {
    dispatch(
      addBreadcrumb({
        ...BreadCrumbObject,
        url: `/antoiler-feedback?id=${antoilerID}`,
      })
    );
    navigate(`/antoiler-feedback?id=${antoilerID}`);
  };

  return (
    <div className="mt-6 bg-white rounded-md">
      <div className="flex justify-between items-center p-4">
        <div>
          <p className="font-bold text-lg">Published Reviews</p>
          <p className="text-sm text-gray-500">
            Authentic Reviews at Your Fingertips.
          </p>
        </div>
        <div className="text-[#0085FF] cursor-pointer" onClick={handleNavigate}>
          View All Reviews
        </div>
      </div>

      <div className="p-4">{renderReviews()}</div>
    </div>
  );
};

export default AllReviews;
