import React from "react";
import AdvancedDropdown from "./AdvancedDropdown"; // Adjust the import path as necessary
import { BASE_URL } from "../../../services/apiService";

interface Category {
  cat_id: number;
  cat_title: string;
  cat_slug: string;
  is_featured: number;
}

interface CategorySelectorProps {
  handleCategoryId: (cat_id: string) => void;
  classNames?: string;
  selectCategoryId?: string;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  handleCategoryId,
  classNames = "",
  selectCategoryId,
}: any) => {
  const handleSelect = async (selectedItems: any) => {
    console.log("Selected categories:", selectedItems);
    handleCategoryId(selectedItems?.[0]?.cat_id);
    console.log("hellow", selectedItems?.[0]?.cat_id);
  };

  return (
    <AdvancedDropdown<Category>
      apiEndpoint={BASE_URL + "/category/get-all-categories"}
      isMultiSelect={false}
      onSelect={handleSelect}
      pageSize={10}
      itemToString={(item) => item.cat_title}
      search_var="search_term"
      classNames={classNames}
    />
  );
};

export default CategorySelector;
