import ReactSwitch from "react-switch";
import { BASE_IMAGE, getData, postData } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";
import { format } from "date-fns";
import { Eye, Trash2 } from "lucide-react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import EditChildCategoryModal from "../components/EditChildCategoryModal";
import Pagination from "../../../common/components/Pagination";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import SearchBar from "../../../common/SearchBar/SearchBar";
import { toast } from "react-toastify";

const ChildCategoryView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pendingCategoryId, setPendingCategoryId] = useState<number | null>(
    null
  );

  const [searchParams] = useSearchParams();
  const parentCategoryId = searchParams.get("parent_cat");
  const itemsPerPage = 10;

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "getChildCategoriesByFilters",
      parentCategoryId,
      currentPage,
      searchTerm,
    ],
    queryFn: () =>
      getData(
        `category/get-child-categories-by-filters?parent_category_id=${parentCategoryId}&search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}`
      ),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) => {
      setPendingCategoryId(id);
      return postData(`category/change-status-by-child-category/${id}`);
    },
    onSuccess: () => {
      toast.success("Category status changed successfully");
      setPendingCategoryId(null);
      refetch();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
      setPendingCategoryId(null);
    },
  });

  const formatDate = (dateString: string) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEditCategory = (category: any) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const handleSwitchChange = (checked: boolean, category: any) => {
    mutate(category?.cat_id);
  };

  const handleSearchBarChange = (searchBarValue: string) => {
    setSearchTerm(searchBarValue);
    setCurrentPage(1);
  };

  const { mutate: DeleteChildCategory } = useMutation({
    mutationFn: (id: number) => postData(`category/delete-category/${id}`),
    onSuccess: () => {
      refetch();
      toast.success("Category deactivated successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message?.message);
    },
  });

  const handleDeleteCategory = (category: any) => {
    if (category?.cat_id) {
      DeleteChildCategory(category.cat_id);
    } else {
      console.error("Category ID is missing");
    }
  };

  return (
    <>
      <SearchBar onChange={handleSearchBarChange} />
      <div className="overflow-x-auto rounded-md shadow-md mt-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader />
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Child Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Category Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Featured
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.data?.data
                ?.filter(
                  (category: any) =>
                    category.cat_status === 1 || category.cat_status === 2
                )
                .map((category: any, index: number) => (
                  <tr key={index}>
                    <td className="px-6 py-4 text-sm text-gray-500 flex items-center gap-4">
                      <img
                        src={`${BASE_IMAGE}${category?.cat_img}`}
                        className="w-10 h-10 rounded-full"
                        alt="Category"
                      />
                      {category.cat_title || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {pendingCategoryId === category.cat_id ? (
                        <Loader />
                      ) : (
                        <ReactSwitch
                          checked={category?.cat_status === 1}
                          onChange={(checked) =>
                            handleSwitchChange(checked, category)
                          }
                          height={28}
                          width={56}
                          onColor="#EF4444"
                          offColor="#D1D5DB"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {category?.cat_status === 1 ? "Published" : "Pending"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {category?.is_featured === 1
                        ? "Featured"
                        : "Not Featured"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {formatDate(category.cat_date_time)}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 flex gap-3 items-center">
                      <div
                        onClick={() => handleEditCategory(category)}
                        className="hover:text-green-600"
                      >
                        <MdOutlineModeEditOutline size={20} />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCategory(category);
                        }}
                        className="hover:text-green-600"
                      >
                        <Trash2 size={20} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        <div className="m-4">
          {data?.data?.totalResults > itemsPerPage && (
            <Pagination
              totalItems={data?.data?.totalResults}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>

        {selectedCategory && (
          <EditChildCategoryModal
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
            category={selectedCategory}
            refetch={refetch}
          />
        )}
      </div>
    </>
  );
};

export default ChildCategoryView;
