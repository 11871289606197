import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./common/components/Layout/Layout";
import { routeDefinitions } from "./routes/routeDefinitions";
import Login from "./domains/Login/Login";

const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return <Navigate to="/" />;
  }
  return element;
};

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      {routeDefinitions.map(({ path, element }, index) => (
        <Route
          key={index}
          path={path}
          element={<PrivateRoute element={<Layout>{element}</Layout>} />}
        />
      ))}
    </Routes>
  );
};

export default App;
