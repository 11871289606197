import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { postData, postFileData } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";

const EditParentCategoryModal = ({ isOpen, close, category, refetch }: any) => {
  const [parentCategoryName, setParentCategoryName] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false); // Loader state

  // Initialize form data when category changes
  useEffect(() => {
    if (category) {
      setParentCategoryName(category.cat_title || "");
      setImage(null); // Reset the image when category changes
    }
  }, [category]);

  const iconImage = async () => {
    if (!image) return null; // Skip upload if no image is selected

    const formData = new FormData();
    formData.append("image", image);
    const response = await postFileData(
      "admin-category-image-upload",
      formData
    );
    return response.data; // Assume the server returns the file URL or ID
  };

  const updateParentCategory = async (id: number) => {
    // Call the function to upload the image if it exists
    const imageUrl = await iconImage();

    // Prepare data for updating the category
    const dataToUpdate = {
      cat_title: parentCategoryName,
      ...(imageUrl && { cat_img: imageUrl?.outputPath }), // Only include cat_img if imageUrl is defined
    };

    // Send the update request
    await postData(`category/update-parent-category/${id}`, dataToUpdate);
  };

  const { mutate } = useMutation({
    mutationFn: (id: number) => updateParentCategory(id),
    onMutate: () => {
      setLoading(true); // Set loading to true when mutation starts
    },
    onSuccess: () => {
      refetch();
      toast.success("Parent category updated successfully");
      close();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Error updating category");
    },
    onSettled: () => {
      setLoading(false); // Set loading to false when mutation ends
    },
  });

  // Handle form submission
  const handleUpdate = () => {
    // Validate inputs
    if (!parentCategoryName.trim()) {
      toast.error("Parent category name is required.");
      return;
    }

    // Call mutate to update the category
    console.log("Updating parent category:", parentCategoryName, image);
    mutate(category.cat_id); // Pass the ID of the category to update
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      if (file.type === "image/svg+xml" || file.name.endsWith(".svg")) {
        setImage(file);
      } else {
        toast.success("Please upload a valid SVG image.");
        e.target.value = "";
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <h2 className="text-xl font-semibold mb-4">Edit Parent Category</h2>

        {/* Parent Category Field */}
        <div className="mb-4">
          <label htmlFor="parentCategory" className="block mb-1">
            Parent Category
          </label>
          <Input
            type="text"
            id="parentCategory"
            placeholder="Enter Parent Category"
            value={parentCategoryName}
            onChange={(e) => setParentCategoryName(e.target.value)}
            className="w-full"
          />
        </div>

        {/* Image Upload Field */}
        <div className="mb-4">
          <label htmlFor="categoryImage" className="block mb-1">
            Upload Image (optional)
          </label>
          <Input
            type="file"
            id="categoryImage"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full"
          />
        </div>

        {/* Loader */}
        {loading && (
          <div className="flex justify-center mb-4">
            <Loader />
          </div>
        )}

        {/* Update Button */}
        <div className="flex justify-end">
          <Button onClick={handleUpdate} className="bg-red-500 text-white">
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditParentCategoryModal;
