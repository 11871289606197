import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import { setData } from "../../AntoilerReview/slice/AntoilerReviewSlice";
import { useDispatch } from "react-redux";
import ProfileData from "../../../domains/AntoilerReview/components/ProfileData";
import PersonalInformation from "../../../domains/AntoilerReview/components/PersonalInformation";
import AllReviews from "../../../domains/AntoilerReview/components/AllReviews";
import ActivityLogs from "../../../domains/AntoilerReview/components/Activitylogs";

const CustomerReview = () => {
  const [searchParams] = useSearchParams();
  const CustomerID = searchParams.get("id");
  const [activeTab, setActiveTab] = useState("reviews");

  const dispatch = useDispatch();

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  const { data, refetch: refetchFn } = useQuery({
    queryKey: ["getUserById"],
    queryFn: () => getData(`user/get-user-by-id/${CustomerID}`),
  });

  useEffect(() => {
    if (data) {
      dispatch(setData(data?.data));
    }
  }, [data]);

  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-4 flex flex-col p-4">
        <ProfileData
          onTabChange={handleTabChange}
          activeTab={activeTab}
          refetch={refetchFn}
        />
      </div>

      <div className="col-span-8">
        <PersonalInformation refetch={refetchFn} />
        {activeTab === "reviews" && <AllReviews />}
        {activeTab === "activitylogs" && <ActivityLogs />}
      </div>
    </div>
  );
};

export default CustomerReview;
