import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface DataItem {
  date: string;
  month: string;
  total_count: string;
  antoiler_count: string;
  user_count: string;
}

const UserActivityReport: React.FC = () => {
  const [number, setNumber] = useState(12);
  const [activeButton, setActiveButton] = useState(12); // State to track active button

  const { data: apiResponse, refetch } = useQuery({
    queryKey: ["dashboardChart", number],
    queryFn: () => getData(`user/get-dashboard-chart/${number}`),
  });

  if (apiResponse?.data?.length === 3) {
    refetch();
  }

  console.log({ apiResponse });
  const dashboardChart: DataItem[] = apiResponse?.data || [];

  const months = dashboardChart.map((item) =>
    item.month ? item?.month : item?.date
  );
  const antoilerCounts = dashboardChart.map((item) =>
    parseInt(item.antoiler_count, 10)
  );
  const userCounts = dashboardChart.map((item) =>
    parseInt(item.user_count, 10)
  );

  const handleClick = (number: number) => {
    setNumber(number);
    setActiveButton(number); // Set the active button state
  };

  const data = {
    labels: months,
    datasets: [
      {
        label: "Antoiler Count",
        data: antoilerCounts,
        fill: true,
        borderColor: "#F15A24",
        backgroundColor: "rgba(238, 242, 255, 0.5)",
        tension: 0.4,
        pointRadius: 5,
        pointBorderWidth: 2,
        pointBorderColor: "white",
        pointBackgroundColor: "#F15A24",
      },
      {
        label: "User Count",
        data: userCounts,
        fill: true,
        borderColor: "#6ab964",
        backgroundColor: "rgba(73, 138, 246, 0.2)",
        tension: 0.4,
        pointRadius: 5,
        pointBorderWidth: 2,
        pointBorderColor: "white",
        pointBackgroundColor: "#6ab964",
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: number === 30 || number === 7 ? false : true,
          autoSkip: number === 30 || number === 7,
        },
      },
      y: {
        grid: {
          display: true,
          borderColor: "#e5e7eb",
        },
        ticks: {
          display: false,
          count: 5,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "#E4E4E7",
        borderWidth: 1,
        callbacks: {
          label: (tooltipItem: any) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
        // Ensure this is set to true
        caretSize: 6, // Adjust caret size if needed
        caretPadding: 8, // Adjust padding if needed
        displayColors: true, // Show color box
      },
      filler: {
        propagate: true,
      },
    },
  };

  return (
    <div className="bg-white pl-8 pr-2 py-4 rounded-lg border border-gray-200">
      <div className="flex justify-start items-center space-x-4 mb-4">
        <h2 className="text-left font-bold text-sm">User Activity Report</h2>
        <div>
          <button
            className={`mx-2 px-4 py-2 border rounded-md text-sm ${
              activeButton === 12
                ? "border-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 text-black font-semibold"
                : "border-transparent  hover:bg-gray-300 text-gray-600"
            }`}
            onClick={() => handleClick(12)}
          >
            12 Months
          </button>
          <button
            className={`mx-2 px-4 py-2 border rounded-md text-sm ${
              activeButton === 6
                ? "border-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 text-black font-semibold"
                : "border-transparent  hover:bg-gray-300 text-gray-600"
            }`}
            onClick={() => handleClick(6)}
          >
            6 Months
          </button>
          <button
            className={`mx-2 px-4 py-2 border rounded-md text-sm ${
              activeButton === 30
                ? "border-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 text-black font-semibold"
                : "border-transparent  hover:bg-gray-300 text-gray-600"
            }`}
            onClick={() => handleClick(30)}
          >
            30 Days
          </button>
          <button
            className={`mx-2 px-4 py-2 border rounded-md text-sm ${
              activeButton === 7
                ? "border-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 text-black font-semibold"
                : "border-transparent  hover:bg-gray-300 text-gray-600"
            }`}
            onClick={() => handleClick(7)}
          >
            7 Days
          </button>
        </div>
      </div>

      <div className="w-full h-60">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default UserActivityReport;
