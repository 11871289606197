import { useState, useEffect, useCallback } from "react";
import { Search as SearchIcon, ChevronDown } from "lucide-react";
import debounce from "lodash/debounce";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu"; // Adjust the import based on your structure

const SearchFeedback = ({ onChange, handleRole }: any) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState("Select"); // Default option

  // Wrap the onChange function with debounce
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 500),
    []
  );

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedOnChange(value);
  };

  const handleOptionSelect = (label: string, value: number) => {
    setSelectedOption(label); // Display the label
    handleRole(value); // Pass the corresponding value
    console.log(
      "Selected Option: ",
      label,
      "Value sent to handleRole: ",
      value
    );
  };

  // Clean up the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return (
    <div className="relative w-full mt-4 flex items-center gap-4">
      <input
        type="text"
        className="w-full px-4 py-2 pr-10 border rounded-md focus:outline-none text-[#C0C0C0] font-medium text-sm"
        placeholder="Search by Feedback"
        value={searchTerm}
        onChange={handleInputChange}
      />
      <div className="absolute right-36 top-1/2 transform -translate-y-1/2 text-gray-400">
        <SearchIcon size={20} />
      </div>

      {/* Dropdown for selecting options */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex items-center gap-2 cursor-pointer border px-3 py-2 rounded-md text-sm font-medium">
            <p>{selectedOption}</p>
            <ChevronDown className="text-gray-400" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>Select A User</DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleOptionSelect("Antoiler", 2)}>
            Antoiler
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleOptionSelect("User", 3)}>
            User
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SearchFeedback;
