import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    onPageChange(pageNumber);
  };

  if (totalPages <= 1) return null;

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page
      pageNumbers.push(1);

      let startPage = Math.max(currentPage - 1, 2);
      let endPage = Math.min(currentPage + 1, totalPages - 1);

      // If the current page is near the start, show the first few pages
      if (currentPage <= 3) {
        startPage = 2;
        endPage = 4;
      }

      // If the current page is near the end, show the last few pages
      if (currentPage >= totalPages - 2) {
        startPage = totalPages - 3;
        endPage = totalPages - 1;
      }

      // Show ellipsis if needed
      if (startPage > 2) {
        pageNumbers.push("...");
      }

      // Show the page numbers between the startPage and endPage
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Show ellipsis if needed
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }

      // Always show the last page
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-end items-center space-x-2 mt-4">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`p-2 border rounded-md text-xs ${
          currentPage === 1
            ? "bg-[#D9D9D9] text-gray-500"
            : "bg-[#D9D9D9] text-black"
        }`}
      >
        <ChevronLeft size={20} />
      </button>

      {renderPageNumbers().map((pageNumber, index) =>
        typeof pageNumber === "number" ? (
          <button
            key={index}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-4 py-2 rounded-full text-sm ${
              pageNumber === currentPage
                ? "text-white font-bold bg-blue-500"
                : "text-gray-700"
            }`}
          >
            {pageNumber}
          </button>
        ) : (
          <span key={index} className="px-2">
            {pageNumber}
          </span>
        )
      )}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`p-2 border rounded-md ${
          currentPage === totalPages
            ? "bg-[#D9D9D9] text-gray-500"
            : "bg-[#D9D9D9] text-black"
        }`}
      >
        <ChevronRight size={20} />
      </button>
    </div>
  );
};

export default Pagination;
