import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { Eye, HardHat, PencilIcon, Share2, User } from "lucide-react";
import { BsTelephone } from "react-icons/bs";
import { CiStar } from "react-icons/ci";
import { PiSuitcaseSimple } from "react-icons/pi";
import {
  BASE_IMAGE,
  getData,
  postData,
  postFileData,
} from "../../../services/apiService";
import { OtherStatus, Rupees, Review, Workdone } from "../../../common/icons";
import Loader from "../../../common/Loader/Loader";
import { useSearchParams } from "react-router-dom";

interface ProfileDataProps {
  onTabChange: (tab: string) => void;
  activeTab: string;
  refetch: () => void;
}

const ProfileData: React.FC<ProfileDataProps> = ({
  onTabChange,
  activeTab,
  refetch,
}) => {
  const user = useSelector((state: any) => state?.AntoilerReview);
  const user_id = user?.data?.user_id;
  const [newImage, setNewImage] = useState<File | null>(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [profileImage, setProfileImage] = useState(user?.data?.profile_pic);

  const [searchParams] = useSearchParams();
  const userID = searchParams.get("id");

  const [formData, setFormData] = useState({
    online_status: user?.data?.online_status,
    profile_pic: user?.data?.profile_pic,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      online_status: user?.data?.online_status,
      profile_pic: user?.data?.profile_pic,
    }));
    setProfileImage(user?.data?.profile_pic);
  }, [user?.data?.online_status, user?.data?.profile_pic]);

  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(parseISO(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  const formatNumberWithPlus = (number: number) => {
    if (number === 0) {
      return `${number}`;
    }
    if (number % 10 === 0) {
      return `${number}+`;
    }
    return number;
  };

  const { mutate: updateProfile, isPending } = useMutation({
    mutationFn: (formObj) =>
      postData(`user/update-antoiler-by-id/${user_id}`, formObj),
    onSuccess: () => {
      toast.success("Profile updated successfully!");
      refetch();
    },
    onError: () => {
      toast.error("Failed to update profile. Please try again.");
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = event.target.checked ? 1 : 2;
    setFormData((prevFormData) => ({
      ...prevFormData,
      online_status: newStatus,
    }));
    const obj: any = { ...formData, online_status: newStatus };
    updateProfile(obj);
  };

  const profileUpdate = async (file: File) => {
    const formData = new FormData();
    formData.append("profile", file);
    setIsUploadingImage(true);
    try {
      const response = await postFileData("/single-image-upload", formData);
      console.log("hel", response?.data?.outputPath);
      setIsUploadingImage(false);
      return response?.data?.outputPath;
    } catch (error) {
      setIsUploadingImage(false);
      toast.error("Failed to upload image. Please try again.");
      return null;
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setNewImage(file);
      const imageUrl = await profileUpdate(file);
      console.log(imageUrl);
      if (imageUrl) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          profile_pic: imageUrl,
        }));
        setProfileImage(imageUrl);

        const obj: any = { ...formData, profile_pic: imageUrl };
        updateProfile(obj);
        // updateProfile();
      }
    }
  };

  const handlePencilClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const { data: userStates } = useQuery<any>({
    queryKey: ["user_states"],
    queryFn: () => getData(`user/get-states-of-user/${userID}`),
  });

  console.log({ userStates });

  return (
    <>
      <div className="relative inline-block w-32">
        {isUploadingImage ? (
          <Loader />
        ) : (
          <img
            src={`${BASE_IMAGE}${profileImage}`}
            className="w-32 h-32 rounded-full"
            alt="Antoiler"
          />
        )}
        <div
          className="absolute bottom-1 right-2 bg-primary rounded-full p-2 cursor-pointer"
          onClick={handlePencilClick}
        >
          <PencilIcon
            className="h-4 w-4 text-primary-foreground z-50"
            id="pencil"
          />
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          className="hidden"
          accept="image/*"
        />
      </div>
      <div className="mt-4 space-x-2 flex items-center">
        <User size={15} />
        <span className="text-lg font-semibold">{user?.data?.first_name}</span>
        <span className="text-sm text-gray-600 mt-1">
          {user?.data?.gender === "f" ? "Female" : "Male"}
        </span>

        {user?.data?.role === 2 && (
          <label className="inline-flex items-center cursor-pointer">
            {isPending ? (
              <Loader />
            ) : (
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={Number(formData.online_status) === 1}
                  onChange={handleCheckboxChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
              </div>
            )}
            <span
              className={`ms-3 text-sm font-semibold w-2 h-2 rounded-full ${
                Number(formData?.online_status) === 1
                  ? "bg-[#34C759]"
                  : "bg-[#F24E1E]"
              }`}
            ></span>
            <span
              className={`ms-1 text-sm font-semibold ${
                Number(formData?.online_status) === 1
                  ? "text-[#34C759]"
                  : "text-[#F24E1E]"
              }`}
            >
              {Number(formData?.online_status) === 1
                ? "Available"
                : "Unavailable"}
            </span>
          </label>
        )}
      </div>
      {user?.data?.role === 2 && (
        <div className="flex items-center gap-2">
          <div
            className={`w-2 h-2 rounded-full ${
              formData.online_status === 1
                ? "bg-[#34C759] border-[#91FFAD]"
                : "bg-red-500 border-red-700"
            } border-2 animate-ping`}
          ></div>
          <p
            className={`font-semibold ${
              formData.online_status === 1 ? "text-[#34C759]" : "text-red-500"
            }`}
          >
            {formData.online_status === 1
              ? "Available for work"
              : "Unavailable for work"}
          </p>
        </div>
      )}
      {user?.data?.role === 2 && (
        <div className="space-x-2 items-center flex">
          <HardHat size={15} />
          <span className="text-sm text-gray-700">{user?.data?.cat_title}</span>
        </div>
      )}
      <div className="space-x-2 items-center flex ">
        <Eye size={15} />
        <span className="text-sm font-medium text-gray-400">
          Registration Date
        </span>
        <span className="text-sm text-gray-700">
          {formatDate(user?.data?.reg_date_time)}
        </span>
      </div>
      <div className="space-x-2 items-center flex ">
        <Eye size={15} />
        <span className="text-sm font-medium text-gray-400">Last used</span>
        <span className="text-sm text-gray-700">
          {formatDate(user?.data?.last_seen)}
        </span>
      </div>
      <div className="mt-6 flex flex-row justify-between items-center">
        <div className="">
          {user?.data?.role === 2 ? (
            <p className="text-black font-medium">
              {formatNumberWithPlus(user?.data?.contactedCount)}
            </p>
          ) : (
            <p className="text-black font-medium">
              {formatNumberWithPlus(
                userStates?.data?.insightsData?.contact_count
              )}
            </p>
          )}

          <div className="flex items-center space-x-2">
            <BsTelephone size={15} />
            <p className="text-gray-400 font-medium text-xs text-nowrap">
              Contacted
            </p>
          </div>
        </div>
        <div className="">
          {user?.data?.role === 2 ? (
            <p className="text-black font-medium">
              {formatNumberWithPlus(user?.data?.reviews?.length)}
            </p>
          ) : (
            <p className="text-black font-medium">
              {formatNumberWithPlus(
                userStates?.data?.insightsData?.feedback_count
              )}
            </p>
          )}
          <div className="flex items-center space-x-2">
            <CiStar />
            <p className="text-gray-400 font-medium text-xs text-nowrap">
              Feedback
            </p>
          </div>
        </div>
        <div className="">
          {user?.data?.role === 2 ? (
            <p className="text-black font-medium">
              {formatNumberWithPlus(user?.data?.shares)}
            </p>
          ) : (
            <p className="text-black font-medium">
              {formatNumberWithPlus(
                userStates?.data?.insightsData?.share_count
              )}
            </p>
          )}
          <div className="flex items-center space-x-2">
            <Share2 size={15} />
            <p className="text-gray-400 font-medium text-xs text-nowrap">
              Shares
            </p>
          </div>
        </div>
        <div className="">
          {user?.data?.role === 2 ? (
            <p className="text-black font-medium">
              {formatNumberWithPlus(user?.data?.hiredCount)}
            </p>
          ) : (
            <p className="text-black font-medium">
              {formatNumberWithPlus(
                userStates?.data?.insightsData?.hired_count
              )}
            </p>
          )}
          <div className="flex items-center gap-2">
            <PiSuitcaseSimple size={15} />
            <p className="text-gray-400 font-medium text-xs text-nowrap">
              Hired
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <p className="text-gray-400">Profile Management</p>
      </div>
      <div className="mt-6">
        <div
          className="flex items-center gap-4 mb-3 cursor-pointer group"
          onClick={() => onTabChange("reviews")}
        >
          <Review activeTab={activeTab} />
          <span
            className={`font-medium transition-colors duration-200 group-hover:text-[#F24E1E] ${
              activeTab === "reviews" ? "text-[#F24E1E]" : "text-black"
            }`}
          >
            Reviews
          </span>
        </div>
        {user?.data?.role === 2 && (
          <div
            className="flex items-center gap-4 mb-3 cursor-pointer group"
            onClick={() => onTabChange("workDone")}
          >
            <Workdone activeTab={activeTab} />
            <span
              className={`font-medium transition-colors duration-200 ${
                activeTab === "workDone"
                  ? "text-[#F24E1E]"
                  : "text-black group-hover:text-[#F24E1E]"
              }`}
            >
              Work Done
            </span>
          </div>
        )}
        {user?.data?.role === 2 && (
          <div
            className="flex items-center gap-4 mb-3 cursor-pointer group"
            onClick={() => onTabChange("rateCard")}
          >
            <Rupees activeTab={activeTab} />
            <span
              className={`font-medium transition-colors duration-200 ${
                activeTab === "rateCard"
                  ? "text-[#F24E1E]"
                  : "text-black group-hover:text-[#F24E1E]"
              }`}
            >
              Rate Card
            </span>
          </div>
        )}
        <div
          className="flex items-center gap-4 mb-3 cursor-pointer group"
          onClick={() => onTabChange("activitylogs")}
        >
          <OtherStatus activeTab={activeTab} />
          <span
            className={`font-medium transition-colors duration-200 ${
              activeTab === "activitylogs"
                ? "text-[#F24E1E]"
                : "text-black group-hover:text-[#F24E1E]"
            }`}
          >
            Activity Logs
          </span>
        </div>
      </div>
    </>
  );
};

export default ProfileData;
