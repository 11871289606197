import React from "react";
import AddNewSlider from "../components/AddNewSlider";
import SliderList from "../components/SliderList";

const Slider = () => {
  return (
    <>
      <div className="m-4 bg-white p-4 border border-gray-300 rounded-md">
        <div className="p-4 rounded-md border border-gray-300 ">
          <AddNewSlider />
        </div>
        <SliderList />
      </div>
    </>
  );
};

export default Slider;
