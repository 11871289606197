import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import {
  BASE_IMAGE,
  getData,
  postData,
  postFileData,
} from "../../../services/apiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DialogContent,
  DialogHeader,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/ui/button";
import Loader from "../../../common/Loader/Loader";
import Select from "react-select";

interface Slider {
  id: number;
  img: string;
  description: string;
  title: string;
  order_count: number;
  sliderId: any;
  categoryID: any;
}

const SliderList: React.FC = () => {
  const [sliders, setSliders] = useState<Slider[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSlider, setSelectedSlider] = useState<Slider | null>(null);
  const [selectedProfession, setSelectedProfession] = useState<any>(null);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { data, refetch } = useQuery({
    queryKey: ["recentSlider"],
    queryFn: () => getData("slider/get-sliders-by-order"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  }: any = useForm();

  useEffect(() => {
    if (data) {
      const sliderData = data?.data?.map((item: any) => ({
        id: item?.id,
        img: item?.img,
        description: item?.description,
        title: item?.title,
        order_count: item?.order_count,
        sliderId: item?.id,
        categoryID: item?.category_id,
      }));
      setSliders(sliderData);
    }
  }, [data]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(sliders);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    const updatedSliders = reorderedItems.map((item, index) => ({
      ...item,
      order_count: index + 1,
    }));

    setSliders(updatedSliders);
  };

  const handleSubmitOrder = async () => {
    try {
      await postData(
        "slider/update-sliders-order",
        sliders.map((slider) => ({
          id: slider.id,
          order_count: slider.order_count,
        }))
      );
      toast.success("Slider order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Failed to update slider order");
    }
  };

  const handleEditModalOpen = (slider: Slider) => {
    console.log("Modal opened", slider?.categoryID);
    setSelectedProfession(
      getAllcategoriesValue.find(
        (category: any) => category.value === slider?.categoryID
      )
    );
    setIsEditModalOpen(true);
    setSelectedSlider(slider);
    setValue("title", slider.title);
    setValue("description", slider.description);
    setPreviewUrl(`${BASE_IMAGE}${slider.img}`);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedSlider(null);
    setNewImage(null);
    setPreviewUrl(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setNewImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const uploadSlider: any = async () => {
    const formData = new FormData();
    if (newImage) {
      formData.append("image", newImage);
      const response = await postFileData(
        "admin-slider-image-upload",
        formData
      );
      return response.data; // Assume the server returns the file URL or ID
    }
    return null;
  };

  const onSubmit = async (formData: any) => {
    const imagePath: any = await uploadSlider();

    console.log({ ...formData, img: imagePath?.outputPath });

    if (selectedSlider) {
      editSlider({
        sliderId: selectedSlider.sliderId,
        data: {
          ...formData,
          img: imagePath?.outputPath,
          category_id: selectedProfession?.value,
        },
      });
    }
  };

  const { mutate: editSlider, isPending: UpdatePending } = useMutation({
    mutationFn: ({ sliderId, data }: { sliderId: number; data: FormData }) =>
      postData(`slider/update-slider/${sliderId}`, data),
    onSuccess: () => {
      toast.success("Slider updated successfully");
      refetch();
      setIsEditModalOpen(false);
    },
  });

  const { mutate: SliderDelete, isPending } = useMutation({
    mutationFn: (id: any) => postData(`slider/delete-slider/${id}`),
    onSuccess: () => {
      toast.success("Slider deleted successfully");
      refetch();
    },
  });

  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  const getAllcategoriesValue =
    getAllcategories?.data?.data?.map((item: any) => ({
      label: item?.cat_title,
      value: item?.cat_id,
    })) || [];

  const handleDelete = (id: any) => {
    SliderDelete(id);
  };

  return (
    <>
      {isPending ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="mt-4 flex flex-col justify-start">
          <div className="pl-4">
            <p className="font-semibold text-lg">Slider List</p>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="images" direction="vertical">
              {(provided) => (
                <div
                  className="border-2 border-gray-300 p-4 rounded-md mt-4 w-[420px]"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sliders?.map((slider, index) => (
                    <Draggable
                      key={slider.id.toString()}
                      draggableId={slider.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="mb-4 bg-white shadow-sm rounded-md relative"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="absolute px-2 font-semibold text-white w-full z-10 top-24">
                            {slider.title}
                          </div>
                          <div className="absolute px-2 font-normal text-white w-full z-10 top-32">
                            {slider?.description}
                          </div>
                          <div className="relative">
                            <img
                              src={`${BASE_IMAGE}${slider.img}`}
                              alt={`Slider ${index + 1}`}
                              className="h-40 w-full object-cover rounded-md filter brightness-75"
                            />
                            <div className="absolute inset-0 bg-black opacity-25 rounded-md z-0"></div>
                          </div>
                          <div className="flex items-center justify-between my-4">
                            <p
                              className="text-blue-500 cursor-pointer"
                              onClick={() => handleEditModalOpen(slider)}
                            >
                              Edit Slider
                            </p>
                            <p
                              className="text-orange-500 cursor-pointer"
                              onClick={() => handleDelete(slider?.sliderId)}
                            >
                              Delete
                            </p>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="pl-4 mt-4 flex justify-end w-full">
            <button
              className="px-4 py-2 text-orange-500 rounded-md border border-orange-500"
              onClick={handleSubmitOrder}
            >
              Submit Order
            </button>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <Dialog open={isEditModalOpen} onOpenChange={handleEditModalClose}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Edit Slider</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="mb-4">
                <img
                  src={previewUrl || ""}
                  alt="Slider Preview"
                  className="w-full h-40 object-cover rounded-md"
                />

                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                >
                  Select Image
                </label>
                <Input
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                  className="mt-2"
                />
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Category Select
                </label>
                <Select
                  options={getAllcategoriesValue}
                  value={selectedProfession}
                  onChange={(option) => setSelectedProfession(option)}
                  placeholder="Select Category"
                  className="w-full border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <Input
                  id="title"
                  {...register("title", { required: "Title is required" })}
                  className="mt-1"
                />
                {errors.title && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.title.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <Textarea
                  id="description"
                  {...register("description", {
                    required: "Description is required",
                  })}
                  className="mt-1"
                />
                {errors.description && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.description.message}
                  </p>
                )}
              </div>
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleEditModalClose}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {UpdatePending ? "Loading..." : "Update Slider"}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SliderList;
