import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { toast } from "react-toastify";
import Select from "react-select";
import { getData, postData, postFileData } from "../../../services/apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../../common/Loader/Loader";

const EditChildCategoryModal = ({ isOpen, close, category, refetch }: any) => {
  const [childCategoryName, setChildCategoryName] = useState("");
  const [selectedParentCategory, setSelectedParentCategory] =
    useState<any>(null);
  const [image, setImage] = useState<File | null>(null);

  const { data: parentCategoriesResponse, isLoading } = useQuery({
    queryKey: ["getPublishedParentCategory"],
    queryFn: () => getData("category/get-published-parent-categories"),
  });

  const parentCategoryOptions: any = parentCategoriesResponse?.data?.map(
    (cat: any) => ({
      value: cat.cat_id,
      label: cat.cat_title,
    })
  );

  useEffect(() => {
    if (category) {
      setChildCategoryName(category.cat_title || "");
      const parentCat = parentCategoryOptions?.find(
        (cat: any) => cat.value === category.parent_id
      );
      setSelectedParentCategory(parentCat || null);
    }
  }, []);

  const resetFields = () => {
    setChildCategoryName("");
    setSelectedParentCategory(null);
    setImage(null);
  };

  // Use the close function to reset fields
  const handleClose = () => {
    resetFields(); // Reset fields
    close(); // Call the close function
  };

  const iconImage = async () => {
    if (!image) return null;

    const formData = new FormData();
    formData.append("profile", image);
    const response = await postFileData("/single-image-upload", formData);
    return response.data;
  };

  const { mutate: updateChildCategory, isPending } = useMutation({
    mutationFn: async (id: number) => {
      const imageUrl = await iconImage();
      const dataToUpdate = {
        cat_title: childCategoryName,
        parent_id: selectedParentCategory?.value,
        ...(imageUrl && { cat_img: imageUrl?.outputPath }),
      };
      return await postData(
        `category/update-child-category/${id}`,
        dataToUpdate
      );
    },
    onSuccess: () => {
      refetch();
      toast.success("Child category updated successfully");
      close();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Error updating category");
    },
  });

  const handleUpdate = () => {
    if (!childCategoryName) {
      toast.error("Child category name is compulsory");
      return;
    }
    if (!selectedParentCategory) {
      toast.error("Parent category is compulsory");
      return;
    }

    updateChildCategory(category.cat_id);
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      // Check if the uploaded file is indeed an SVG
      if (file.type === "image/*") {
        setImage(file); // Set the image if it's valid
      } else {
        toast.success("Please upload a valid SVG image."); // Alert the user
        e.target.value = ""; // Clear the input for re-upload
      }
    }
  };

  if (isPending) {
    return (
      <div className="flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <h2 className="text-xl font-semibold mb-4">Edit Child Category</h2>

        <div className="mb-4">
          <label htmlFor="parentCategory" className="block mb-1">
            Parent Category
          </label>
          <Select
            id="parentCategory"
            options={parentCategoryOptions}
            value={selectedParentCategory}
            onChange={setSelectedParentCategory}
            placeholder="Select Parent Category"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="childCategory" className="block mb-1">
            Child Category
          </label>
          <Input
            type="text"
            id="childCategory"
            placeholder="Enter Child Category"
            value={childCategoryName}
            onChange={(e) => setChildCategoryName(e.target.value)}
            className="w-full"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="categoryImage" className="block mb-1">
            Upload Image
          </label>
          <Input
            type="file"
            id="categoryImage"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full"
          />
        </div>

        <div className="flex justify-end">
          <Button onClick={handleUpdate} className="bg-red-500 text-white">
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditChildCategoryModal;
