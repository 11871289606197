import React, { useState } from "react";
import Pagination from "../../../common/components/Pagination";
import SearchBar from "../../../common/SearchBar/SearchBar";
import ReportAdvanceFilter from "../components/ReportAdvanceFIlter";
import ReportTable from "../components/ReportTable";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";
import { File } from "lucide-react";
// Assuming you're using react-feather for icons

const Report = () => {
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [status, setStatus] = useState<any>(undefined);
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Handle pagination
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Handle search input change
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  // Handle filter application
  const handleFilterChange = (filters: any) => {
    setStartDate(filters.startDate);
    setEndDate(filters.endDate);
    setStatus(filters.selectedStatus?.value); // Assuming `selectedStatus` is an object from react-select
    setCurrentPage(1);
  };

  // Handle filter clearing
  const clearFilters = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setStatus(undefined);
  };

  // Fetch data using react-query based on filters, search, and pagination
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["support", searchTerm, currentPage, startDate, endDate, status],
    queryFn: () =>
      getData(
        `report/get-all-reports-by-filters?search_term=${
          searchTerm || ""
        }&page=${currentPage}&limit=${itemsPerPage}&status=${
          status || ""
        }&start_date=${startDate || ""}&end_date=${endDate || ""}`
      ),
  });

  console.log({ data });

  // Function to export data to Excel
  const handleExport = async () => {
    let queryString = `report/get-all-reports-by-filters?search_term=${
      searchTerm || ""
    }&page=1&limit=10000&status=${status || ""}&start_date=${
      startDate || ""
    }&end_date=${endDate || ""}`;

    try {
      const allDataResponse = await getData(queryString);
      const tableData = allDataResponse?.data?.data;

      if (!tableData || tableData.length === 0) {
        toast.error("No data available to export.");
        return;
      }

      // Format the data for Excel export
      const formattedData = tableData.map((item: any) => ({
        ReportName: item?.report_by_name,
        ReportToName: item?.report_to_name,
        Message: item?.report_msg,
        RegistrationDate: moment(item?.report_datetime).format("DD/MM/YYYY"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report Data");
      XLSX.writeFile(workbook, "Report.xlsx");

      toast.success("Data exported successfully!");
    } catch (error) {
      console.error("Error fetching data for export:", error);
      toast.error("Failed to export data.");
    }
  };

  return (
    <div className="m-4">
      {/* Search Bar */}
      <div>
        <SearchBar
          onChange={handleSearchChange}
          placeholder="Search report by message"
        />
      </div>

      {/* Export Button */}
      <div className="flex justify-end mt-4">
        <button
          onClick={handleExport}
          className="px-6 py-2 bg-green-200 hover:bg-green-300 rounded-md flex items-center justify-center flex-row gap-2 transition duration-300"
        >
          <span className="text-[#119C2B] font-semibold">Export</span>
          <File className="w-5 h-5 text-[#119C2B]" />
        </button>
      </div>

      {/* Advanced Filter */}
      <div className="mt-4">
        <ReportAdvanceFilter
          onApplyFilters={handleFilterChange}
          clearFilter={clearFilters}
          totalResults={data?.data?.totalResults}
        />
      </div>

      {/* Support Table */}
      <div className="mt-4">
        <ReportTable
          data={data?.data?.data || []}
          isLoading={isLoading}
          refetch={refetch}
        />
      </div>

      {/* Pagination */}
      <div className="mt-4">
        <Pagination
          totalItems={data?.data?.totalResults || 0} // Dynamic total items
          itemsPerPage={data?.data?.limit}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Report;
