import { useQuery } from "@tanstack/react-query";
import AllFeedbackCardDesign from "../components/AllFeedbackCardDesign";
import { getData } from "../../../services/apiService";
import Pagination from "../../../common/components/Pagination";
import { useState } from "react";
import AllFeedbackFilter from "../components/AllFeedbackFilter";
import Search from "../components/SearchFeedback";

const AllFeedback = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fileterFeedbackStatus, setFileterFeedbackStatus] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(null); // Changed to null
  const [endDate, setEndDate] = useState<any>(null); // Changed to null
  const [role, setRole] = useState<any>();
  const itemsPerPage = 10;

  const {
    data,
    isLoading,
    refetch: getAllReviewsRefetch,
    isError,
    error,
  } = useQuery({
    queryKey: [
      "getAllReviews",
      currentPage,
      fileterFeedbackStatus,
      searchTerm,
      role,
      startDate,
      endDate,
    ],
    queryFn: () => {
      let url = `review/get-all-reviews-by-filter?search_term=${searchTerm}&role=${
        role === undefined ? "" : role
      }&page=${currentPage}&limit=${itemsPerPage}`;

      if (
        fileterFeedbackStatus !== undefined &&
        fileterFeedbackStatus !== null &&
        fileterFeedbackStatus !== ""
      ) {
        url += `&review_status=${fileterFeedbackStatus}`;
      }

      // Append startDate and endDate to the API call
      if (startDate) {
        url += `&start_date=${startDate.format("YYYY-MM-DD")}`; // Assuming startDate is a moment object
      }
      if (endDate) {
        url += `&end_date=${endDate.format("YYYY-MM-DD")}`; // Assuming endDate is a moment object
      }

      return getData(url);
    },
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const totalItems = data?.data?.totalResults || 0;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const filterfeedbackStatus = (status: any) => {
    setFileterFeedbackStatus(status);
    setCurrentPage(1); // Reset page to 1 when filter is applied
    console.log("Filtered status from child:", status);
  };

  const handleClearFilter = () => {
    filterfeedbackStatus("");
    setStartDate(null); // Clear the date range on filter clear
    setEndDate(null); // Clear the date range on filter clear
  };

  const handleSearch = (search: any) => {
    setSearchTerm(search);
    setCurrentPage(1);
    console.log("Searching...", search);
  };

  const handleRole = (roleno: any) => {
    setCurrentPage(1);
    setRole(roleno);
  };

  return (
    <div className="mx-4">
      <div>
        <Search onChange={handleSearch} handleRole={handleRole} />
      </div>
      <div className="flex justify-end my-4">
        <AllFeedbackFilter
          filterfeedbackStatus={filterfeedbackStatus}
          handleClearFilter={handleClearFilter}
          setStartDate={setStartDate} // Pass setStartDate to filter component
          setEndDate={setEndDate} // Pass setEndDate to filter component
        />
      </div>
      {isError && <p>Error loading feedback: {error?.message}</p>}
      <AllFeedbackCardDesign
        feedback={data?.data?.data}
        loading={isLoading}
        getAllReviewsRefetch={getAllReviewsRefetch}
      />
      {totalItems > 0 && (
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AllFeedback;
