import React, { useState } from "react";
import Select from "react-select";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Ghost, XIcon } from "lucide-react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

const SupportAdvanceFilter = ({
  onApplyFilters,
  clearFilter,
  totalResults,
}: any) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedDates, setSelectedDates] = useState<any>([]);

  const statusOptions = [
    { label: "Open", value: 1 },
    { label: "In Progress", value: 2 },
    { label: "Resolved", value: 3 },
  ];

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleApplyFilter = () => {
    const filters = {
      selectedStatus,
      startDate: selectedDates[0]
        ? selectedDates[0].format("YYYY-MM-DD")
        : null,
      endDate: selectedDates[1] ? selectedDates[1].format("YYYY-MM-DD") : null,
    };

    onApplyFilters(filters);

    handleModalToggle();
  };

  const handleClearFilter = () => {
    setSelectedStatus(null);
    setSelectedDates([]);
    clearFilter();
    setModalOpen(false);
  };

  console.log("hello", selectedDates);

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <div>
          <p className="font-semibold text-gray-400 text-sm">Total</p>
          <p className="font-semibold text-black text-sm">{totalResults}</p>
        </div>

        {/* Right side - Advanced Filter Button */}
        <Button
          variant="ghost"
          onClick={handleModalToggle}
          className="text-orange-500 font-semibold"
        >
          Advanced Filter
        </Button>
      </div>

      {/* Advanced Filter Modal */}
      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="font-semibold">Advanced Filter</p>
          </div>

          <div className="mt-4">
            <Select
              options={statusOptions}
              value={selectedStatus}
              onChange={(option) => setSelectedStatus(option)} // Ensure react-select event is handled correctly
              placeholder="Filter by Status"
              isClearable
            />
          </div>

          <div className="mt-4 w-full relative">
            <DatePicker
              maxDate={moment().format("YYYY-MM-DD")}
              value={selectedDates}
              onChange={setSelectedDates}
              range
              placeholder="Select a date range"
              style={{
                backgroundColor: "white",
                width: "259%", // Full width of the container
                height: "40px",
                borderRadius: "5px",
                fontSize: "14px",
                padding: "10px",
              }}
            />

            {selectedDates.length > 0 && (
              <XIcon
                className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                onClick={() => setSelectedDates([])} // Clears the date selection
                size={20}
              />
            )}
          </div>

          {/* Apply and Cancel Buttons */}
          <div className="flex justify-end gap-4 mt-6">
            <Button
              onClick={handleClearFilter}
              className="text-red-600"
              variant={"ghost"}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApplyFilter}
              className="text-blue-500"
              variant={"ghost"}
            >
              Apply
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SupportAdvanceFilter;
