import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ChevronDownIcon, XIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { format, parse } from "date-fns";
import {
  Select as SingleSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker"; // Ensure you import DateObject
import moment from "moment";

const AdvancedFilter = ({
  antoilerCount,
  onApplyFilters,
  clearFilter,
}: any) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLocality, setSelectedLocality] = useState<any>([]);
  const [selectedProfession, setSelectedProfession] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [clearFilterShow, setClearFilterShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<DateObject | null>(null); // Change to DateObject or null
  const [endDate, setEndDate] = useState<DateObject | null>(null); // Change to DateObject or null

  const localValues = localStorage.getItem("filters");

  useEffect(() => {
    const LV = localValues ? JSON.parse(localValues) : null;
    if (LV) {
      setSelectedGender(LV.selectedGender || "");
      setSelectedLocality(LV.selectedLocality || []);
      setSelectedProfession(LV.categoryId || null);
      setSelectedStatus(LV.selectedStatus || "");
      // setSelectedDate(LV.registerationDate || null);
      // Set start and end dates if available
      setStartDate(LV.startDate || null);
      setEndDate(LV.endDate || null);

      setClearFilterShow(
        LV.selectedGender ||
          LV.selectedLocality?.length > 0 ||
          LV.categoryId ||
          LV.selectedStatus ||
          LV.registerationDate ||
          LV.startDate ||
          LV.endDate
      );
    }
  }, [localValues]);

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleApplyFilter = () => {
    console.log(
      "Applying filters:",
      selectedGender,
      selectedLocality,
      selectedStatus,
      selectedProfession,
      selectedDate,
      startDate,
      endDate
    );

    if (
      selectedLocality.length > 0 ||
      selectedProfession ||
      selectedStatus !== "" ||
      selectedGender !== "" ||
      startDate ||
      endDate
    ) {
      setClearFilterShow(true);
    } else {
      setClearFilterShow(false);
    }

    onApplyFilters(
      selectedGender,
      selectedLocality,
      selectedStatus,
      selectedProfession,
      selectedDate,
      startDate,
      endDate
    );

    handleModalToggle(); // Close modal after applying filters
  };

  const handleClearFilter = () => {
    setClearFilterShow(false);
    setSelectedGender("");
    setSelectedLocality([]);
    setSelectedProfession(null);
    setSelectedStatus("");
    setStartDate(null);
    setEndDate(null);
    clearFilter();
  };

  const { data: getAllLocation } = useQuery({
    queryKey: ["getAllLocation"],
    queryFn: () => getData("user/get-all-locations"),
  });

  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  const locationOptions =
    getAllLocation?.data?.map((location: any) => ({
      label: location,
      value: location,
    })) || [];

  const getAllcategoriesValue =
    getAllcategories?.data?.data?.map((item: any) => ({
      label: item?.cat_title,
      value: item?.cat_id,
    })) || [];

  const defaultOption = { value: "", label: "Select an option" };
  const professionOptions = [defaultOption, ...getAllcategoriesValue];

  const convertDateForInput = (dateString: any) => {
    if (!dateString) return "";
    try {
      const parsedDate = parse(dateString, "dd-MM-yyyy", new Date());
      if (!isNaN(parsedDate.getTime())) {
        return format(parsedDate, "yyyy-MM-dd");
      }
    } catch (error) {
      console.error("Date parsing error:", error);
    }
    return "";
  };

  return (
    <>
      <div className="mt-10 flex justify-between items-center">
        <div>
          <p className="font-semibold text-gray-400 text-sm">Total</p>
          <p className="text-md font-bold">{antoilerCount}</p>
        </div>
        <div className="flex gap-2 items-center">
          {clearFilterShow && (
            <p
              className="text-blue-600 font-semibold cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filter
            </p>
          )}
          <Button
            variant="ghost"
            className="text-orange-500 font-semibold"
            onClick={handleModalToggle}
          >
            Advanced Filter
            <ChevronDownIcon size={20} className="ml-2" />
          </Button>
        </div>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center mt-4">
            <p className="font-semibold">Advanced Filter</p>
            <p
              className="ml-4 text-red-600 font-semibold cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filter
            </p>
            <p
              onClick={handleApplyFilter}
              className="ml-auto cursor-pointer text-[#0085FF]"
            >
              Apply Filter
            </p>
          </div>

          {/* Locality Filter */}
          <div className="mt-4 relative">
            <Select
              isMulti
              options={locationOptions}
              value={selectedLocality}
              onChange={setSelectedLocality}
              placeholder="Filter By Locality"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
              styles={{
                multiValueLabel: (base) => ({
                  ...base,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  maxWidth: "200px",
                  whiteSpace: "normal",
                }),
              }}
            />
          </div>

          {/* Profession Filter */}
          <div className="mt-4 relative">
            <Select
              options={professionOptions}
              value={selectedProfession}
              onChange={(value) => setSelectedProfession(value)}
              placeholder="Filter By Profession"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
              isClearable={true}
            />
          </div>

          {/* Status Filter */}
          <div className="mt-4 relative">
            <SingleSelect
              onValueChange={(value) => setSelectedStatus(value)}
              value={selectedStatus}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Current Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Pending</SelectItem>
                <SelectItem value="1">Verify</SelectItem>
                <SelectItem value="2">Published</SelectItem>
                <SelectItem value="3">Unpublished</SelectItem>
                <SelectItem value="4">Blocked</SelectItem>
                <SelectItem value="5">Deactivate</SelectItem>
              </SelectContent>
            </SingleSelect>
            {selectedStatus && (
              <XIcon
                className="absolute right-10 top-3 text-gray-400 cursor-pointer"
                onClick={() => setSelectedStatus("")}
                size={20}
              />
            )}
          </div>

          {/* Gender Filter */}
          <div className="mt-4 relative">
            <SingleSelect
              onValueChange={setSelectedGender}
              value={selectedGender}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Gender" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="m">Male</SelectItem>
                <SelectItem value="f">Female</SelectItem>
              </SelectContent>
            </SingleSelect>
            {selectedGender && (
              <XIcon
                className="absolute right-10 top-3 text-gray-400 cursor-pointer"
                onClick={() => setSelectedGender("")}
                size={20}
              />
            )}
          </div>

          {/* Date Filter */}
          {/* <div className="mt-4 relative">
            <input
              type="date"
              placeholder="Filter By Date"
              className="w-full p-2 bg-white rounded-md text-gray-900 outline-none border border-gray-300"
              value={convertDateForInput(selectedDate || "")}
              onChange={(e) => {
                const date = new Date(e.target.value);
                if (!isNaN(date.getTime())) {
                  setSelectedDate(format(date, "dd-MM-yyyy"));
                } else {
                  setSelectedDate(null);
                }
              }}
              max={new Date().toISOString().split("T")[0]}
            />
            {selectedDate && (
              <XIcon
                className="absolute right-10 top-3 text-gray-400 cursor-pointer"
                onClick={() => setSelectedDate(null)}
                size={20}
              />
            )}
          </div> */}

          {/* Multi-Date Picker */}
          <div className="mt-4 relative">
            <DatePicker
              maxDate={moment().format("YYYY-MM-DD")}
              range
              value={[startDate, endDate]} // DateObject
              onChange={(dates: any) => {
                if (dates && (dates.length === 1 || dates.length === 2)) {
                  if (dates.length === 2) {
                    const DateSplit = dates.toString().split(",");
                    const [start, end] = DateSplit;
                    setStartDate(start);
                    setEndDate(end);
                  } else if (dates.length === 1) {
                    console.log(dates.toString()); //

                    const start = dates.toString();
                    setStartDate(start);
                    setEndDate(start);
                    console.log("singledate", startDate, endDate);
                  }
                } else {
                  setStartDate(null);
                  setEndDate(null); // Clear the dates if no date is selected
                }
              }}
              placeholder="Select a date range"
              style={{
                position: "absolute",
                backgroundColor: "white",
                width: "100%",
                height: "24px",
                borderRadius: "5px",
                fontSize: "14px",
                padding: "17px 10px",
                top: -9,
              }} // Ensuring full width
            />

            {startDate && (
              <XIcon
                className="absolute right-3 top-0 text-gray-400 cursor-pointer"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
                size={20}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdvancedFilter;
