import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "../../../components/ui/input";
import { Checkbox } from "../../../components/ui/checkbox";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import { postData, postFileData } from "../../../services/apiService";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader/Loader";

// Define types for props
interface CategoryCreateFormProps {
  parentCategory: Array<{ cat_id: number; cat_title: string }>;
  isModalOpen: boolean;
  closeModal: () => void;
  refetch: () => void;
}

const CategoryCreateForm: React.FC<CategoryCreateFormProps> = ({
  parentCategory,
  closeModal,
  refetch,
}) => {
  const { register, handleSubmit, control, reset } = useForm();
  const [categoryImage, setCategoryImage] = useState<File | null>(null);

  // Mutation for form submission
  const { mutate, isPending } = useMutation({
    mutationFn: (data: any) => postData("category/create-new-category", data),
    onSuccess: () => {
      toast.success("Category created successfully");
      refetch();
      reset();
      closeModal();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message?.message || "An error occurred"
      );
    },
  });

  // Image upload function
  const uploadProfilePic = async () => {
    if (categoryImage) {
      const formData = new FormData();
      formData.append("profile", categoryImage);
      const response = await postFileData("/single-image-upload", formData);
      return response?.data || null;
    }
    return null;
  };

  // Form submission
  const onSubmit = async (data: any) => {
    const uploadedImage = await uploadProfilePic();
    const categoryData = {
      parent_id: data.parentCategory?.value || 0,
      cat_title: data.categoryName,
      is_featured: data.featured ? 1 : 0,
      cat_img: uploadedImage?.outputPath,
    };
    // console.log("h",categoryData);
    mutate(categoryData);
  };

  // Map parentCategory to options for Select component
  const parentCategoryOptions = parentCategory.map((category) => ({
    value: category.cat_id,
    label: category.cat_title,
  }));

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Category Create Form</h2>
      {isPending ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          {/* Parent Category (React Select) */}
          <div>
            <Label htmlFor="parentCategory">Parent Category</Label>
            <Controller
              name="parentCategory"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={parentCategoryOptions}
                  isClearable
                  placeholder="Select Parent Category"
                  className="mt-1"
                  menuPlacement="bottom"
                />
              )}
            />
          </div>

          {/* Category Name */}
          <div>
            <Label htmlFor="categoryName">Category Name</Label>
            <Input
              type="text"
              placeholder="Category Name"
              {...register("categoryName", { required: true })}
              className="mt-1 w-full"
            />
          </div>

          {/* Image Upload */}
          <div>
            <Label htmlFor="categoryImage">Upload Image</Label>
            <Input
              type="file"
            accept="image/svg+xml"
              onChange={(e) => setCategoryImage(e.target.files?.[0] || null)}
              className="mt-1 w-full"
            />
          </div>

          {/* Featured Checkbox */}
          <div className="mt-4">
            <Checkbox {...register("featured")} id="featured" />
            <Label htmlFor="featured" className="ml-2">
              Featured
            </Label>
            <p className="text-xs text-gray-500 mt-1">
              Select this checkbox to mark the category as featured.
            </p>
          </div>

          {/* Buttons */}
          <div className="col-span-1 md:col-span-2 flex gap-4">
            <Button
              type="button"
              onClick={() => reset()}
              className="w-full mt-4 bg-gray-400"
            >
              Reset
            </Button>
            <Button type="submit" className="w-full mt-4 bg-orange-500">
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default CategoryCreateForm;
