import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ArrowRight, Eye, EyeOff } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const mutation = useMutation({
    mutationFn: (data: any) => postData(`auth/admin-login`, data),
    onSuccess: (data: any) => {
      if (data?.data?.data?.role === "sales") {
        localStorage.setItem("access_token", data?.data?.access_token);
        toast.success("Login successful");
        navigate("/antoiler-create-account");
      } else {
        localStorage.setItem("access_token", data?.data?.access_token);
        toast.success("Login successful");
        navigate("/dashboard");
      }
    },
    onError: (error: any) => {
      console.log(error?.response?.data?.message?.message);
      const errorMessage =
        error?.response?.data?.message?.message ||
        "Login failed: Invalid credentials";
      toast.error(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  return (
    <section className="min-h-screen flex flex-col lg:flex-row">
      {/* Image container, hidden on mobile */}
      <div className="hidden lg:block lg:w-1/2">
        <img
          className="h-full w-full object-cover"
          src="./work.avif"
          alt="Login background"
        />
      </div>

      {/* Form section, full width on mobile */}
      <div className="flex-1 flex items-center justify-center px-4 py-8 lg:px-8 lg:py-24">
        <div className="w-full max-w-sm sm:max-w-md">
          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl">
            {/* <span className="text-orange-600">A</span>ntoiler */}
            <img src="./logo.png" alt="" />
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
            {/* Email Input */}
            <div>
              <label
                htmlFor="email"
                className="text-base font-medium text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  className="w-full h-12 rounded-md border border-gray-300 bg-transparent px-3 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  placeholder="Email"
                  {...register("username", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Enter a valid email address",
                    },
                  })}
                />
                {errors.username?.message && (
                  <p className="text-red-500 text-sm mt-2">
                    {String(errors.username.message)}
                  </p>
                )}
              </div>
            </div>
            {/* Password Input */}
            <div>
              <label
                htmlFor="password"
                className="text-base font-medium text-gray-900"
              >
                Password
              </label>
              <div className="mt-2 relative">
                <input
                  id="password"
                  className="w-full h-12 rounded-md border border-gray-300 bg-transparent px-3 pr-10 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <div
                  className="absolute top-3 right-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                </div>
                {errors.password?.message && (
                  <p className="text-red-500 text-sm mt-2">
                    {String(errors.password.message)}
                  </p>
                )}
              </div>
            </div>
            {/* Submit Button */}
            <button
              type="submit"
              className="w-full rounded-md bg-black px-3 py-3 font-semibold text-white hover:bg-black/80"
              disabled={mutation.isPending}
            >
              {mutation.isPending ? "Logging in..." : "Get started"}
              <ArrowRight className="ml-2 inline" size={16} />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
