import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";

// RateTypeDropdown component
const RateTypeDropdown = ({ onSelect, selected }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { label: "Per Hour", value: "Per Hour" },
    { label: "Per Day", value: "Per Day" },
    { label: "Per Month", value: "Per Month" },
    { label: "Per Service", value: "Per Service" },
  ];

  const selectedOption =
    options.find((option) => option.value === selected) || options[0];

  const handleSelectOption = (option: any) => {
    console.log("handleSelectOption", option);
    onSelect(option.value);

    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white border border-gray-300 rounded-md px-4 py-2 w-48 text-left flex justify-between items-center focus:outline-none"
      >
        {selectedOption.label}
        <svg
          className={`w-5 h-5 ml-2 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleSelectOption(option)}
              className="block px-4 py-2 text-left w-full hover:bg-gray-100"
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RateTypeDropdown;
