import React from "react";
import TabData from "../components/TabData";
import UserActivityReport from "../components/UserActivityReport";
import RecentAddedUser from "../components/RecentAddedUser";
import Feedback from "../components/FeedbackCard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData, postData } from "../../../services/apiService"; // Import postData
import Loader from "../../../common/Loader/Loader";
import { Button } from "../../../components/ui/button";
import { toast } from "react-toastify"; // Ensure correct import for toast

const Dashboard: React.FC = () => {
  // Fetch dashboard statistics
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardStatistics"],
    queryFn: () => getData("user/get-dashboard-statistics"),
  });

  // Mutation for sending app update notifications
  const mutation = useMutation({
    mutationFn: () => postData("user/app_update"), // Call your global postData function
    onSuccess: () => {
      toast.success("App update notifications sent successfully!"); // Show success toast
    },
    onError: () => {
      toast.error("An error occurred while sending notifications!"); // Handle error toast
    },
  });

  // Handle click event for Antoiler Update button
  const handleAntoilerUpdate = () => {
    mutation.mutate(); // Trigger the mutation on button click
  };

  // Show loader while fetching data
  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-6 md:p-8 lg:p-6 w-full">
      <div className="flex justify-end mb-4">
        <Button
          className="bg-orange-600 text-white hover:none"
          onClick={handleAntoilerUpdate}
          disabled={mutation.isPending} // Disable button when loading
        >
          {mutation.isPending ? (
            <div className="flex items-center">
              <Loader /> {/* Show loader inside button when loading */}
              <span className="ml-2">Updating...</span>
            </div>
          ) : (
            "Send App Update"
          )}
        </Button>
      </div>

      {/* Tab Data Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5">
        {data?.data?.map((item: any, index: number) => (
          <TabData
            key={index}
            title={item?.title}
            number={item?.number}
            growth={Math.floor(item?.growth)}
          />
        ))}
      </div>

      {/* Section for User Activity Report and Recent Added User */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 mt-6">
        <div className="col-span-1 lg:col-span-8">
          <UserActivityReport />
        </div>

        <div className="col-span-1 lg:col-span-4">
          <RecentAddedUser />
        </div>
      </div>

      {/* Feedback Section */}
      <div className="mt-6">
        <Feedback />
      </div>
    </div>
  );
};

export default Dashboard;
