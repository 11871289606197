import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Accordion from "./Accordian";
import EditRateCardAccordion from "./EditRateCardAccordian";
import { getData } from "../../../services/apiService";
import AddRateCard from "./AddRateCard";

const RateCard = () => {
  const [accordionTab, setAccordionTab] = useState("accordion");
  const antoiler = useSelector((state: any) => state?.AntoilerReview);

  const userId = antoiler?.data?.user_id;

  const { data, refetch: rateCardRefetch } = useQuery({
    queryKey: ["getRateCardByUserId", userId],
    queryFn: () => {
      return getData(`rate-card/get-rate-card-by-user-id/${userId}`);
    },
    enabled: !!userId,
  });

  const handleAccordion = (tab: string) => {
    console.log("Hello", tab);
    rateCardRefetch();
    setAccordionTab(tab);
  };

  return (
    <div className="mt-6 bg-white rounded-md">
      <div className="flex justify-between items-center p-4">
        <div>
          <p className="font-bold text-lg">Rate Card</p>
          <p className="text-sm text-gray-500">
            Antoilers can showcase their charges for the services they take.
          </p>
        </div>

        <div className="flex justify-end items-start gap-4">
          {accordionTab !== "addRateCard" && (
            <button
              className="text-[#F24E1E]"
              onClick={() => handleAccordion("addRateCard")}
            >
              Add Cards
            </button>
          )}

          {accordionTab !== "editaccordion" && (
            <button
              className="text-[#0085FF]"
              onClick={() => handleAccordion("editaccordion")}
            >
              Edit Rates
            </button>
          )}

          {(accordionTab === "editaccordion" ||
            accordionTab === "addRateCard") && (
            <button
              className="text-[#0085FF]"
              onClick={() => handleAccordion("accordion")}
            >
              View Rate Card
            </button>
          )}
        </div>
      </div>

      <div className="p-4">
        {accordionTab === "addRateCard" && (
          <AddRateCard refetch={rateCardRefetch} />
        )}
        {accordionTab === "accordion" && <Accordion data={data} />}
        {accordionTab === "editaccordion" && (
          <EditRateCardAccordion
            data={data}
            refetch={rateCardRefetch}
            onClick={handleAccordion}
          />
        )}
      </div>
    </div>
  );
};

export default RateCard;
