// check commit 04-09-2024 / 16-07-2024 / 17-09-2024 /  18-09-2024 / new / new2 / new4
import React, { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Cloud, Calendar, ChevronDown } from "lucide-react";
import { RxCross2 } from "react-icons/rx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getData, postData, postFileData } from "../../../services/apiService";
import { DialPad } from "../../../common/icons";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader/Loader";
import CategorySelector from "../../../common/components/dropdowns/CategorySelector";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { useSize } from "../../../common/components/Layout/useSize.hook";
// import CustomSelect from "../../../common/CustomSelect/CustomSearchSelect";
// import CustomSearchSelect from "../../../common/CustomSelect/CustomSearchSelect";
import { FaCamera, FaTimes } from "react-icons/fa";
import Button from "../../../domains/Dashboard/components/Button";
import { FiPlus } from "react-icons/fi";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";

const libraries: any = ["places"];

type FormData = {
  first_name: string;
  gender: string;
  mobile: string;
  dob: Date | null;
  address: string;
  categories_id: string;
  experince: string;
  location_area: string;
  user_rate: Number;
  rate_type: string;
  profile_pic: File | null;
};

let isMobile = true; // variable to check width

const AntoilerCreateAccount: React.FC = () => {
  // const isMobile = useViewport();

  const [files, setFiles] = useState<File[]>([]);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  // const [categoriesID, setCategoriesID] = useState<string | null>(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const [cameraType, setCameraType] = useState<"user" | "environment">(
    "environment"
  );
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const [categoryError, setCategoryError] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const webcamRef = React.useRef<any>();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    // getValues,
  } = useForm<FormData>({
    defaultValues: {
      dob: null,
    },
  });

  const windowsize = useSize();
  const handleWindowSizeChange = () => {
    isMobile = window.innerWidth < 700 ? true : false;
  };

  const [addressDetails, setAddressDetails] = useState<any>({
    formatted_address: "",
    lat: null,
    lng: null,
    locality: "",
  });

  const { data: getAllcategories } = useQuery({
    queryKey: ["getcategories", searchTerm],
    queryFn: async () => {
      const resultData = await getData(`category/get-all-categories`);
      return resultData?.data || {};
    },
  });

  // const switchCamera = () => {
  //   setCameraType((prevType) => (prevType === "user" ? "environment" : "user"));
  // };

  const uploadProfilePic = async () => {
    const formData = new FormData();
    if (profileImage) {
      formData.append("profile", profileImage);
      const response = await postFileData("/single-image-upload", formData);
      return response.data; // Assume the server returns the file URL or ID
    }
    return null;
  };

  const uploadDocuments = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    const response = await postFileData("multiple-files-upload", formData);
    return response.data; // Assume the server returns an array of file URLs or IDs
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: FormData) => {
      if (
        selectedCategory === null ||
        selectedCategory === undefined ||
        selectedCategory === ""
      ) {
        setCategoryError(true);
        return "";
      }

      setCategoryError(false);
      let profilePicUrl;
      let documentsUrls;

      try {
        // Upload profile picture
        profilePicUrl = await uploadProfilePic();

        if (files.length > 0) {
          documentsUrls = await uploadDocuments();
        }

        const finalData = {
          ...data,
          profile_pic: profilePicUrl?.outputPath,
          ...(files.length > 0 && { documents: documentsUrls?.outputPath }),
          address: "",
          location_area: addressDetails.locality,
          latitude: addressDetails.lat,
          longitude: addressDetails.lng,
          categories_id: selectedCategory,
        };

        // console.log(finalData);
        await postData("user/create-new-user", { ...finalData });
      } catch (error) {
        console.error("Error submitting data:", error);
        throw error;
      }
    },
    onSuccess: () => {
      toast.success("User successfully created");
      setProfileImage(null);
      setAddress("");
      setAddressDetails(null);
      setFiles([]);
      if (!isMobile) {
        navigate("/all-antoilers");
      }
      reset();
    },
    onError: (error: any) => {
      console.log(error?.response?.data?.message?.message);
      toast.error(error?.response?.data?.message?.message);
      setFiles([]);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleImageDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const dataURLtoFile = (dataurl: string, filename: string): File => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      const imageFile = dataURLtoFile(
        imageSrc,
        Date.now().toString() + "webcam-capture.jpg"
      );
      console.log(imageFile);
      setProfileImage(imageFile);
      setUseWebcam(false);
    }
  }, [webcamRef]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (
      selectedCategory === null ||
      selectedCategory === undefined ||
      selectedCategory === ""
    ) {
      setCategoryError(true);
      return;
    }
    mutate(data);
  };

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [address, setAddress] = useState("");

  const onLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const cityName = place.address_components.find((component: any) =>
        component.types.includes("locality")
      ).long_name;

      if (cityName.toLowerCase() === "gwalior") {
        const formatted_address = place.formatted_address;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const locality =
          place.address_components.find((component: any) =>
            component.types.includes("sublocality")
          )?.long_name || "";

        setAddressDetails({
          formatted_address,
          lat,
          lng,
          locality,
        });

        setAddress(place.formatted_address);
        console.log("Place:", place);
      } else {
        setAddress("");
        console.log("Selected location is not in Gwalior.");
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleCategoryId = (categoryId: any) => {
    console.log("HandleCategoryId:", categoryId);
    // Validation: Set error if category is null, undefined or empty string
    if (categoryId === undefined || categoryId === null || categoryId === "") {
      setCategoryError(true);
    } else {
      setCategoryError(false);
      setSelectedCategory(categoryId);
    }
  };
  const currentDate: any = new Date();

  useEffect(() => {
    isMobile = window.innerWidth < 700 ? true : false;
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [windowsize]);

  const getAllCategories = getAllcategories?.data?.map((item: any) => ({
    value: item.cat_id,
    label: item.cat_title,
  }));

  const handleClickNavigate = () => {
    navigate("/all-customer-list-view-mobile");
  };

  const handleConfirmCancel = () => {
    navigate("/all-antoilers");
  };

  // console.log({ addressDetails });

  return (
    <>
      {isPending ? (
        <div className="flex justify-center items-center h-52">
          <Loader />
        </div>
      ) : (
        <>
          <div>
            <p className=" hidden md:block text-center font-bold text-lg md:text-xl">
              New Antoiler Registration Form
            </p>
            {isMobile && (
              <div className="flex  justify-center">
                <img src="./logo.png" alt="" className="w-56" />
              </div>
            )}
            <p className=" block md:hidden text-center font-bold text-3xl md:text-xl">
              Become an <span className="text-orange-500">Antoiler</span>
            </p>
          </div>

          {isMobile && (
            <>
              <div className="flex justify-end mt-4">
                <Button
                  label="All Antoilers"
                  onClick={handleClickNavigate}
                  style={{ backgroundColor: "#F15A24", color: "white" }}
                  className="rounded-lg flex p-2 items-center"
                  icon={FiPlus}
                  iconPosition="left"
                />
              </div>
            </>
          )}

          {isMobile ? (
            <div className="block md:hidden p-4 md:p-6">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 mobile-screen"
              >
                <div className="flex flex-col space-y-1">
                  <label className="text-sm text-gray-600">Full Name*</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300 "
                    {...register("first_name", { required: true })}
                    onKeyPress={(e) => {
                      if (!/^[A-Za-z\s]+$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.first_name && (
                    <p className="text-red-500 text-sm">
                      Full Name is required
                    </p>
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <label className="text-sm text-gray-600">
                    Date of Birth*
                  </label>
                  <input
                    type="date"
                    className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                    {...register("dob", { required: true })}
                    max={new Date().toISOString().split("T")[0]} // Restrict future dates
                  />
                  {errors.dob && (
                    <p className="text-red-500 text-sm">
                      Date of birth is required
                    </p>
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <label className="text-sm text-[#8E8E8E]">
                    Phone Number*
                  </label>
                  <div className="relative">
                    <input
                      {...register("mobile", {
                        required: "Phone Number is required",
                        minLength: {
                          value: 10,
                          message: "Phone Number must be exactly 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Phone Number must be exactly 10 digits",
                        },
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Invalid Phone Number format",
                        },
                      })}
                      type="number"
                      className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      placeholder="Enter Phone"
                    />
                  </div>
                  {errors.mobile && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.mobile.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <label className="text-sm text-gray-600">
                    Current Address*
                  </label>
                  <LoadScript
                    googleMapsApiKey="AIzaSyCRwb0vA7hZbyngiluURoKWM_635FuiHyA"
                    libraries={libraries}
                  >
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      options={{
                        componentRestrictions: { country: "IN" },
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      />
                    </Autocomplete>
                  </LoadScript>
                  {errors.address && (
                    <p className="text-red-500 text-sm">Address is required</p>
                  )}
                </div>

                <div>
                  <label className="text-sm text-gray-600">
                    Select Category*
                  </label>

                  <CategorySelector
                    handleCategoryId={handleCategoryId}
                    classNames="my-mobile-selector w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300 pac-target-input "
                  />

                  {categoryError && (
                    <p className="text-red-500 text-sm">Category is required</p>
                  )}
                </div>

                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="text-sm text-gray-600">Gender*</label>
                    <select
                      className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      {...register("gender", { required: true })}
                    >
                      <option value="" disabled>
                        Gender
                      </option>
                      <option value="m">Male</option>
                      <option value="f">Female</option>
                    </select>
                    {errors.gender && (
                      <p className="text-red-500 text-sm">Gender is required</p>
                    )}
                  </div>

                  <div className="w-1/2">
                    <label className="text-sm text-gray-600">Experience*</label>
                    <select
                      className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      {...register("experince", { required: true })}
                    >
                      <option disabled>Experience</option>
                      <option value="0">0+ yrs</option>
                      <option value="2">2+ yrs</option>
                      <option value="5">5+ yrs</option>
                      <option value="10">10+ yrs</option>
                      <option value="15">15+ yrs</option>
                      <option value="20">20+ yrs</option>
                    </select>
                    {errors.experince && (
                      <p className="text-red-500 text-sm">
                        Experience is required
                      </p>
                    )}
                  </div>
                </div>

                {/* Rate Type and Rate in one line */}
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="text-sm text-gray-600">Rate*</label>
                    <input
                      type="number"
                      placeholder="Enter Charge"
                      className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      {...register("user_rate", { required: true })}
                    />
                    {errors.user_rate && (
                      <p className="text-red-500 text-sm">Rate is required</p>
                    )}
                  </div>
                  <div className="w-1/2">
                    <label className="text-sm text-gray-600">
                      Service Type*
                    </label>
                    <select
                      className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
                      {...register("rate_type", { required: true })}
                    >
                      <option value="" disabled>
                        Rate Type
                      </option>
                      <option value="Per Hour">Per Hour</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Day">Per Day</option>
                      <option value="Per Service">Per Service</option>
                    </select>
                    {errors.rate_type && (
                      <p className="text-red-500 text-sm">
                        Service type is required
                      </p>
                    )}
                  </div>
                </div>

                {/* Modified Upload Profile section */}
                <div className="flex flex-col space-y-2">
                  <label className="text-sm text-gray-600">
                    Upload Single Profile*
                  </label>

                  {/* Upload from Gallery and Use Camera Buttons */}
                  <div className="flex flex-col md:flex-row border border-gray-300 p-2 rounded-md space-y-2 md:space-y-0 md:space-x-4">
                    {/* Upload from Gallery Button */}
                    <label
                      htmlFor="profile_pic"
                      className="w-full flex items-center justify-center cursor-pointer border border-gray-300 p-2 rounded-md bg-gray-100 hover:bg-gray-200"
                    >
                      <div className="flex flex-col items-center">
                        {/* Keep the "Upload Image" button always visible */}
                        <Cloud className="text-xl" />
                        <p className="text-gray-500 text-sm font-medium mt-2">
                          Upload Image
                        </p>
                      </div>
                      <input
                        {...register("profile_pic", {
                          required: !profileImage,
                        })}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="profile_pic"
                        className="hidden"
                      />
                    </label>
                    {errors.profile_pic && (
                      <p className="text-red-500">
                        {profileImage ? "" : "Profile picture is required."}
                      </p>
                    )}

                    {/* Use Camera Button */}
                    <button
                      type="button"
                      className="w-full md:w-1/2 p-3 flex items-center justify-center space-x-2 bg-gray-100 rounded-md text-gray-500 hover:bg-gray-200"
                      onClick={() => setUseWebcam(true)}
                    >
                      <FaCamera className="text-xl" />
                      <span className="text-sm">Use Camera</span>
                    </button>
                  </div>

                  {/* Webcam Functionality */}
                  {useWebcam && (
                    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
                      {/* Fullscreen webcam */}
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        videoConstraints={{
                          facingMode: cameraType,
                        }}
                        screenshotFormat="image/jpeg"
                        className="w-full h-full object-cover"
                      />

                      {/* Buttons at the bottom */}
                      <div className="absolute bottom-4 w-full flex justify-center space-x-4 px-4">
                        {/* <button
                          onClick={switchCamera}
                          className="p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                        >
                          Switch Camera
                        </button> */}
                        <button
                          onClick={capture}
                          className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                          Capture Photo
                        </button>
                        <button
                          onClick={() => setUseWebcam(false)}
                          className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-600"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Display Captured or Uploaded Image Below the Buttons */}
                  {profileImage && (
                    <div className="relative mt-4 flex justify-center">
                      <img
                        src={URL.createObjectURL(profileImage)}
                        alt="Profile"
                        className="w-1/2 h-auto rounded-lg object-cover"
                      />

                      {/* Cross Icon for Clearing Image */}
                      <button
                        onClick={() => setProfileImage(null)}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                      >
                        <FaTimes className="text-sm" />
                      </button>
                    </div>
                  )}

                  {/* Error handling */}
                  {errors.profile_pic && (
                    <span className="text-red-500 text-xs">
                      {errors.profile_pic.message}
                    </span>
                  )}
                </div>

                {/* Work Done */}

                <div className="mt-6">
                  <div className="mb-4">
                    <p className="font-semibold">Upload Work Done</p>
                  </div>
                  <div className="border-2 border-dashed border-[#A9A9A9] flex items-center justify-center p-6">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      className="hidden"
                      id="file-upload"
                    />
                    <label htmlFor="file-upload" className="cursor-pointer">
                      <p>Upload Pictures</p>
                    </label>
                  </div>
                  <div className="mt-4">
                    {files.length > 0 && (
                      <ul>
                        {files.map((file, index) => (
                          <div
                            className="border p-2 border-blue-500 inline-block m-2 relative"
                            key={index}
                          >
                            <div
                              className="w-6 h-6 bg-red-600 rounded-full absolute -right-2 -top-3 flex items-center justify-center cursor-pointer"
                              onClick={() => handleImageDelete(index)}
                            >
                              <RxCross2 color="white" />
                            </div>
                            <li>{file.name}</li>
                          </div>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full bg-orange-500 text-white py-2 rounded-md"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className=" hidden md:block bg-white rounded-md border border-gray-200 p-4 mt-4 mx-2 sm:mx-4 lg:mx-6 laptop-screen">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                  <div className="md:col-span-3">
                    <div className="w-full h-60 md:h-80 bg-[#D9D9D9] rounded-lg flex flex-col items-center justify-center cursor-pointer">
                      {useWebcam ? (
                        <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute bottom-4 w-full flex justify-center space-x-4 px-4">
                            <button
                              onClick={capture}
                              className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                              Capture Photo
                            </button>
                            <button
                              onClick={() => setUseWebcam(false)}
                              className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-600"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <label
                          htmlFor="profile_pic"
                          className="w-full h-full flex items-center justify-center cursor-pointer"
                        >
                          {profileImage ? (
                            <div className="relative w-full h-full">
                              <img
                                src={URL.createObjectURL(profileImage)}
                                alt="Uploaded"
                                className="w-full h-full rounded-lg object-cover"
                              />
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProfileImage(null);
                                }}
                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                              >
                                <FaTimes className="text-sm" />
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center mb-4 flex-col">
                              <Cloud />
                              <p className="text-gray-500 text-sm font-medium">
                                Upload Image
                              </p>
                            </div>
                          )}
                          <input
                            {...register("profile_pic", {
                              required: !profileImage,
                            })}
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            id="profile_pic"
                            className="hidden"
                          />
                        </label>
                      )}
                      <button
                        type="button"
                        onClick={() => setUseWebcam(true)}
                        className="mt-2 text-gray-500 flex items-center justify-center"
                      >
                        <DialPad /> Capture with Camera
                      </button>
                      {errors.profile_pic && (
                        <div className="flex-col">
                          <p className="text-red-500 ">
                            {profileImage ? "" : "Profile picture is required."}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="md:col-span-9">
                    <div>
                      <p className="font-bold text-lg">Personal Information</p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="mt-4 w-full md:w-1/2">
                        <label className="text-sm text-[#8E8E8E]">
                          Full Name*
                        </label>
                        <input
                          {...register("first_name", {
                            required: "Full Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: "Only alphabetic characters are allowed",
                            },
                          })}
                          type="text"
                          className="mt-1 block w-full rounded-md bg-[#F4F4F4] py-2 px-3 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
                          placeholder="Enter Name"
                          onKeyPress={(e) => {
                            if (!/^[A-Za-z\s]+$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors.first_name && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.first_name.message}
                          </p>
                        )}
                      </div>

                      <div className="mt-4 w-full md:w-1/2 relative">
                        <label className="text-sm text-[#8E8E8E]">
                          Mobile Number*
                        </label>
                        <div className="relative">
                          <input
                            {...register("mobile", {
                              required: "Phone Number is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Phone Number must be exactly 10 digits",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Phone Number must be exactly 10 digits",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Invalid Phone Number format",
                              },
                            })}
                            type="number"
                            className="mt-1 block w-full rounded-md bg-[#F4F4F4] py-2 px-3 pr-10 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
                            placeholder="Enter mobile number"
                          />
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <DialPad />
                          </span>
                        </div>
                        {errors.mobile && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.mobile.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="mt-4 w-full md:w-1/2">
                        <label className="text-sm text-[#8E8E8E]">
                          Current Address*
                        </label>
                        <div>
                          <LoadScript
                            googleMapsApiKey="AIzaSyCRwb0vA7hZbyngiluURoKWM_635FuiHyA"
                            libraries={libraries}
                          >
                            <Autocomplete
                              onLoad={onLoad}
                              onPlaceChanged={onPlaceChanged}
                              options={{
                                componentRestrictions: { country: "IN" },
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Enter Current Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="mt-1 block w-full rounded-md bg-[#F4F4F4] py-2 px-3 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
                              />
                            </Autocomplete>
                          </LoadScript>
                        </div>
                      </div>

                      <div className="mt-4 w-full md:w-1/2">
                        <label className="text-sm text-[#8E8E8E]">
                          Select Profession*
                        </label>
                        <CategorySelector handleCategoryId={handleCategoryId} />
                      </div>
                    </div>

                    <div className="flex gap-2">
                      <div className="mt-4 w-1/2 ">
                        <label className="text-sm text-[#8E8E8E] block">
                          Date of Birth*
                        </label>
                        <div className="mt-1 relative inline-block">
                          <DatePicker
                            selected={watch("dob")}
                            onChange={(date: any) => setValue("dob", date)}
                            className="  p-2 py-2 px-3 text-sm bg-[#F4F4F4] text-gray-900 placeholder-gray-[#7A7A7A] outline-none w-full rounded-md font-semibold"
                            placeholderText="Select Date"
                            dateFormat="dd/MM/yyyy"
                            maxDate={currentDate}
                          />
                          <Calendar className="absolute right-2 text-gray-500 h-5 w-5 pointer-events-none top-3" />
                        </div>
                        {errors.dob && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.dob.message}
                          </p>
                        )}
                      </div>
                      <div className="mt-3 w-1/2 ">
                        <label className="text-sm text-[#8E8E8E]">
                          Gender*
                        </label>
                        <div className="mt-1 flex flex-row space-x-2 items-center bg-[#F4F4F4] px-2 py-2 rounded-md">
                          <label className="flex items-center">
                            <input
                              {...register("gender", {
                                required: "Gender is required",
                              })}
                              type="radio"
                              value="m"
                              className="mr-2"
                              // defaultChecked // Set Male as the default selected radio button
                            />
                            <span className="text-sm text-gray-900">Male</span>
                          </label>
                          <label className="flex items-center">
                            <input
                              {...register("gender", {
                                required: "Gender is required",
                              })}
                              type="radio"
                              value="f"
                              className="mr-2"
                            />
                            <span className="text-sm text-gray-900">
                              Female
                            </span>
                          </label>
                        </div>
                        {errors.gender && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.gender.message}
                          </p>
                        )}
                      </div>

                      <div className="mt-4 w-1/2">
                        <label className="text-sm text-[#8E8E8E]">
                          Experience*
                        </label>
                        <div className="relative">
                          <select
                            {...register("experince", {
                              required: "Previous Experience is required",
                            })}
                            className="p-2 py-2 px-3 text-sm bg-[#F4F4F4] text-[#7A7A7A] placeholder-gray-[#7A7A7A] outline-none w-full rounded-md font-semibold appearance-none"
                          >
                            <option value="" disabled>
                              Select Experience
                            </option>
                            <option value="1">0+ yrs</option>
                            <option value="2">2+ yrs</option>
                            <option value="5">5+ yrs</option>
                            <option value="10">10+ yrs</option>
                            <option value="15">15+ yrs</option>
                            <option value="20">20+ yrs</option>
                          </select>
                          <ChevronDown className="absolute right-2 text-gray-500 h-5 w-5 pointer-events-none top-3" />
                        </div>
                        {errors.experince && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.experince.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col mt-6">
                      <div className="mt-6">
                        <p className="text-md font-semibold">Pricing</p>
                        <div className="mt-1 flex flex-col md:flex-row gap-4">
                          <div className="mt-4 w-full md:w-1/2">
                            <label className="text-sm text-[#8E8E8E]">
                              Service Charge*
                            </label>
                            <input
                              {...register("user_rate", {
                                required: "Rate is required",
                              })}
                              type="number"
                              className="mt-1 block w-full rounded-md bg-[#F4F4F4] py-2 px-3 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
                              placeholder="Enter an amount"
                            />
                            {errors.user_rate && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.user_rate.message}
                              </p>
                            )}
                          </div>

                          <div className="mt-4 w-full md:w-1/2">
                            <label className="text-sm text-[#8E8E8E]">
                              Service Type *
                            </label>
                            <div className="relative">
                              <select
                                {...register("rate_type", {
                                  required: "Rate Type is required",
                                })}
                                className="p-2 py-2 px-3 text-sm bg-[#F4F4F4] text-[#7A7A7A] placeholder-gray-[#7A7A7A] outline-none w-full rounded-md font-semibold appearance-none"
                              >
                                <option value="">Select Rate Type</option>
                                <option value="Per Hour">Per Hour</option>
                                <option value="Per Month">Per Month</option>
                                <option value="Per Day">Per Day</option>
                                <option value="Per Service">Per Service</option>
                              </select>
                              <ChevronDown className="absolute right-2 text-gray-500 h-5 w-5 pointer-events-none top-3" />
                            </div>
                            {errors.rate_type && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.rate_type.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <div className="mb-4">
                          <p className="font-semibold">Upload Work Done</p>
                        </div>
                        <div className="border-2 border-dashed border-[#A9A9A9] flex items-center justify-center p-6">
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            className="hidden"
                            id="file-upload"
                          />
                          <label
                            htmlFor="file-upload"
                            className="cursor-pointer"
                          >
                            <p>Upload Pictures</p>
                          </label>
                        </div>
                        <div className="mt-4">
                          {files.length > 0 && (
                            <ul>
                              {files.map((file, index) => (
                                <div
                                  className="border p-2 border-blue-500 inline-block m-2 relative"
                                  key={index}
                                >
                                  <div
                                    className="w-6 h-6 bg-red-600 rounded-full absolute -right-2 -top-3 flex items-center justify-center cursor-pointer"
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <RxCross2 color="white" />
                                  </div>
                                  <li>{file.name}</li>
                                </div>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="mt-6">
                        <div className="flex flex-row space-x-4 justify-end">
                          <Dialog>
                            <DialogTrigger asChild>
                              <button className="text-[#0074FD] font-bold text-sm py-2 px-4 rounded flex items-center justify-between">
                                Cancel
                              </button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-md">
                              <DialogHeader>
                                <DialogTitle>Cancel Form</DialogTitle>
                                <DialogDescription>
                                  Are you sure you want to cancel the form? Any
                                  unsaved changes will be lost.
                                </DialogDescription>
                              </DialogHeader>
                              <DialogFooter className="sm:justify-end">
                                <button
                                  onClick={handleConfirmCancel}
                                  className="bg-red-600 text-white rounded-md px-4 py-2"
                                >
                                  Yes
                                </button>
                                <DialogTrigger asChild>
                                  <button className="bg-blue-600 px-4 py-2 text-white rounded-md">
                                    Continue Editing
                                  </button>
                                </DialogTrigger>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                          <button
                            type="submit"
                            className="text-[#F15A24] py-2 px-4 text-sm font-bold"
                          >
                            <span>{isPending ? "Loading" : "Submit"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AntoilerCreateAccount;
