import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash";

interface DropdownProps<T> {
  apiEndpoint: string;
  isMultiSelect?: boolean;
  itemToString: (item: T) => string;
  onSelect: (selectedItems: T[]) => void;
  pageSize?: number;
  placeholder?: string;
  enableSearch?: boolean;
  search_var?: string;
  classNames?: string;
}

interface DropdownState<T> {
  options: T[];
  loading: boolean;
  error: string | null;
  hasMore: boolean;
  page: number;
  searchTerm: string;
  selectedItems: T[];
  isOpen: boolean;
  initialFetchDone: boolean;
}

const AdvancedDropdown = <T,>({
  apiEndpoint,
  isMultiSelect = false,
  itemToString,
  onSelect,
  pageSize = 10,
  placeholder = "Select an option",
  enableSearch = true,
  search_var = "search_term",
  classNames = "",
}: DropdownProps<T>) => {
  const [state, setState] = useState<DropdownState<T>>({
    options: [],
    loading: false,
    error: null,
    hasMore: true,
    page: 1,
    searchTerm: "",
    selectedItems: [],
    isOpen: false,
    initialFetchDone: false,
  });

  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  const fetchOptions = useCallback(
    async (search: string, page: number) => {
      if (!apiEndpoint) {
        console.error("Invalid Endpoint:", apiEndpoint);
        return;
      }

      setState((prev) => ({ ...prev, loading: true, error: null }));
      try {
        const response = await fetch(
          `${apiEndpoint}?${search_var}=${encodeURIComponent(
            search
          )}&page=${page}&limit=${pageSize}`
        );
        if (!response.ok) throw new Error("Failed to fetch options");
        const data = await response.json();
        const newOptions = data.data || [];
        setState((prev) => ({
          ...prev,
          options: page === 1 ? newOptions : [...prev.options, ...newOptions],
          loading: false,
          hasMore: newOptions.length === pageSize,
          page: page,
          initialFetchDone: true,
        }));
      } catch (error) {
        setState((prev) => ({
          ...prev,
          loading: false,
          error: (error as Error).message,
        }));
      }
    },
    [apiEndpoint, pageSize]
  );

  const debouncedFetch = useCallback(
    debounce((search: string) => {
      setState((prev) => ({ ...prev, options: [], page: 1, hasMore: true }));
      fetchOptions(search, 1);
    }, 300),
    [fetchOptions]
  );

  useEffect(() => {
    if (state.isOpen && !state.initialFetchDone && !state.loading) {
      fetchOptions(state.searchTerm, 1);
    }
  }, [
    state.isOpen,
    state.initialFetchDone,
    state.loading,
    fetchOptions,
    state.searchTerm,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (optionsRef.current && state.hasMore && !state.loading) {
        const { scrollTop, scrollHeight, clientHeight } = optionsRef.current;
        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          fetchOptions(state.searchTerm, state.page + 1);
        }
      }
    };

    optionsRef.current?.addEventListener("scroll", handleScroll);
    return () =>
      optionsRef.current?.removeEventListener("scroll", handleScroll);
  }, [
    state.hasMore,
    state.loading,
    fetchOptions,
    state.searchTerm,
    state.page,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setState((prev) => ({ ...prev, isOpen: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (item: T) => {
    setState((prev) => {
      const newSelectedItems = isMultiSelect
        ? prev.selectedItems.some((i) => itemToString(i) === itemToString(item))
          ? prev.selectedItems.filter(
              (i) => itemToString(i) !== itemToString(item)
            )
          : [...prev.selectedItems, item]
        : [item];

      onSelect(newSelectedItems);
      return {
        ...prev,
        selectedItems: newSelectedItems,
        isOpen: isMultiSelect,
        searchTerm: "",
      };
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setState((prev) => ({ ...prev, searchTerm, page: 1, options: [] }));
    debouncedFetch(searchTerm);
  };

  const toggleDropdown = () => {
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className={`mt-1 block w-60 rounded-md bg-[#F4F4F4] py-2 px-3 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold ${classNames}`}
        onClick={toggleDropdown}
      >
        {state.selectedItems.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {state.selectedItems.map((item, index) => (
              <span
                key={index}
                className="px-2 py-1  text-black rounded-full text-sm"
              >
                {itemToString(item)}
              </span>
            ))}
          </div>
        ) : (
          <span className="text-gray-400">{placeholder}</span>
        )}
      </div>
      {state.isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={state.searchTerm}
                onChange={handleSearch}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          )}
          <div ref={optionsRef} className="max-h-60 overflow-auto">
            {state.options.map((item, index) => (
              <div
                key={`${itemToString(item)}-${index}`}
                className={`p-2 cursor-pointer hover:bg-gray-100 ${
                  state.selectedItems.some(
                    (i) => itemToString(i) === itemToString(item)
                  )
                    ? "bg-blue-50"
                    : ""
                }`}
                onClick={() => handleSelect(item)}
              >
                {itemToString(item)}
              </div>
            ))}
            {state.loading && (
              <div className="p-2 text-center text-gray-500">Loading...</div>
            )}
            {state.error && (
              <div className="p-2 text-center text-red-500">{state.error}</div>
            )}
            {!state.loading && state.options.length === 0 && (
              <div className="p-2 text-center text-gray-500">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedDropdown;
// add
