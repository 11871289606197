import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

// Create the slice
const antoilerSlice = createSlice({
  name: "antoiler",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setData } = antoilerSlice.actions;

export default antoilerSlice.reducer;
