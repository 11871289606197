import { format } from "date-fns";
import Loader from "../../../common/Loader/Loader";
import { useState } from "react";
import AnswerModal from "../../../common/AnswerModal/AnswerModal"; // Adjust the import path as necessary
import { Eye } from "lucide-react";

const AnswerTable = ({
  data,
  isLoading,
  refetch,
}: {
  data: any[];
  isLoading: boolean;
  refetch: any;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [answer, setAnswer] = useState(null);

  const openModal = (report: any) => {
    console.log("open modal", report);
    setAnswer(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setAnswer(null);
    setIsModalOpen(false);
  };

  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a") // Formatting date
      : "N/A";
  };

  return (
    <>
      <div className="overflow-x-auto rounded-md shadow-md mt-6">
        {/* Show loader if loading, otherwise show table */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader />
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Call By Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Call To Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Mobile
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.length > 0 ? (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td
                      className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500 "
                      // Open modal with row data
                    >
                      {row.call_by_name || "N/A"}
                    </td>
                    <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                      {row.call_to_name || "N/A"}
                    </td>
                    <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                      {row.call_to_mobile || "N/A"}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(row.Noanswer_datetime)}
                    </td>

                    <td
                      className="px-6 py-4 whitespace-nowrap text-sm text-black flex gap-3 items-center cursor-pointer hover:text-red-600"
                      onClick={() => openModal(row)}
                    >
                      View
                      <Eye size={20} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No support available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Support Ticket Modal */}
      <AnswerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        answer={answer}
        refetch={refetch}
      />
    </>
  );
};

export default AnswerTable;
