import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrumbs: [],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs(state: any, action: any) {
      state.breadcrumbs = [];
      const { title, url } = action.payload;
      const newBreadcrumb = { title, url };
      state.breadcrumbs = [newBreadcrumb];
    },
    addBreadcrumb(state: any, action: any) {
      const { title, url } = action.payload;
      const newBreadcrumb = { title, url };

      const exists = state.breadcrumbs.some(
        (breadcrumb: any) =>
          breadcrumb.url === url || breadcrumb.title === title
      );

      if (!exists) {
        state.breadcrumbs = [...state.breadcrumbs, newBreadcrumb];
      }
    },
    handleBreadcumsClick(state: any, action: any) {
      state.breadcrumbs = action.payload;
    },
    removeBreadcrumb(state, action: any) {
      state.breadcrumbs = state.breadcrumbs.filter(
        (_: any, index: any) => index !== action.payload
      );
    },
    clearBreadcrumbs(state) {
      state.breadcrumbs = [];
    },
  },
});

export const {
  setBreadcrumbs,
  addBreadcrumb,
  removeBreadcrumb,
  clearBreadcrumbs,
  handleBreadcumsClick,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
