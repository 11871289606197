import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronRight, MoreVertical, User } from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { BASE_IMAGE, postData } from "../../../services/apiService";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import EditParentCategoryModal from "./EditParentCategoryModal";
import EditChildCategoryModal from "./EditChildCategoryModal";

interface Category {
  cat_id: number;
  parent_id: number;
  cat_title: string;
  cat_status: number;
  cat_img: string | null;
  cat_date_time: string;
  child_count: number;
  user_count: number;
  childCategories?: Category[];
}

interface ExpandableTableProps {
  getParentCategoriesWithChildCategory: { data: Category[] };
  searchTerm: string;
  refetch: () => void;
}

export default function ExpandableTable({
  getParentCategoriesWithChildCategory,
  searchTerm,
  refetch,
}: ExpandableTableProps) {
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [categories, setCategories] = useState<Category[]>(
    getParentCategoriesWithChildCategory?.data
  );
  useEffect(() => {
    setCategories(getParentCategoriesWithChildCategory?.data || []);
  }, [getParentCategoriesWithChildCategory?.data]);
  const [pendingCategoryId, setPendingCategoryId] = useState<number | null>(
    null
  );

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [isEditChildModalOpen, setEditChildModalOpen] = useState(false);
  const [selectedChildCategory, setSelectedChildCategory] =
    useState<Category | null>(null);

  const toggleRow = (id: number) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const { mutate } = useMutation({
    mutationFn: (id: number) => {
      setPendingCategoryId(id);
      return postData(`category/change-status-by-child-category/${id}`);
    },
    onSuccess: () => {
      refetch();
      toast.success("Category status changed successfully");
      setPendingCategoryId(null);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
      setPendingCategoryId(null);
    },
  });

  const handleSwitchChange = (checked: boolean, category: Category) => {
    mutate(category.cat_id);
    refetch();
  };

  const renderCategory = (category: Category, level: number = 0) => {
    const isExpanded = expandedRows.has(category.cat_id);
    const hasChildren =
      category.childCategories && category.childCategories.length > 0;

    return (
      <React.Fragment key={category.cat_id}>
        <tr
          className={`border-b transition-colors hover:bg-gray-100 ${
            level > 0 ? "bg-gray-50" : ""
          }`}
        >
          <td
            className="p-2 pl-4"
            style={{ paddingLeft: `${level * 20 + 16}px` }}
          >
            <div className="flex items-center">
              {category.cat_img ? (
                <img
                  src={`${BASE_IMAGE}${category.cat_img}`}
                  alt={`${category?.cat_title} icon`}
                  className="w-10 h-10 rounded-full mr-3"
                  style={{ objectFit: "contain" }} // Ensures SVG scales within bounds
                />
              ) : (
                <div className="w-10 h-10 bg-gray-200 rounded-full mr-3" />
              )}

              <button
                className="flex items-center text-left"
                onClick={() => hasChildren && toggleRow(category.cat_id)}
              >
                {hasChildren && (
                  <span className="mr-2">
                    {isExpanded ? (
                      <ChevronDown className="h-4 w-4" />
                    ) : (
                      <ChevronRight className="h-4 w-4" />
                    )}
                  </span>
                )}
                {category.cat_title}
              </button>
            </div>
          </td>
          <td className="p-2">
            {level === 0 ? (
              <span
                className={`px-2 py-1 rounded-full text-xs ${
                  category.cat_status === 1
                    ? "bg-green-200 text-green-800"
                    : category.cat_status === 2
                    ? "bg-yellow-200 text-yellow-800"
                    : "bg-red-200 text-red-800"
                }`}
              >
                {category.cat_status === 1
                  ? "Published"
                  : category.cat_status === 2
                  ? "Pending"
                  : "Deactivated"}
              </span>
            ) : (
              <ReactSwitch
                checked={category.cat_status === 1}
                onChange={(checked) => handleSwitchChange(checked, category)}
                height={28}
                width={56}
                onColor="#EF4444"
                offColor="#D1D5DB"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            )}
          </td>
          {level === 0 ? (
            <td className="p-2 text-center">{category.child_count}</td> // Displaying child count
          ) : (
            <td className="p-2 text-center">
              <User className="inline mr-1" /> {/* User icon */}
              {category?.user_count}
            </td>
          )}
          <td className="p-2">
            {new Date(category.cat_date_time).toLocaleDateString()}
          </td>
          <td className="p-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {category.parent_id === 0 ? (
                  <DropdownMenuItem
                    onClick={() => {
                      setSelectedCategory(category); // Set the selected category
                      setEditModalOpen(true); // Open the modal
                    }}
                  >
                    Edit Parent Category
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    onClick={() => {
                      setSelectedChildCategory(category); // Set the selected category
                      setEditChildModalOpen(true); // Open the modal
                    }}
                  >
                    Edit Child Category
                  </DropdownMenuItem>
                )}

                <DropdownMenuItem
                  onClick={() => handleDeleteCategory(category.cat_id)}
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </td>
        </tr>
        {isExpanded &&
          category.childCategories?.map((childCategory) =>
            renderCategory(childCategory, level + 1)
          )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    // Reset expanded rows on search term change
    setExpandedRows(new Set());

    if (searchTerm.length === 3) {
      const newExpandedRows = new Set<number>();
      categories.forEach((category) => {
        if (
          category?.cat_title.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          newExpandedRows.add(category.cat_id);
        }
        category.childCategories?.forEach((child) => {
          if (
            child.cat_title.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            newExpandedRows.add(category.cat_id);
          }
        });
      });
      setExpandedRows(newExpandedRows);
    }
  }, [searchTerm, categories]);

  const { mutate: deleteChildCategory } = useMutation({
    mutationFn: (id: number) => postData(`category/delete-category/${id}`),
    onSuccess: () => {
      refetch();
      toast.success("Category deleted successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message?.message);
    },
  });

  const handleDeleteCategory = (categoryId: number) => {
    if (categoryId) {
      deleteChildCategory(categoryId);
    } else {
      console.error("Category ID is missing");
    }
  };

  return (
    <div className="p-2 ">
      <div className="rounded-lg border border-gray-200 shadow-md ">
        <table className="min-w-full divide-y divide-gray-200 h-auto">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Child Count
              </th>
              <th
                scope="col"
                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Created At
              </th>
              <th
                scope="col"
                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {categories.map((category) => renderCategory(category))}
          </tbody>
        </table>
      </div>
      {selectedCategory && (
        <EditParentCategoryModal
          isOpen={isEditModalOpen}
          close={() => setEditModalOpen(false)}
          category={selectedCategory}
          refetch={refetch} // Pass refetch function to refresh data after editing
        />
      )}

      {selectedChildCategory && (
        <EditChildCategoryModal
          isOpen={isEditChildModalOpen}
          close={() => setEditChildModalOpen(false)}
          category={selectedChildCategory}
          refetch={refetch}
        />
      )}
    </div>
  );
}
