import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ChevronDownIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";

import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";

const AdvancedFilter = ({ allcustomers, onApplyFilters, clearFilter }: any) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLocality, setSelectedLocality] = useState<any>([]);
  const [clearFilterShow, setClearFilterShow] = useState(false);

  const localValues = localStorage.getItem("customerFilters");

  useEffect(() => {
    const LV = localValues ? JSON.parse(localValues) : null;

    if (LV && LV.selectedLocality && LV.selectedLocality.length > 0) {
      setSelectedLocality(LV.selectedLocality);
      setClearFilterShow(true);
    } else {
      setSelectedLocality([]);
      setClearFilterShow(false);
    }
  }, [localValues]);

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleApplyFilter = () => {
    if (selectedLocality.length > 0) {
      setClearFilterShow(true); // Set true if any value is selected
    }
    onApplyFilters(selectedLocality);
    localStorage.setItem(
      "customerFilters",
      JSON.stringify({ selectedLocality })
    ); // Save the filters in local storage
    handleModalToggle();
  };

  const { data: getAllLocation } = useQuery({
    queryKey: ["getAllLocation"],
    queryFn: () => getData("user/get-all-locations"),
  });

  const locationOptions =
    getAllLocation?.data?.map((location: any) => ({
      label: location,
      value: location,
    })) || [];

  const handleClearFilter = () => {
    setSelectedLocality([]); // Clear the selected filters state
    setClearFilterShow(false); // Immediately hide the clear button
    localStorage.removeItem("customerFilters"); // Clear filters from local storage
    clearFilter(); // Call the clear filter handler passed as a prop
  };

  return (
    <>
      <div className="mt-10 flex justify-between items-center">
        <div>
          <p className="font-semibold text-gray-400 text-sm">Total</p>
          <p className="text-md font-bold">{allcustomers}</p>
        </div>
        <div className="flex gap-2 items-center">
          {clearFilterShow && ( // Show clear filter button if there are any active filters
            <p
              className="text-blue-600 font-semibold cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filter
            </p>
          )}

          <Button
            variant="ghost"
            className="text-orange-500 font-semibold"
            onClick={handleModalToggle}
          >
            Advanced Filter
            <ChevronDownIcon size={20} className="ml-2" />
          </Button>
        </div>
      </div>
      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center mt-4">
            <p className="font-semibold">Advanced Filter</p>
            <p
              onClick={handleApplyFilter}
              className="ml-auto cursor-pointer text-[#0085FF]"
            >
              Apply Filter
            </p>
          </div>

          {/* Multi-select with search for Locality */}
          <div className="mt-4">
            <Select
              isMulti
              options={locationOptions}
              value={selectedLocality}
              onChange={setSelectedLocality}
              placeholder="Filter By Locality"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
              styles={{
                multiValueLabel: (base) => ({
                  ...base,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  maxWidth: "200px",
                  whiteSpace: "normal",
                }),
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdvancedFilter;
