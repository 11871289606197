import { useState } from "react";
import { ChevronRight, IndianRupee } from "lucide-react";

const Accordion = ({ data }: any) => {
  const [openIndex, setOpenIndex] = useState();

  const handleToggle = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (!data?.data?.length) {
    return (
      <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-gray-50 rounded-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#F24E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
          <line x1="8" y1="21" x2="16" y2="21"></line>
          <line x1="12" y1="17" x2="12" y2="21"></line>
        </svg>
        <p className="text-lg font-semibold text-gray-700">No Rate Card yet</p>
        <p className="text-sm text-gray-500">
          Start your first task to see it here!
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 p-4 rounded-md">
      {data?.data?.map((item: any, index: number) => (
        <div
          key={index}
          className="border border-gray-200 rounded-md overflow-hidden bg-[#F4F4F4]"
        >
          <div className="flex items-center justify-between w-full p-4">
            <button
              className="flex items-center text-left flex-grow"
              onClick={() => handleToggle(index)}
            >
              <ChevronRight
                className={`w-5 h-5 mr-3 transition-transform duration-200 ${
                  openIndex === index ? "transform rotate-90" : ""
                }`}
              />
              <span className="flex-grow mr-2 truncate text-gray-900 font-semibold">
                {item.title}
              </span>
            </button>
            <div className="flex items-center justify-center text-[#F24E1E]">
              <IndianRupee size={15} /> {item.rate} /
              <span className="text-[#F24E1E] font-semibold whitespace-nowrap">
                <span className="text-black ml-2"> {item.type}</span>
              </span>
            </div>
          </div>
          {openIndex === index && (
            <div className="p-4 bg-white">
              <p className="text-[#7A7A7A]">{item.description}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
