import { format } from "date-fns";
import { Dialog, DialogContent, DialogTitle } from "../../components/ui/dialog"; // Adjust the import path as necessary

import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs"; // Adjust the import path

const AnswerModal = ({ isOpen, onClose, answer }: any) => {
  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a") // Formatting date
      : "N/A";
  };

  const handleModalClose = () => {
    onClose(); // Call the original onClose
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleModalClose}>
      <DialogContent>
        <DialogTitle>No Answer</DialogTitle>
        {answer ? (
          <Tabs defaultValue="supportDetails" className="w-full ">
            <TabsList>
              <TabsTrigger value="supportDetails">
                No Answer Details
              </TabsTrigger>
            </TabsList>

            {/* Support Details Tab */}
            <TabsContent value="supportDetails">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Call by Name
                  </label>
                  <input
                    type="text"
                    value={answer.call_by_name || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Call To Name:
                  </label>
                  <input
                    type="text"
                    value={answer.call_to_name || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Mobile
                  </label>
                  <input
                    type="text"
                    value={answer.call_to_mobile || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Date:
                  </label>
                  <input
                    type="text"
                    value={formatDate(answer.Noanswer_datetime) || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
              </div>
            </TabsContent>
          </Tabs>
        ) : (
          <p>No answer available.</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AnswerModal;
