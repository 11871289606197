import React, { useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import RatingStars from "../../../common/Rating/Rating";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import moment from "moment";
import { BASE_IMAGE, postData } from "../../../services/apiService";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import Loader from "../../../common/Loader/Loader";

interface Sender {
  profile_pic: string;
  first_name: string;
}

interface ReviewItem {
  review_id: number;
  sender: Sender;
  overall_review_rate: number;
  review_status: number;
  rev_date_time: string;
  review_txt: string;
  rejection_reason?: string;
  is_edited?: any;
}

interface MediaItem {
  normal_img: string;
}

interface UserReviewCardProps {
  antoilerReview: { data: ReviewItem[] };
  handleGetReviewID: (reviewId: number) => void;
  media: MediaItem[];
  refetch: () => void;
  MediaLoading: any;
}

const formatDateTime = (dateTime: string): string =>
  moment(dateTime).format("MMM D, YYYY, h:mm A");

const UserReviewCard: React.FC<UserReviewCardProps> = ({
  antoilerReview,
  handleGetReviewID,
  media,
  MediaLoading,
  refetch,
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [currentReviewId, setCurrentReviewId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [viewRejectionModal, setViewRejectionModal] = useState<boolean>(false);
  const [editRejectionModal, setEditRejectionModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (data: any) =>
      postData(`review/update-review-status/${data.reviewId}`, data),
    onSuccess: () => {
      refetch();
      toast.success("Review updated successfully");
    },
  });

  const { mutate: updateReasonMutate } = useMutation({
    mutationFn: (data: any) =>
      postData(`review/update-reason-text/${data.reviewId}`, {
        rejection_reason: data.rejectionReason,
      }),
    onSuccess: () => {
      refetch();
      toast.success("Rejection reason updated successfully");
      setEditRejectionModal(false);
    },
  });

  const handleViewPhotosClick = (reviewId: number) => {
    setExpandedIndex(expandedIndex === reviewId ? null : reviewId);
    handleGetReviewID(reviewId);
  };

  const handleActionClick = (
    reviewId: number,
    status: number,
    reason?: string
  ) => {
    mutate({ reviewId, review_status: status, rejection_reason: reason });
    closeAllModals();
  };

  const handleDenyFeedbackClick = (reviewId: number) => {
    setCurrentReviewId(reviewId);
    setIsModalOpen(true);
  };

  const handleViewReasonClick = (reason: string) => {
    setRejectionReason(reason);
    setViewRejectionModal(true);
  };

  const closeAllModals = () => {
    setIsModalOpen(false);
    setViewRejectionModal(false);
    setEditRejectionModal(false);
  };

  const handleSaveEditedReason = () => {
    console.log("saveEditedReason", currentReviewId);
    if (currentReviewId !== null) {
      updateReasonMutate({ reviewId: currentReviewId, rejectionReason });
    }
  };

  const BreadCrumbObject: any = {
    title: "Edit Feedback",
    url: "edit-feedback",
  };

  const handleNavigate = (item: ReviewItem) => {
    const {
      review_id,
      review_txt,
      sender: { first_name },
    } = item;
    dispatch(addBreadcrumb(BreadCrumbObject));
    navigate(
      `/edit-feedback?review_id=${review_id}&review_txt=${review_txt}&first_name=${first_name}`
    );
  };

  const handleCloseViewRejectionModal = () => {
    setViewRejectionModal(false);
    setRejectionReason("");
  };

  return (
    <>
      {antoilerReview?.data?.length > 0 ? (
        antoilerReview.data.map((item) => (
          <div
            className="p-4 bg-white shadow-sm border border-gray-200 my-2"
            key={item.review_id}
          >
            {/* Review content */}
            <div className="grid grid-cols-12">
              {/* User profile picture */}
              <div className="col-span-1">
                <img
                  src={`${BASE_IMAGE}${item.sender?.profile_pic}`}
                  alt=""
                  className="rounded-full w-12 h-12"
                />
              </div>
              {/* Review details */}
              <div className="col-span-10">
                <div className="flex items-center gap-2">
                  <RatingStars
                    count={5}
                    value={item?.overall_review_rate}
                    size={21}
                    color2={"#F24E1E"}
                    edit={false}
                  />
                  <p className="font-bold text-sm">
                    {item?.sender?.first_name}
                  </p>

                  <p
                    style={{
                      color:
                        item?.review_status === 0
                          ? "#FF0000" // Pending
                          : item?.review_status === 1
                          ? "#34C759" // Published
                          : item?.review_status === 2 && item?.is_edited
                          ? "#FF59EE" // Edited
                          : item?.review_status === 3
                          ? "#FF9874" // Denied
                          : "black", // Default
                    }}
                    className="text-xs font-medium"
                  >
                    {item?.review_status === 0
                      ? "Feedback Pending"
                      : item?.review_status === 1
                      ? "Feedback Published"
                      : item?.review_status === 3
                      ? "Feedback Denied"
                      : ""}
                    {item.review_status === 2 && !item.is_edited && (
                      <span
                        className="text-[#0085FF] cursor-pointer ml-1"
                        onClick={() =>
                          handleViewReasonClick(item.rejection_reason || "")
                        }
                      >
                        -View Reason
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-xs">
                    {formatDateTime(item.rev_date_time)}{" "}
                    {item?.is_edited ? "Edited" : null}
                  </p>
                  <p className="text-gray-600 text-xs mt-4">
                    {item?.review_txt}
                  </p>
                </div>
                {/* View photos toggle */}
                <div
                  className="text-indigo-600 flex items-center gap-2 mt-4 cursor-pointer"
                  onClick={() => handleViewPhotosClick(item?.review_id)}
                >
                  <p className="font-medium">View Photos</p>
                  <ChevronRight size={15} className="font-medium" />
                </div>
                {/* Expanded photos view */}
                {expandedIndex === item?.review_id && (
                  <div className="mt-4 p-4 bg-white rounded-md flex items-center justify-start gap-4">
                    {media && media.length > 0 ? (
                      media.map((mediaItem: any, i) =>
                        mediaItem?.type === "img" ? (
                          <>
                            <img
                              key={i}
                              src={`${BASE_IMAGE}${mediaItem?.normal_img}`}
                              alt={`Media ${i}`}
                              className="w-20 h-20 object-cover rounded-md"
                            />
                            {/* Moved inside fragment */}
                          </>
                        ) : (
                          <video
                            key={i}
                            controls
                            className="w-20 h-20 object-cover rounded-md"
                          >
                            <source
                              src={`${BASE_IMAGE}${mediaItem?.normal_img}`}
                            />
                            Your browser does not support the video tag.
                          </video>
                        )
                      )
                    ) : (
                      <p>No media available</p>
                    )}
                  </div>
                )}
              </div>

              {/* Action dropdown */}
              <div className="col-span-1 cursor-pointer">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div className="flex items-center gap-2">
                      <p className="text-[#F24E1E] font-semibold">Action</p>
                      <ChevronDown className="text-[#F24E1E]" />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => handleNavigate(item)}>
                      Edit Feedback
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleDenyFeedbackClick(item.review_id)}
                    >
                      Deny Feedback
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleActionClick(item.review_id, 1)}
                    >
                      Published Feedback
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleActionClick(item.review_id, 0)}
                    >
                      Pending Feedback
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500 mt-6">There is no feedback.</p>
      )}

      {/* Modal for Deny Feedback */}
      <Dialog
        open={isModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            closeAllModals();
          }
          setIsModalOpen(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Submit Reason to Deny</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <textarea
              placeholder="Enter reason for denial here..."
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              rows={4}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-none"
            />
          </DialogDescription>
          <div className="flex justify-start items-center mt-4 gap-2">
            <button
              className="bg-[#F24E1E] text-white rounded-md px-4 py-2"
              onClick={() => {
                handleActionClick(
                  currentReviewId as number,
                  3,
                  rejectionReason
                );
              }}
            >
              Submit
            </button>
            <button
              className="bg-gray-400 text-white rounded-md px-4 py-2"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Modal for View Rejection Reason */}
      <Dialog
        open={viewRejectionModal}
        onOpenChange={handleCloseViewRejectionModal}
      >
        <DialogContent>
          <div className="flex justify-between mt-4">
            <p className="text-lg font-semibold">Rejection Reason</p>
            <p
              className="text-red-500 cursor-pointer"
              onClick={() => {
                closeAllModals();
                setEditRejectionModal(true);
              }}
            >
              Edit Reason
            </p>
          </div>
          <DialogDescription>{rejectionReason}</DialogDescription>
          <div className="flex justify-start items-center mt-4 gap-2">
            <button
              className="bg-gray-400 text-white rounded-md px-4 py-2"
              onClick={handleCloseViewRejectionModal}
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Modal for Edit Rejection Reason */}
      <Dialog
        open={editRejectionModal}
        onOpenChange={(open) => {
          if (!open) {
            setRejectionReason("");
          }
          setEditRejectionModal(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Rejection Reason</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <textarea
              placeholder="Enter updated reason here..."
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              rows={4}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-none"
            />
          </DialogDescription>
          <div className="flex justify-start items-center mt-4 gap-2">
            <button
              className="bg-[#F24E1E] text-white rounded-md px-4 py-2"
              onClick={handleSaveEditedReason}
            >
              Save
            </button>
            <button
              className="bg-gray-400 text-white rounded-md px-4 py-2"
              onClick={() => setEditRejectionModal(false)}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserReviewCard;
