import { useEffect, useState } from "react";
import Search from "../components/Search";
import AdvancedFilter from "../components/AdvanceFilter";
import AllCustomerTable from "../components/AllCustomerTable";
import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import Pagination from "../../../common/components/Pagination";
import Loader from "../../../common/Loader/Loader";

const AllCustomer = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedLocality, setSelectedLocality] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  // Load filter values from localStorage on mount
  useEffect(() => {
    const savedFilters = localStorage.getItem("customerFilters");
    if (savedFilters) {
      const { searchTerm, selectedLocality, currentPage } =
        JSON.parse(savedFilters);
      setSearchTerm(searchTerm || "");
      setSelectedLocality(selectedLocality || []);
      setCurrentPage(currentPage || 1);
    }
  }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    const filters = {
      selectedLocality,
      currentPage,
    };
    localStorage.setItem("customerFilters", JSON.stringify(filters));
  }, [selectedLocality, currentPage]);

  const {
    data: getAllCustomers,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getAllCustomers", searchTerm, currentPage, selectedLocality],
    queryFn: () =>
      getData(
        `user/get-all-users-by-filters?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}&location_area=${selectedLocality
          .map((loc) => loc?.value)
          .join(",")}`
      ),
  });

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleApplyFilters = (locality: any[]) => {
    setSelectedLocality(locality);
    setCurrentPage(1);
    refetch();
  };

  const clearFilter = () => {
    setSelectedLocality([]);
    setSearchTerm("");
    setCurrentPage(1);
    localStorage.removeItem("customerFilters"); // Clear from localStorage
    refetch();
  };

  // Clear individual filters (and update localStorage)
  const clearIndividualFilter = (filterType: string) => {
    if (filterType === "locality") {
      setSelectedLocality([]);
    }
    setCurrentPage(1);
    refetch();
  };

  const renderSelectedFilters = () => {
    const filters = [];

    if (selectedLocality.length > 0) {
      filters.push(
        <div
          key="locality"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Locality: ${selectedLocality
            .map((loc) => loc.label)
            .join(", ")}`}</span>
          <button
            onClick={() => clearIndividualFilter("locality")}
            className="ml-2 text-red-500"
          >
            ✕
          </button>
        </div>
      );
    }

    return filters;
  };

  return (
    <div className="m-4">
      <div className="mt-4">
        <p className="font-semibold">All Users</p>
      </div>
      <Search onChange={handleSearch} />

      {/* Display Selected Filters */}
      <div className="flex gap-2 mt-4 flex-wrap">{renderSelectedFilters()}</div>

      <AdvancedFilter
        allcustomers={getAllCustomers?.data?.totalResults}
        onApplyFilters={handleApplyFilters}
        clearFilter={clearFilter}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AllCustomerTable
            getAllCustomers={getAllCustomers}
            onApplyFilters={handleApplyFilters}
            clearFilter={clearFilter}
          />
          {getAllCustomers?.data && (
            <Pagination
              totalItems={Number(getAllCustomers?.data?.totalResults || 0)}
              itemsPerPage={Number(getAllCustomers?.data?.limit)}
              currentPage={Number(getAllCustomers?.data?.page)}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AllCustomer;
