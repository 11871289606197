import { format } from "date-fns";
import { Dialog, DialogContent, DialogTitle } from "../../components/ui/dialog"; // Adjust the import path as necessary

// Import Shadcn Tabs components
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs"; // Adjust the import path

const ReportModal = ({ isOpen, onClose, report }: any) => {
  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a") // Formatting date
      : "N/A";
  };

  const handleModalClose = () => {
    onClose(); // Call the original onClose
  };

  console.log({ report });

  return (
    <Dialog open={isOpen} onOpenChange={handleModalClose}>
      <DialogContent>
        <DialogTitle>Report</DialogTitle>
        {report ? (
          <Tabs defaultValue="supportDetails" className="w-full ">
            <TabsList>
              <TabsTrigger value="supportDetails">Report Details</TabsTrigger>
            </TabsList>

            {/* Support Details Tab */}
            <TabsContent value="supportDetails">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Report:
                  </label>
                  <input
                    type="text"
                    value={report.report_msg || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Reported By:
                  </label>
                  <input
                    type="text"
                    value={report.report_by_name || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Reported To:
                  </label>
                  <input
                    type="text"
                    value={report.report_to_name || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Date:
                  </label>
                  <input
                    type="text"
                    value={formatDate(report.report_datetime) || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
                  />
                </div>
              </div>
            </TabsContent>
          </Tabs>
        ) : (
          <p>No ticket details available.</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
