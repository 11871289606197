import { useQuery } from "@tanstack/react-query";
import AllReviews from "../components/AllReviews";
import PersonalInformation from "../components/PersonalInformation";
import ProfileData from "../components/ProfileData";
import { getData } from "../../../services/apiService";
import { setData } from "../slice/AntoilerReviewSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import WorkDone from "../components/WorkDone";
import RateCard from "../components/RateCard";
import Activitylogs from "../components/Activitylogs";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";

const AntoilerReview = () => {
  const [searchParams] = useSearchParams();
  const antoilerId: any = searchParams.get("id");

  const [activeTab, setActiveTab] = useState("reviews");
  const dispatch = useDispatch();

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  const {
    data,
    refetch: refetchFn,
    isPending,
  } = useQuery({
    queryKey: ["getUserById"],
    queryFn: () => getData(`user/get-user-by-id/${antoilerId}`),
  });

  useEffect(() => {
    if (data) {
      dispatch(setData(data?.data));
    }
  }, [data]);

  return (
    <div className="grid grid-cols-12 ">
      {isPending ? (
        <div className="col-span-12  flex justify-center h-screen items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-4 flex flex-col p-4">
            <ProfileData
              onTabChange={handleTabChange}
              activeTab={activeTab}
              refetch={refetchFn}
            />
          </div>

          <div className="col-span-8">
            <PersonalInformation refetch={refetchFn} />

            {activeTab === "reviews" && <AllReviews antoilerID={antoilerId} />}
            {activeTab === "workDone" && <WorkDone />}
            {activeTab === "rateCard" && <RateCard />}
            {activeTab === "activitylogs" && <Activitylogs />}
          </div>
        </>
      )}
    </div>
  );
};

export default AntoilerReview;
