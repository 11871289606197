import { useState, useEffect, useCallback } from "react";
import { Search as SearchIcon } from "lucide-react";
import debounce from "lodash/debounce";

const MobileSearch = ({ onChange }: any) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Wrap the onChange function with debounce
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 500),
    []
  );

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedOnChange(value);
  };

  // Clean up the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return (
    <div className="relative w-full mt-4">
      <input
        type="text"
        className="w-full px-4 py-2 pr-10 border rounded-md focus:outline-none text-[#C0C0C0] font-medium text-sm "
        placeholder="Search by Name or Profession"
        value={searchTerm}
        onChange={handleInputChange}
      />
      <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
        <SearchIcon size={20} />
      </div>
    </div>
  );
};

export default MobileSearch;
