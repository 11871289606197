import { Check, Dot } from "lucide-react";
import { SortIcon } from "../../../common/icons";
import { useEffect, useState } from "react";
import { BASE_IMAGE } from "../../../services/apiService";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import { useDispatch } from "react-redux";

const AntoilerTable = ({ getAllAntoilers }: any) => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [sortedData, setSortedData] = useState<any[]>([]); // Start with an empty array
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const BreadCrumbObject: any = {
    title: "Antoiler Profile",
    url: `/all-antoilers`,
  };

  useEffect(() => {
    // Whenever getAllAntoilers changes, update sortedData
    setSortedData(getAllAntoilers?.data?.data || []);
  }, [getAllAntoilers]);

  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(parseISO(dateString), "dd MMM yy • hh:mm a")
      : "";
  };

  const singleUserProfile = (singleId?: string, isNewTab?: boolean) => {
    if (singleId) {
      const path = `/antoiler-reviews?id=${singleId}`;

      if (isNewTab) {
        // Open in new tab
        window.open(path, "_blank");
      } else {
        // Navigate within the same tab
        dispatch(
          addBreadcrumb({
            ...BreadCrumbObject,
            url: path,
          })
        );
        navigate(path);
      }
    }
  };

  const handleSort = (field: string) => {
    const isAsc = orderBy === field && sortDirection === "asc";
    const newDirection = isAsc ? "desc" : "asc";

    const sorted = [...sortedData].sort((a, b) => {
      if (a[field] < b[field]) return newDirection === "asc" ? -1 : 1;
      if (a[field] > b[field]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortDirection(newDirection);
    setOrderBy(field);
  };

  return (
    <div className="overflow-x-auto rounded-md shadow-md mt-6">
      {sortedData.length > 0 ? ( // Check if there is data
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              {[
                { label: "Name", field: "user_first_name" },
                { label: "Location", field: "user_location_area" },
                { label: "Profession", field: "category_cat_title" },
                { label: "Profile Status", field: "user_profile_status" },
                { label: "Reg Date & Time", field: "user_reg_date_time" },
                { label: "Category Status", field: "category_cat_status" },
                { label: "Online Status", field: "user_online_status" },
              ].map((header) => (
                <th
                  key={header.field}
                  onClick={() => handleSort(header.field)}
                  className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider cursor-pointer relative"
                >
                  <div className="flex items-center">
                    {orderBy === header.field && (
                      <span
                        className={`mr-2 ${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-gray-400"
                        }`}
                      >
                        <SortIcon />
                      </span>
                    )}
                    <span>{header.label}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData.map((row: any, index: any) => (
              <tr key={index} className="">
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-4 cursor-pointer"
                  onClick={(e) => {
                    const isNewTab = e.ctrlKey || e.metaKey || e.shiftKey;
                    singleUserProfile(row?.user_user_id, isNewTab);
                  }}
                >
                  <img
                    src={`${BASE_IMAGE}${row?.user_profile_pic}`}
                    className="w-10 h-10 rounded-full"
                    alt=""
                  />
                  {row.user_first_name}
                </td>
                <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                  {row.user_location_area}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row?.category_cat_title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row?.user_profile_status === 0
                    ? "Pending"
                    : row?.user_profile_status === 1
                    ? "Verify"
                    : row?.user_profile_status === 2
                    ? "Published"
                    : row?.user_profile_status === 3
                    ? "Unpublished"
                    : row?.user_profile_status === 4
                    ? "Blocked"
                    : row?.user_profile_status === 5
                    ? "Deactivated"
                    : "Unknown"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(row?.user_reg_date_time)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    className={`${
                      row.category_cat_status === 0
                        ? "bg-red-200"
                        : row.category_cat_status === 1
                        ? "bg-green-200"
                        : "bg-yellow-200"
                    } py-1 rounded-md flex items-center justify-center flex-row gap-2`}
                  >
                    {row.category_cat_status === 0 ? (
                      <Dot size={30} color="#D27E00" className="w-6 h-6" />
                    ) : row.category_cat_status === 1 ? (
                      <Check size={16} color="#119C2B" className="w-6 h-6" />
                    ) : (
                      <Dot size={30} color="#FFA500" className="w-6 h-6" />
                    )}
                    <p
                      className={`${
                        row.category_cat_status === 0
                          ? "text-red-500"
                          : row.category_cat_status === 1
                          ? "text-[#119C2B]"
                          : "text-yellow-600"
                      } font-semibold`}
                    >
                      {row.category_cat_status === 0
                        ? "Deactivated"
                        : row.category_cat_status === 1
                        ? "Published"
                        : "Pending"}
                    </p>
                  </div>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    className={`${
                      row.user_online_status === 2
                        ? "bg-[#FFD89D]"
                        : "bg-green-200"
                    } py-1 rounded-md flex items-center justify-center flex-row gap-2`}
                  >
                    {row.user_online_status === 1 ? (
                      <Check size={16} color="#119C2B" className="w-6 h-6" />
                    ) : (
                      <Dot size={30} color="#D27E00" className="w-6 h-6" />
                    )}
                    <p
                      className={`${
                        row.user_online_status === 1
                          ? "text-[#119C2B]"
                          : "text-[#D27E00]"
                      } font-semibold`}
                    >
                      {row.user_online_status === 1
                        ? "Available"
                        : "Unavailable"}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="p-6 text-center text-gray-500 flex flex-col items-center justify-center">
          {/* SVG icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 mb-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35m1.69-5.78a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
            />
          </svg>
          <p>No Antoilers found</p>
        </div>
      )}
    </div>
  );
};

export default AntoilerTable;
