import { useQuery } from "@tanstack/react-query";
import UserReviewCard from "../../../common/components/UserReviewCard/UserReviewCard";
import Pagination from "../components/Pagination";
import { getData } from "../../../services/apiService";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../../common/Loader/Loader";
import AdvanceFilterReview from "../components/AdvanceFilterReview";
import { useSelector } from "react-redux";

const UserFeedback = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [reviewID, setReviewID] = useState<any>();
  const [reviewStatus, setReviewStatus] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const userID = searchParams.get("id");
  const itemsPerPage = 5;

  const user: any = useSelector((state: any) => state.AntoilerReview);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["feedback", userID, currentPage, reviewStatus],
    queryFn: () => {
      // Construct the query string conditionally
      let queryString = `review/get-received-reviews/${userID}?page=${currentPage}&limit=${itemsPerPage}`;

      // Append the review_status only if reviewStatus is not null or undefined
      if (reviewStatus !== null && reviewStatus !== undefined) {
        queryString += `&review_status=${reviewStatus}`;
      }

      return getData(queryString);
    },
    enabled: !!userID,
  });

  const { data: Media, isLoading: MediaLoading } = useQuery({
    queryKey: ["getMediabyReviewID", reviewID],
    queryFn: () => getData(`media/get-media-by-review-id/${reviewID}`),
  });

  const handleGetReviewID = (getMediabyReviewID: any) => {
    setReviewID(getMediabyReviewID);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSetReviewStatus = (reviewstatusno: any) => {
    setReviewStatus(reviewstatusno);
  };

  console.log({ data });

  return (
    <div className="m-4">
      <div className="flex items-start justify-between">
        <p className="font-bold text-md">{user?.data?.first_name}</p>
        <AdvanceFilterReview handleSetReviewStatus={handleSetReviewStatus} />
      </div>

      <div className="bg-white rounded-md p-8 mt-6 shadow-sm border border-gray-200">
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Loader />
          </div>
        ) : (
          <UserReviewCard
            refetch={refetch}
            antoilerReview={data?.data}
            media={Media?.data}
            handleGetReviewID={handleGetReviewID}
            MediaLoading
          />
        )}
      </div>

      <Pagination
        totalItems={parseInt(data?.data?.total) || 0}
        itemsPerPage={parseInt(data?.data?.limit) || 0}
        currentPage={parseInt(data?.data?.page) || 0}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default UserFeedback;
