import { configureStore } from "@reduxjs/toolkit";
import AntoilerReview from "./domains/AntoilerReview/slice/AntoilerReviewSlice";
import breadcrumbReducer from "./common/Slice/BreadcrumbsSlice";

export const store = configureStore({
  reducer: {
    AntoilerReview,
    breadcrumbs: breadcrumbReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
