import { useForm } from "react-hook-form";
import { useState } from "react";
import { X } from "lucide-react";
import CategorySelector from "../../../common/components/dropdowns/CategorySelector";
import { useMutation } from "@tanstack/react-query";
import { postData, postFileData } from "../../../services/apiService"; // Assuming you have a function to post file data
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications

const AddNewSlider = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [categoriesID, setCategoriesID] = useState<string | null>(null);

  const uploadImage = async () => {
    const formData = new FormData();
    if (uploadedImage) {
      formData.append("image", uploadedImage);
    }
    const response = await postFileData("/slider-image-upload", formData);
    return response.data; // Assume the server returns the uploaded image URL or ID
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: any) => {
      const imageUrl = await uploadImage();
      const finalData = {
        ...data,
        img: imageUrl?.outputPath,
        categories_id: categoriesID,
      };
      await postData("slider/create-new-slider", finalData);
    },
    onSuccess: () => {
      toast.success("Slider successfully created");
      setUploadedImage(null);
      reset();
    },
    onError: (error: any) => {
      toast.error("Failed to create slider");
      setUploadedImage(null);
      reset();
    },
  });

  const onSubmit = (data: any) => {
    if (!uploadedImage) {
      toast.error("Please upload an image");
      return;
    }
    if (!categoriesID) {
      toast.error("Please select a category");
      return;
    }
    mutate({ ...data });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length === 1) {
      setUploadedImage(files[0]);
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
  };

  const handleCategoryId = (cat_id: string) => {
    setCategoriesID(cat_id);
  };

  return (
    <>
      <p className="font-semibold text-md mb-4">Add New Slider</p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-4"
      >
        {/* Category Selector and Title Input */}
        <div className="space-x-4 flex justify-start items-center">
          <div className="">
            <label className="text-sm text-[#8E8E8E]">
              Select Slider Category
            </label>
            <CategorySelector handleCategoryId={handleCategoryId} />
          </div>

          <div className="w-full">
            <label htmlFor="title" className="text-sm text-[#8E8E8E]">
              Enter Slider Heading
            </label>
            <input
              id="title"
              type="text"
              {...register("title", {
                required: "This field is required",
                maxLength: {
                  value: 20,
                  message: "Not more than 20 characters",
                },
              })}
              className="mt-1 block w-full rounded-md bg-[#F4F4F4] py-2 px-3 text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
              placeholder="Not More than 20 Characters"
            />
            {errors.title && (
              <p className="text-red-500 text-xs mt-1">
                {errors.title.message as React.ReactNode}
              </p>
            )}
          </div>
        </div>

        {/* Slider Description */}
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Slider Description
          </label>
          <textarea
            id="description"
            {...register("description", {
              required: "This field is required",
              maxLength: {
                value: 50,
                message: "Not more than 50 characters",
              },
            })}
            className="mt-1 w-full h-20 px-2 py-4 rounded-md bg-[#F4F4F4] text-left text-sm text-gray-900 placeholder-gray-[#7A7A7A] outline-none font-semibold"
            placeholder="Not more than 50 Characters"
          />
          {errors.description && (
            <p className="text-red-500 text-xs mt-1">
              {errors.description.message as React.ReactNode}
            </p>
          )}
        </div>

        {/* File Upload */}
        <div>
          <div className="mb-4">
            <p className="font-semibold">Upload Slider Image</p>
          </div>

          <div className="border-2 border-dashed border-[#A9A9A9] flex flex-col items-center p-6">
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              id="file-upload"
              accept="image/*"
            />
            <label htmlFor="file-upload" className="cursor-pointer">
              <p>Upload Picture</p>
            </label>
          </div>
          {uploadedImage && (
            <div className="flex justify-center mt-4">
              <div className="relative">
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  alt="Uploaded"
                  className="h-40 w-[400px] object-cover rounded-md"
                />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 w-8 h-8"
                >
                  <X size={20} className="text-center" />
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end items-center gap-3">
          <button type="button" className="mt-4 text-blue-500">
            Cancel
          </button>
          <button
            type="submit"
            className="mt-4 text-orange-500"
            disabled={isPending}
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddNewSlider;
