import React, { useState } from "react";
import { BASE_IMAGE, getData, postData } from "../../../services/apiService";
import RatingStars from "../../../common/Rating/Rating";
import Loader from "../../../common/Loader/Loader";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChevronDown, ChevronRight, X } from "lucide-react";
import { toast } from "react-toastify";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useNavigate } from "react-router-dom";

interface FeedbackItem {
  review_review_id: string;
  sender_profile_pic: string;
  receiver_first_name: string;
  review_rev_date_time: string;
  receiver_profile_pic: string;
  review_overall_review_rate: number;
  sender_first_name: string;
  review_review_txt: string;
  review_review_status: number;
  review_is_edited: any;
  review_rejection_reason?: string;
}

const AllFeedbackCardDesign: React.FC<{
  feedback: FeedbackItem[];
  loading: boolean;
  getAllReviewsRefetch: () => void;
}> = ({ feedback, loading, getAllReviewsRefetch }) => {
  const [receiverReviewId, setReceiverReviewId] = useState<string | null>(null);
  const [denyModalOpen, setDenyModalOpen] = useState<boolean>(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [currentReviewId, setCurrentReviewId] = useState<string | null>(null);
  const [viewReasonModalOpen, setViewReasonModalOpen] =
    useState<boolean>(false);
  const [currentRejectionReason, setCurrentRejectionReason] =
    useState<string>("");
  const [isEditRejectionReason, setIsEditRejectionReason] =
    useState<boolean>(false);
  const [mediaLoadingStates, setMediaLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  const [imageOpen, setImageOpen] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<{
    type: "img" | "video";
    url: string;
  } | null>(null);

  const navigate = useNavigate();

  const { data: Media, isLoading: MediaLoading } = useQuery({
    queryKey: ["getMediabyReviewID", receiverReviewId],
    queryFn: () => getData(`media/get-media-by-review-id/${receiverReviewId}`),
    enabled: !!receiverReviewId,
  });

  const handleMediaToggle = (id: string) => {
    if (receiverReviewId === id) {
      setMediaOpen((prev) => !prev);
    } else {
      setReceiverReviewId(id);
      setMediaOpen(true);
    }
  };

  const { mutate: reviewStatus } = useMutation({
    mutationFn: (params: {
      data: any;
      review_status: number;
      reason?: string;
    }) =>
      postData(`review/update-review-status/${params.data.review_review_id}`, {
        review_status: params.review_status,
        rejection_reason: params.reason,
      }),
    onSuccess: () => {
      toast.success("Review updated successfully");
      setDenyModalOpen(false);
      setRejectionReason("");
      getAllReviewsRefetch();
    },
  });

  const { mutate: updateReasonMutate } = useMutation({
    mutationFn: (data: { reviewId: string; rejectionReason: string }) =>
      postData(`review/update-reason-text/${data.reviewId}`, {
        rejection_reason: data.rejectionReason,
      }),
    onSuccess: () => {
      toast.success("Rejection reason updated successfully");
      getAllReviewsRefetch();
      setIsEditRejectionReason(false);
      setViewReasonModalOpen(false);
    },
  });

  const handleClickAction = (
    data: FeedbackItem,
    review_status: number,
    reason?: string
  ) => {
    reviewStatus({ data, review_status, reason });
  };

  const handleDenyModalOpen = (reviewId: string) => {
    setCurrentReviewId(reviewId);
    setDenyModalOpen(true);
  };

  const handleDenySubmit = () => {
    if (currentReviewId) {
      handleClickAction(
        { review_review_id: currentReviewId } as FeedbackItem,
        3,
        rejectionReason
      );
    }
  };

  const handleViewReasonModalOpen = (reason: string, item: any) => {
    setCurrentReviewId(item?.review_review_id);
    setCurrentRejectionReason(reason);
    setViewReasonModalOpen(true);
  };

  const handleEditRejectionReason = () => {
    setIsEditRejectionReason(true);
  };

  const handleSubmitEditRejectionReason = () => {
    if (currentReviewId) {
      updateReasonMutate({
        reviewId: currentReviewId,
        rejectionReason: currentRejectionReason,
      });
    }
  };

  const handleEditNavigate = (item: any) => {
    const { review_review_id, review_review_txt, receiver_first_name } = item;
    navigate(
      `/particular-feedback-edit?review_id=${review_review_id}&review_txt=${review_review_txt}&first_name=${receiver_first_name}`
    );
  };

  const handleMediaClick = (mediaItem: any) => {
    const type = mediaItem.type || "img";
    const url = `${BASE_IMAGE}/${mediaItem.normal_img}`;
    setSelectedMedia({ type, url });
    setImageOpen(true);
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : feedback.length === 0 ? (
        <>
          <div>NO Data</div>
        </>
      ) : (
        feedback.map((item) => (
          <div
            key={item.review_review_id}
            className="bg-white p-4 rounded-lg shadow-sm mb-4"
          >
            <div className="flex gap-4 items-center">
              <img
                src={`${BASE_IMAGE}/${item.sender_profile_pic}`}
                alt="Owner"
                className="w-8 h-8 rounded-full"
              />
              <div>
                <p>
                  Response from owner{" "}
                  <span className="font-bold">
                    {item.receiver_first_name || "John Doe"}
                  </span>
                  <span
                    className={`font-normal ml-4 ${
                      item.review_review_status === 1
                        ? "text-green-500"
                        : item.review_review_status === 0
                        ? "text-red-500"
                        : item.review_review_status === 3
                        ? "text-orange-500"
                        : "text-pink-400"
                    }`}
                  >
                    {item.review_review_status === 0
                      ? "Feedback Pending"
                      : item.review_review_status === 1
                      ? "Feedback Published"
                      : item.review_review_status === 3
                      ? "Feedback Denied"
                      : null}
                  </span>
                  {item.review_review_status === 3 &&
                    item.review_rejection_reason && (
                      <span
                        className="text-blue-500 ml-2 cursor-pointer"
                        onClick={() =>
                          handleViewReasonModalOpen(
                            item.review_rejection_reason!,
                            item
                          )
                        }
                      >
                        - View Reason
                      </span>
                    )}
                </p>
                <p className="text-sm text-gray-600">
                  {new Date(item.review_rev_date_time).toLocaleString()}
                </p>
              </div>
            </div>

            <div className="mt-8 ml-16">
              <div className="flex items-center mb-2">
                <img
                  src={`${BASE_IMAGE}/${item.receiver_profile_pic}`}
                  alt="Reviewer"
                  className="w-8 h-8 rounded-full mr-4"
                />
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center  mr-2">
                      <RatingStars
                        count={5}
                        value={item.review_overall_review_rate}
                        size={21}
                        color2={"#F24E1E"}
                        edit={false}
                      />
                      <span className="font-bold ml-2">
                        {item.sender_first_name || "Anonymous"}
                      </span>
                    </div>
                    <div className="px-2 py-1">
                      <div className="col-span-1 cursor-pointer">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <div className="flex items-center gap-2">
                              <p className="text-[#F24E1E] font-semibold">
                                Action
                              </p>
                              <ChevronDown className="text-[#F24E1E]" />
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              onClick={() => handleEditNavigate(item)}
                            >
                              Edit Feedback
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() =>
                                handleDenyModalOpen(item.review_review_id)
                              }
                            >
                              Deny Feedback
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleClickAction(item, 1)}
                            >
                              Publish Feedback
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleClickAction(item, 0)}
                            >
                              Pending Feedback
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600">
                    {new Date(item.review_rev_date_time).toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-600">
                    {item?.review_is_edited === 1 ? "Edited" : null}
                  </p>
                </div>
              </div>

              <div className="ml-12">
                <p className="text-sm text-gray-700">
                  {item.review_review_txt}
                </p>

                <div
                  className="my-4 flex items-center cursor-pointer"
                  onClick={() => handleMediaToggle(item.review_review_id)}
                >
                  <p className="text-blue-500">View Media</p>
                  <div className="ml-2">
                    <ChevronRight style={{ color: "#4F46E5" }} size={15} />
                  </div>
                </div>

                {mediaOpen && receiverReviewId === item.review_review_id && (
                  <div className="flex gap-2 mt-4">
                    {MediaLoading ? (
                      <div className="flex items-center justify-center w-full py-4">
                        <Loader />
                      </div>
                    ) : Media?.data && Media.data.length > 0 ? (
                      Media.data.map((mediaItem: any, i: number) => (
                        <div key={i} className="relative">
                          {mediaLoadingStates[`${mediaItem.normal_img}`] && (
                            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 rounded-lg">
                              <Loader />
                            </div>
                          )}
                          {mediaItem?.type === "img" ? (
                            <img
                              key={i}
                              src={`${BASE_IMAGE}/${mediaItem.normal_img}`}
                              alt="Media"
                              onClick={() => handleMediaClick(mediaItem)}
                              className="w-20 h-20 rounded-lg object-cover cursor-pointer"
                              onLoadStart={() => {
                                setMediaLoadingStates((prev) => ({
                                  ...prev,
                                  [`${mediaItem.normal_img}`]: true,
                                }));
                              }}
                              onLoad={() => {
                                setMediaLoadingStates((prev) => ({
                                  ...prev,
                                  [`${mediaItem.normal_img}`]: false,
                                }));
                              }}
                            />
                          ) : (
                            <video
                              key={i}
                              src={`${BASE_IMAGE}${mediaItem?.normal_img}`}
                              onClick={() => handleMediaClick(mediaItem)}
                              className="h-28 w-28 rounded-md cursor-pointer"
                              controls
                              onLoadStart={() => {
                                setMediaLoadingStates((prev) => ({
                                  ...prev,
                                  [`${mediaItem.normal_img}`]: true,
                                }));
                              }}
                              onLoadedData={() => {
                                setMediaLoadingStates((prev) => ({
                                  ...prev,
                                  [`${mediaItem.normal_img}`]: false,
                                }));
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No media available</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      )}

      <Dialog open={denyModalOpen} onOpenChange={setDenyModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Submit Reason to Deny</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <textarea
              placeholder="Enter reason for denial here..."
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              rows={4}
              className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </DialogDescription>
          <div className="flex justify-start items-center mt-4 gap-2">
            <button
              className="bg-[#F24E1E] text-white rounded-md px-4 py-2"
              onClick={handleDenySubmit}
            >
              Submit
            </button>
            <button
              className="bg-gray-400 text-white rounded-md px-4 py-2"
              onClick={() => setDenyModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={viewReasonModalOpen} onOpenChange={setViewReasonModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rejection Reason</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            {isEditRejectionReason ? (
              <textarea
                placeholder="Enter reason for denial here..."
                value={currentRejectionReason}
                onChange={(e) => setCurrentRejectionReason(e.target.value)}
                rows={4}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ) : (
              <p>{currentRejectionReason}</p>
            )}
          </DialogDescription>
          <div className="flex justify-end items-center mt-4 gap-5">
            {isEditRejectionReason ? (
              <button
                className="bg-red-400 text-white rounded-md px-4 py-2"
                onClick={handleSubmitEditRejectionReason}
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-red-400 text-white rounded-md px-4 py-2"
                onClick={handleEditRejectionReason}
              >
                Edit
              </button>
            )}

            <button
              className="bg-gray-400 text-white rounded-md px-4 py-2"
              onClick={() => {
                setViewReasonModalOpen(false);
                setIsEditRejectionReason(false);
              }}
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={imageOpen} onOpenChange={setImageOpen}>
        <DialogContent className="sm:max-w-[90vw] max-h-[90vh] overflow-auto">
          <DialogHeader>
            <div className="flex justify-between items-center">
              {/* <DialogTitle>Large View</DialogTitle> */}
              <button
                onClick={() => setImageOpen(false)}
                className="rounded-full p-2 hover:bg-gray-100"
              >
                {/* <X className="h-4 w-4" /> */}
              </button>
            </div>
          </DialogHeader>
          <div className="flex items-center justify-center p-4">
            {selectedMedia?.type === "img" ? (
              <img
                src={selectedMedia.url}
                alt="Large view"
                className="max-w-full max-h-[70vh] object-contain"
              />
            ) : (
              <video
                src={selectedMedia?.url}
                controls
                className="max-w-full max-h-[70vh]"
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllFeedbackCardDesign;
