import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import { format, parseISO } from "date-fns";
import { SiReason } from "react-icons/si";
import { BiCategory } from "react-icons/bi";
import {
  CalendarDays,
  CircleUser,
  FileType,
  IndianRupee,
  MapPin,
  Phone,
  ShieldCheck,
  User,
} from "lucide-react";

interface PersonalInformationProps {
  refetch: () => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  refetch,
}) => {
  const [tab, setTab] = useState<string>("profile");

  const handleTabChange = (tab: string) => {
    console.log("Tab changed to", tab);
    setTab(tab);
  };

  const formatDate = (dateString: string | undefined) => {
    return dateString ? format(parseISO(dateString), "dd/MM/yyyy") : "N/A";
  };

  const user = useSelector((state: any) => state?.AntoilerReview);

  return (
    <>
      {tab === "profile" ? (
        <div className="bg-white p-4 rounded-lg border-gray-200 border">
          <div className="flex justify-between">
            <p className="text-black font-bold">Personal Information</p>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleTabChange("editProfile")}
            >
              Edit Profile
            </p>
          </div>

          <div className="mt-4 flex flex-wrap gap-4 justify-between">
            <div className="">
              <div className="flex items-center justify-start gap-2">
                <User size={15} />
                <label className="block text-[#8E8E8E] font-medium">
                  Full Name
                </label>
              </div>
              <input
                value={user?.data?.first_name || ""}
                type="text"
                className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                placeholder="Enter your full name"
                readOnly
                disabled
              />
            </div>

            <div className="">
              <div className="flex items-center justify-start gap-2">
                <MapPin size={15} />
                <label className="block text-[#8E8E8E] font-medium">
                  Location
                </label>
              </div>
              <input
                value={user?.data?.location_area || ""}
                type="text"
                className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                placeholder="Enter your location"
                readOnly
                disabled
              />
            </div>

            {user?.data?.role === 2 && (
              <div className="">
                <div className="flex items-center justify-start gap-2">
                  <BiCategory />
                  <label className="block text-[#8E8E8E] font-medium">
                    Category
                  </label>
                </div>
                <input
                  value={user?.data?.cat_title || ""}
                  type="text"
                  className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                  placeholder="Enter your membership type"
                  readOnly
                  disabled
                />
              </div>
            )}

            <div className="">
              <div className="flex items-center justify-start gap-2">
                <Phone size={15} />
                <label className="block text-[#8E8E8E] font-medium">
                  Phone Number
                </label>
              </div>
              <input
                value={user?.data?.mobile || ""}
                type="tel"
                className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                placeholder="Enter your phone number"
                readOnly
                disabled
              />
            </div>

            {user?.data?.role === 2 && (
              <div className="">
                <div className="flex items-center justify-start gap-2">
                  <CircleUser size={15} />
                  <label className="block text-[#8E8E8E] font-medium">
                    Gender
                  </label>
                </div>
                <input
                  value={user?.data?.gender === "f" ? "Female" : "Male"}
                  type="text"
                  className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                  placeholder="Enter your gender"
                  readOnly
                  disabled
                />
              </div>
            )}

            {user?.data?.role === 2 && (
              <div className="">
                <div className="flex items-center justify-start gap-2">
                  <CalendarDays size={15} />
                  <label className="block text-[#8E8E8E] font-medium">
                    Experience
                  </label>
                </div>
                <input
                  value={user?.data?.experince || ""}
                  type="text"
                  className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                  placeholder="Enter your address"
                  readOnly
                  disabled
                />
              </div>
            )}

            <div className="flex justify-between flex-row gap-24">
              {user?.data?.role === 2 && (
                <div className="">
                  <div className="flex items-center justify-start gap-2">
                    <IndianRupee size={15} />
                    <label className="block text-[#8E8E8E] font-medium">
                      Charges
                    </label>
                  </div>
                  <input
                    value={user?.data?.user_rate || ""}
                    type="text"
                    className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                    placeholder="Enter your charges"
                    readOnly
                    disabled
                  />
                </div>
              )}

              {user?.data?.role === 2 && (
                <div className="flex-1">
                  <div className="flex items-center justify-start gap-2">
                    <FileType size={15} />
                    <label className="block text-[#8E8E8E] font-medium">
                      Rate Type
                    </label>
                  </div>
                  <input
                    value={user?.data?.rate_type || ""}
                    type="text"
                    className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                    placeholder="Enter your rate type"
                    readOnly
                    disabled
                  />
                </div>
              )}

              {user?.data?.role === 2 && (
                <div className="flex-1">
                  <div className="flex items-center justify-start gap-2">
                    <CalendarDays size={15} />
                    <label className="block text-[#8E8E8E] font-medium">
                      DOB
                    </label>
                  </div>
                  <input
                    value={formatDate(user?.data?.dob)}
                    type="text"
                    className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                    placeholder="Enter your date of birth"
                    readOnly
                    disabled
                  />
                </div>
              )}
            </div>
            {/* New line with three divs */}
            <div className="w-full flex gap-4">
              <div className="flex-1">
                <div className="flex items-center justify-start gap-2">
                  <ShieldCheck size={15} />
                  <label className="block text-[#8E8E8E] font-medium">
                    Current Status
                  </label>
                </div>
                <input
                  disabled
                  value={
                    user?.data?.profile_status === 0
                      ? "Pending"
                      : user?.data?.profile_status === 1
                      ? "Verify"
                      : user?.data?.profile_status === 2
                      ? "Published"
                      : user?.data?.profile_status === 3
                      ? "Unpublished"
                      : user?.data?.profile_status === 4
                      ? "Blocked"
                      : user?.data?.profile_status === 5
                      ? "Deactivated"
                      : "Unknown Status"
                  }
                  type="text"
                  className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                  placeholder="Status"
                  readOnly
                />
              </div>

              <div className="flex-1">
                <div className="flex items-center justify-start gap-2">
                  <SiReason />
                  <label className="block text-[#8E8E8E] font-medium">
                    Status Reason
                  </label>
                </div>
                <textarea
                  value={user?.data?.reason || ""}
                  className="mt-1 block w-full p-2 bg-gray-100 rounded-md"
                  placeholder="Reason"
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      ) : tab === "editProfile" ? (
        <EditProfile refetch={refetch} onChange={handleTabChange} />
      ) : null}
    </>
  );
};

export default PersonalInformation;
