import React, { useState } from "react";

import { ChevronDownIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import {
  Select as SingleSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

const AdvanceFilterReview = ({ handleSetReviewStatus = () => {} }: any) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleApplyFilter = () => {
    handleSetReviewStatus(selectedStatus);
    handleModalToggle();
  };

  return (
    <>
      <div className="mt-10 flex justify-between items-center">
        <div></div>
        <Button
          variant="ghost"
          className="text-orange-500 font-semibold"
          onClick={handleModalToggle}
        >
          Advanced Filter
          <ChevronDownIcon size={20} className="ml-2" />
        </Button>
      </div>
      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center mt-4">
            <p className="font-semibold">Advanced Filter</p>
            <p
              onClick={handleApplyFilter}
              className="ml-auto cursor-pointer text-[#0085FF]"
            >
              Apply Filter
            </p>
          </div>

          {/* Single select for Status */}
          <div className="mt-4">
            <SingleSelect
              onValueChange={setSelectedStatus}
              value={selectedStatus}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Reviews" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Pending</SelectItem>
                <SelectItem value="1">Published</SelectItem>
                <SelectItem value="2">Denied</SelectItem>
                {/* <SelectItem value="3">Edit</SelectItem> */}
              </SelectContent>
            </SingleSelect>
          </div>

          {/* Single select for Gender */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdvanceFilterReview;
