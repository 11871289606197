import { useEffect, useState } from "react";
const useSize = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return windowSize;
};

const useViewport = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 700);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []); // The empty dependency array ensures this effect runs only once on mount

  return isMobile;
};
export { useViewport, useSize };
// export default useSize;
