import { useState } from "react";
import { ChevronRight, X } from "lucide-react";
import RateTypeDropdown from "./RateTypeDropdown"; // Assuming this is your dropdown component for selecting types
import { postData } from "../../../services/apiService";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditRateCardAccordian = ({ data, refetch, onClick }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [openModal, setModalOpen] = useState(false);
  const [rateCardSno, setRateCardSno] = useState<number | undefined>(undefined);

  // New states for form values, replacing 'rateType' with 'type'
  const [formValues, setFormValues] = useState({
    title: "",
    rate: "",
    description: "",
    type: "", // 'type' instead of 'rateType'
  });

  const { mutate: deleteRateCard } = useMutation({
    mutationFn: () => {
      if (rateCardSno !== undefined) {
        return postData(`rate-card/delete-rate-card/${rateCardSno}`);
      }
      return Promise.reject("RateCardSno is undefined");
    },
    onSuccess: () => {
      toast.success("Rate card deleted successfully");
      refetch();
    },
    onError: (error) => {
      console.error("Error deleting rate card:", error);
    },
  });

  const { mutate: editRateCard } = useMutation({
    mutationFn: () =>
      postData(`rate-card/update-rate-card/${rateCardSno}`, formValues), // Send formValues with 'type'
    onSuccess: () => {
      toast.success("Rate card updated successfully");
      onClick("accordion");
      refetch();
    },
    onError: (error) => {
      console.error("Error updating rate card:", error);
    },
  });

  const handleDelete = (sno: number) => {
    setRateCardSno(sno);
    deleteRateCard();
  };

  const handleEdit = () => {
    // Validate required fields
    const { title, rate, description, type } = formValues;

    if (!title || !rate || !description || !type) {
      toast.error("Please fill in all fields before saving.");
      return; // Exit if any field is empty
    }

    editRateCard(); // Trigger the mutation for editing
    handleModalClose(); // Close modal after editing
  };

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleModalOpen = (
    sno: number,
    title: string,
    rate: string,
    description: string,
    type: string // Updated to 'type'
  ) => {
    setRateCardSno(sno);
    setFormValues({
      title,
      rate,
      description,
      type, // Use 'type' here as well
    });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSelect = (option: string) => {
    setFormValues((prev) => ({ ...prev, type: option })); // Update 'type' instead of 'rateType'
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="space-y-4 p-4 rounded-md">
        {data?.data?.map((item: any, index: any) => (
          <div
            key={item.sno}
            className="border border-gray-200 rounded-md overflow-hidden bg-[#F4F4F4]"
          >
            <div className="flex items-center justify-between w-full p-4">
              <button
                className="flex items-center text-left flex-grow"
                onClick={() => handleToggle(index)}
              >
                <ChevronRight
                  className={`w-5 h-5 mr-3 transition-transform duration-200 ${
                    openIndex === index ? "transform rotate-90" : ""
                  }`}
                />
                <span className="flex-grow mr-2 truncate text-gray-900 font-semibold">
                  {item.title}
                </span>
              </button>
              <span className="text-[#F24E1E] font-semibold whitespace-nowrap">
                {item.rate}
              </span>
            </div>
            {openIndex === index && (
              <div className="p-4 bg-white">
                <p className="text-[#7A7A7A]">{item.description}</p>
              </div>
            )}
            <div className="flex items-center gap-4 justify-start p-2">
              <button
                className="text-[#0085FF]"
                onClick={() =>
                  handleModalOpen(
                    item.sno,
                    item.title,
                    item.rate,
                    item.description,
                    item.type // Use 'type' here
                  )
                }
              >
                Edit Card
              </button>
              <button
                className="text-[#FF0000]"
                onClick={() => handleDelete(item.sno)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Overlay */}
      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="relative bg-white px-8 py-8 rounded-md shadow-lg w-[40rem]">
            <button
              className="absolute top-2 right-4 text-black hover:text-gray-700"
              onClick={handleModalClose}
            >
              <X className="w-5 h-5" />
            </button>
            <div className="flex justify-between items-center px-2 pt-4">
              <p className="text-sm font-bold">Edit Rate Card</p>
              <button className="text-[#0085FF]" onClick={handleEdit}>
                Save
              </button>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              <div className="border border-gray-300 rounded-md p-2 w-full">
                <input
                  type="text"
                  name="title"
                  value={formValues.title}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium w-full outline-none border-none"
                  placeholder="Title Goes Here"
                />
              </div>
              <div className="border border-gray-300 rounded-md p-2 flex flex-row justify-between w-full">
                <input
                  type="text"
                  name="rate"
                  value={formValues.rate}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium w-full outline-none border-none"
                  placeholder="Rate Goes Here"
                />
                <RateTypeDropdown
                  onSelect={handleSelect}
                  selected={formValues.type} // Updated to 'type'
                />
              </div>
              <div className="border border-gray-300 rounded-md p-2">
                <textarea
                  name="description"
                  value={formValues.description}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium h-52 resize-none w-full outline-none border-none"
                  placeholder="Description Goes Here"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditRateCardAccordian;
