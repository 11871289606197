import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getData, postData } from "../../../services/apiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../common/Loader/Loader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Select from "react-select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

interface EditProfileProps {
  refetch: () => void;
  onChange: (tab: string) => void;
}

interface FormData {
  first_name: string;
  location_area: string;
  dob: Date | null;
  mobile: string;
  gender: string;
  experince: string;
  user_rate?: any;
  rate_type?: string;
  profile_status: string;
  categories_id: string;
  lat?: any;
  lng?: any;
}

interface AddressDetails {
  formatted_address: string;
  lat: number | null;
  lng: number | null;
  locality: string;
}

interface ValidationErrors {
  first_name: string;
  location_area: string;
  mobile: string;
  user_rate: any;
}

const libraries: "places"[] = ["places"];

const EditProfile: React.FC<EditProfileProps> = ({ refetch, onChange }) => {
  const user = useSelector((state: any) => state.AntoilerReview);
  const [selectedProfession, setSelectedProfession] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [tempProfileStatus, setTempProfileStatus] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(true);
  const [validationErrors, setValidationErrors] = useState<any>({
    first_name: "",
    location_area: "",
    mobile: "",
    ...(user?.data?.role === 2 && { user_rate: "" }), // Conditionally add `user_rate` if role is 2
  });

  const [formData, setFormData] = useState<FormData>({
    first_name: "",
    location_area: "",
    dob: null,
    mobile: "",
    gender: "",
    experince: "",
    user_rate: "",
    rate_type: "",
    profile_status: "",
    categories_id: "",
    lat: null,
    lng: null,
  });

  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    formatted_address: "",
    lat: null,
    lng: null,
    locality: "",
  });

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (user && user.data) {
      setFormData({
        first_name: user.data.first_name || "",
        location_area: user.data.location_area || "",
        dob: user.data.dob ? new Date(user.data.dob) : null,
        mobile: user.data.mobile || "",
        gender: user.data.gender || "",
        experince: user.data.experince || "",
        user_rate: user.data.user_rate || "",
        rate_type: user.data.rate_type || "",
        profile_status: user.data.profile_status?.toString() || "0",
        categories_id: user.data.categories_id || "",
        lat: user.data.latitude || null,
        lng: user.data.longitude || null,
      });

      setReason(user.data.reason || "");

      setAddressDetails({
        locality: user.data.location_area || "",
        lat: user.data.latitude || null,
        lng: user.data.longitude || null,
        formatted_address: "",
      });
    }
  }, [user?.data]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === "gender") {
      setFormData((prev) => ({
        ...prev,
        gender: value,
      }));
    } else if (name === "profile_status" && value !== formData.profile_status) {
      setTempProfileStatus(value);
      setIsModalOpen(true);
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear validation error when user starts typing
    setValidationErrors((prev: any) => ({
      ...prev,
      [name]: "",
    }));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (data: any) =>
      postData(`user/update-antoiler-by-id/${user?.data?.user_id}`, data),
    onSuccess: () => {
      toast.success("Profile updated successfully");
      refetch();
      onChange("profile");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message?.message);
      console.error("Error updating profile:", error);
    },
  });

  const handleModalClose = () => {
    setIsModalOpen(false);
    setReason("");
    setTempProfileStatus("");
  };

  const handleModalSave = () => {
    setFormData((prev) => ({
      ...prev,
      profile_status: tempProfileStatus,
    }));
    setIsModalOpen(false);
  };

  const validateForm = (): boolean => {
    const errors: any = {
      first_name: "",
      location_area: "",
      mobile: "",
      ...(user?.data?.role === 2 && { user_rate: "" }),
    };

    if (!formData.first_name.trim()) {
      errors.first_name = "Full Name is required";
    }

    if (!formData.location_area.trim()) {
      errors.location_area = "Location is required";
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Phone Number is required";
    }
    if (user?.data?.role === 2) {
      if (!formData.user_rate) {
        errors.user_rate = "Charges are required";
      }
    }

    setValidationErrors(errors);

    return !Object.values(errors).some((error) => error !== "");
  };

  const handleSave = () => {
    if (!validateForm()) {
      // toast.error("Please Fill Currect Information");
      return;
    }

    const formattedDate = formData.dob
      ? format(formData.dob, "yyyy-MM-dd")
      : "";

    const dataToSubmit = {
      ...(user?.data?.role === 3
        ? {
            profile_status: formData.profile_status,
            first_name: formData.first_name,
            location_area: addressDetails.locality,
            latitude: addressDetails.lat,
            longitude: addressDetails.lng,
            mobile: formData.mobile,
            gender: formData.gender,
            categories_id: selectedProfession?.value,
          }
        : {
            ...formData,
            dob: formattedDate,
            ...(user?.data?.role === 2 && {
              location_area: addressDetails.locality,
              latitude: addressDetails.lat,
              longitude: addressDetails.lng,
              categories_id: selectedProfession?.value,
            }),
          }),
      reason: reason,
    };
    console.log({ dataToSubmit });
    mutate(dataToSubmit);
    setReason("");
  };

  const handleCancel = () => {
    onChange("profile");
  };

  const onLoad = (autocompleteInstance: google.maps.places.Autocomplete) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const cityComponent = place.address_components?.find((component) =>
        component.types.includes("locality")
      );
      const cityName = cityComponent?.long_name;

      if (cityName?.toLowerCase() === "gwalior") {
        const localityComponent = place.address_components?.find((component) =>
          component.types.includes("sublocality")
        );

        const newLocality = localityComponent?.long_name || "";
        const formattedAddress = place.formatted_address || "";

        setAddressDetails({
          formatted_address: formattedAddress,
          lat: place.geometry?.location?.lat() || null,
          lng: place.geometry?.location?.lng() || null,
          locality: newLocality,
        });

        setFormData((prev) => ({
          ...prev,
          location_area: newLocality,
        }));

        // Clear location validation error
        setValidationErrors((prev: any) => ({
          ...prev,
          location_area: "",
        }));
      } else {
        toast.error("Selected location is not in Gwalior.");
        setFormData((prev) => ({
          ...prev,
          location_area: "",
        }));
      }
    }
  };

  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  const getAllcategoriesValue = useMemo(() => {
    return (
      getAllcategories?.data?.data?.map((item: any) => ({
        label: item?.cat_title,
        value: item?.cat_id,
      })) || []
    );
  }, [getAllcategories?.data?.data]);

  useEffect(() => {
    if (user?.data && getAllcategoriesValue.length > 0) {
      const selected = getAllcategoriesValue.find(
        (category: any) => category.value == user.data.categories_id
      );
      setSelectedProfession(selected);
    }
  }, [user?.data, getAllcategoriesValue]);

  return (
    <div className="bg-white p-4 rounded-lg border-gray-200 border">
      <div className="flex justify-between">
        <p className="text-black font-bold">Edit Profile</p>
        <div className="flex justify-end items-center gap-4">
          <button className="text-black" onClick={handleCancel}>
            Cancel
          </button>
          <button
            onClick={handleSave}
            className={`text-blue-500 ${
              isPending || !isFormChanged ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isPending || !isFormChanged}
          >
            {isPending ? <LoadingSpinner /> : "Save"}
          </button>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-[#8E8E8E] font-medium">Full Name</label>
          <input
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            type="text"
            className={`mt-1 block w-full p-2 rounded-md bg-white border ${
              validationErrors.first_name ? "border-red-500" : "border-gray-200"
            }`}
            placeholder="Enter your full name"
          />
          {validationErrors.first_name && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.first_name}
            </p>
          )}
        </div>

        <div>
          <label className="block text-[#8E8E8E] font-medium">Location</label>
          <LoadScript
            googleMapsApiKey="AIzaSyCRwb0vA7hZbyngiluURoKWM_635FuiHyA"
            libraries={libraries}
          >
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              options={{ componentRestrictions: { country: "IN" } }}
            >
              <input
                type="text"
                placeholder="Enter a location"
                value={formData.location_area}
                onChange={handleInputChange}
                name="location_area"
                className={`mt-1 block w-full p-2 rounded-md bg-white border ${
                  validationErrors.location_area
                    ? "border-red-500"
                    : "border-gray-200"
                }`}
              />
            </Autocomplete>
          </LoadScript>
          {validationErrors.location_area && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.location_area}
            </p>
          )}
        </div>

        {user?.data?.role === 2 && (
          <div>
            <label className="block text-[#8E8E8E] font-medium">
              Date of Birth
            </label>
            <DatePicker
              selected={formData.dob}
              onChange={(date: any) => {
                setFormData((prev) => ({ ...prev, dob: date }));
              }}
              className="mt-1 block w-full p-2 rounded-md bg-white border border-gray-200"
              dateFormat="yyyy-MM-dd"
              placeholderText="Select your date of birth"
            />
          </div>
        )}

        <div>
          <label className="block text-[#8E8E8E] font-medium">
            Phone Number
          </label>
          <input
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            type="number"
            className={`mt-1 block w-full p-2 rounded-md bg-white border ${
              validationErrors.mobile ? "border-red-500" : "border-gray-200"
            }`}
          />
          {validationErrors.mobile && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.mobile}
            </p>
          )}
        </div>

        {user?.data?.role === 2 && (
          <div>
            <label className="block text-[#8E8E8E] font-medium">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 rounded-md bg-white border border-gray-200"
            >
              <option value="">Select Gender</option>
              <option value="m">Male</option>
              <option value="f">Female</option>
            </select>
          </div>
        )}

        {user?.data?.role === 2 && (
          <div>
            <label className="block text-[#8E8E8E] font-medium">
              Experience
            </label>
            <select
              className="w-full p-2 bg-gray-100 rounded-md text-gray-900 outline-none border border-gray-300"
              name="experince"
              value={formData.experince}
              onChange={handleInputChange}
            >
              <option disabled>Experience</option>
              <option value="0">0+ yrs</option>
              <option value="2">2+ yrs</option>
              <option value="5">5+ yrs</option>
              <option value="10">10+ yrs</option>
              <option value="15">15+ yrs</option>
              <option value="20">20+ yrs</option>
            </select>
          </div>
        )}

        {user?.data?.role === 2 && (
          <>
            <div>
              <label className="block text-[#8E8E8E] font-medium">
                Charges
              </label>
              <input
                name="user_rate"
                value={formData.user_rate || ""}
                onChange={handleInputChange}
                type="text"
                className={`mt-1 block w-full p-2 rounded-md bg-white border ${
                  validationErrors.user_rate
                    ? "border-red-500"
                    : "border-gray-200"
                }`}
                placeholder="Enter your charges"
              />
              {validationErrors.user_rate && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors.user_rate}
                </p>
              )}
            </div>

            <div>
              <label className="block text-[#8E8E8E] font-medium">
                Rate Type
              </label>
              <select
                name="rate_type"
                value={formData.rate_type || ""}
                onChange={handleInputChange}
                className="mt-1 block w-full p-2 rounded-md bg-white border border-gray-200"
              >
                <option value="" disabled>
                  Select Rate Type
                </option>
                <option value="Per Hour">Per Hour</option>
                <option value="Per Month">Per Month</option>
                <option value="Per Day">Per Day</option>
                <option value="Per Service">Per Service</option>
              </select>
            </div>
          </>
        )}
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-[#8E8E8E] font-medium">
            Current Status
          </label>
          <select
            name="profile_status"
            value={formData.profile_status}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 rounded-md bg-white border border-gray-200"
          >
            {user?.data?.role === 2 && (
              <>
                <option value="0">Pending</option>
                <option value="1">Verify</option>
                <option value="2">Published</option>
                <option value="3">Unpublished</option>
                <option value="4">Blocked</option>
              </>
            )}
            {user?.data?.role === 3 && (
              <>
                <option value="4">Blocked</option>
                <option value="2">Published</option>
                <option value="5">Deactivated</option>
              </>
            )}
          </select>
        </div>

        {user?.data?.role === 2 && (
          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category Select
            </label>
            <Select
              options={getAllcategoriesValue}
              value={selectedProfession}
              onChange={(option) => {
                setSelectedProfession(option);
              }}
              placeholder="Select Category"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
            />
          </div>
        )}
      </div>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Provide Reason for Update</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter reason for profile update"
              className="w-full p-2 border border-gray-300 rounded-md resize-none"
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button onClick={handleModalSave} disabled={!reason.trim()}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditProfile;
