import { useState, useEffect } from "react";
import AdvanceFilter from "../components/AdvanceFilter";
import AntoilerTable from "../components/AntoilerTable";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import Button from "../../../domains/Dashboard/components/Button";
import { FiPlus } from "react-icons/fi";
import { Check, File, X } from "lucide-react"; // Import X for the cross icon
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import { useDispatch } from "react-redux";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import moment from "moment";
import * as XLSX from "xlsx"; // Import the xlsx library
import { format } from "path";
import { formatDate } from "date-fns";

const AllAntoiler = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedGender, setSelectedGender] = useState<any>("");
  const [selectedLocality, setSelectedLocality] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [categoryId, setCategoryId] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null); // Change to DateObject or null
  const [endDate, setEndDate] = useState<any>(null); // Change to DateObject or null
  const [registerationDate, setRegisterationDate] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const itemsPerPage = 10;

  const {
    data: getAllAntoilers,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "getAllAntoilers",
      searchTerm,
      currentPage,
      selectedGender,
      selectedLocality,
      selectedStatus,
      registerationDate,
      startDate,
      endDate,
      categoryId,
    ],
    queryFn: () => {
      // let queryString = `user/get-all-antoilers?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}&gender=${selectedGender}&location_area=${selectedLocality
      //   .map((loc: any) => loc?.value)
      //   .join(",")}&profile_status=${selectedStatus}${
      //   registerationDate ? `&reg_date=${registerationDate}` : ""
      // }&category_id=${categoryId?.value ? categoryId.value : ""}`;

      let queryString = `user/get-all-antoilers?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}&gender=${selectedGender}&location_area=${selectedLocality
        .map((loc: any) => loc?.value)
        .join(",")}&profile_status=${selectedStatus}${
        startDate ? `&start_date=${startDate}` : ""
      }${endDate ? `&end_date=${endDate}` : ""}&category_id=${
        categoryId?.value ? categoryId.value : ""
      }`;

      return getData(queryString);
    },
  });

  // Utility functions for local storage
  const loadFiltersFromLocalStorage = () => {
    const storedFilters = localStorage.getItem("filters");
    if (storedFilters) {
      return JSON.parse(storedFilters);
    }
    return {
      selectedGender: "",
      selectedLocality: [],
      selectedStatus: "",
      categoryId: "",
      registerationDate: null,
      startDate: null, // Load startDate from local storage
      endDate: null, // Load endDate from local storage
    };
  };
  const saveFiltersToLocalStorage = (filters: any) => {
    localStorage.setItem("filters", JSON.stringify(filters));
  };
  // Load filters on component mount
  useEffect(() => {
    const {
      selectedGender,
      selectedLocality,
      selectedStatus,
      categoryId,
      registerationDate,
      startDate, // Load startDate from local storage
      endDate, // Load endDate from local storage
    } = loadFiltersFromLocalStorage();

    setSelectedGender(selectedGender);
    setSelectedLocality(selectedLocality);
    setSelectedStatus(selectedStatus);
    setCategoryId(categoryId);
    setRegisterationDate(registerationDate);
    setStartDate(startDate); // Set startDate in state
    setEndDate(endDate); // Set endDate in state
    refetch(); // Refetch data with loaded filters
  }, []);

  // Convert date format
  const convertDateFormat = (dateStr: string | null) => {
    if (!dateStr) return null;
    const parsedDate = moment(dateStr, "DD-MM-YYYY", true);
    return parsedDate.isValid() ? parsedDate.format("YYYY/MM/DD") : null;
  };

  // Handle search
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Handle apply filters
  const handleApplyFilters = (
    gender: string,
    locality: any[],
    status: string,
    categoryId: any,
    registerationDate: string | null,
    startDate: any, // Include startDate
    endDate: any // Include endDate
  ) => {
    setSelectedGender(gender);
    setSelectedLocality(locality);
    setSelectedStatus(status);
    setCategoryId(categoryId);
    setStartDate(startDate);
    setEndDate(endDate);
    setRegisterationDate(convertDateFormat(registerationDate));
    setCurrentPage(1);
    refetch();

    // Save filters to localStorage
    saveFiltersToLocalStorage({
      selectedGender: gender,
      selectedLocality: locality,
      selectedStatus: status,
      categoryId: categoryId,
      registerationDate: registerationDate,
      startDate: startDate, // Save startDate to local storage
      endDate: endDate, // Save endDate to local storage
    });
  };

  // Clear all filters
  const clearFilter = () => {
    setSelectedGender("");
    setSelectedLocality([]);
    setSelectedStatus("");
    setCurrentPage(1);
    setRegisterationDate(null);
    setCategoryId("");
    setStartDate(null); // Clear startDate
    setEndDate(null); // Clear endDate
    localStorage.removeItem("filters"); // Remove filters from localStorage
    refetch();
  };

  // Handle navigation to create account page
  const BreadcrumbObject: any = {
    title: "Antoiler create account",
    url: "/antoiler-create-account",
  };

  const handleClick = () => {
    dispatch(addBreadcrumb(BreadcrumbObject));
    navigate("/antoiler-create-account");
  };

  // Function to export data to Excel
  const handleExport = async () => {
    let queryString = `user/get-all-antoilers?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}&gender=${selectedGender}&location_area=${selectedLocality
      .map((loc: any) => loc?.value)
      .join(",")}&profile_status=${selectedStatus}${
      startDate ? `&start_date=${startDate}` : ""
    }${endDate ? `&end_date=${endDate}` : ""}&category_id=${
      categoryId?.value ? categoryId.value : ""
    }`;

    try {
      const allDataResponse = await getData(queryString);
      const tableData = allDataResponse?.data?.data;

      if (!tableData || tableData.length === 0) {
        alert("No data available to export.");
        return;
      }

      const formattedData = tableData.map((item: any) => ({
        Name: item.user_first_name,
        Mobile: item.user_mobile,
        Locality: item.user_location_area,
        Category: item.category_cat_title,
        RegistrationDate: moment(item.user_reg_date_time).format("DD/MM/YYYY"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Antoilers Data");
      XLSX.writeFile(workbook, "AntoilersData.xlsx");
    } catch (error) {
      console.error("Error fetching data for export:", error);
      alert("Failed to export data.");
    }
  };

  // Clear individual filters
  // Clear individual filters and update local storage
  const clearIndividualFilter = (filterType: string) => {
    let updatedFilters = {
      selectedGender,
      selectedLocality,
      selectedStatus,
      categoryId,
      registerationDate,
      startDate,
      endDate, // Include startDate and endDate
    };

    if (filterType === "gender") {
      updatedFilters.selectedGender = "";
    } else if (filterType === "locality") {
      updatedFilters.selectedLocality = [];
    } else if (filterType === "status") {
      updatedFilters.selectedStatus = "";
    } else if (filterType === "categoryId") {
      updatedFilters.categoryId = "";
    } else if (filterType === "registerationDate") {
      updatedFilters.registerationDate = null;
    } else if (filterType === "startDate") {
      updatedFilters.startDate = null; // Clear startDate
      updatedFilters.endDate = null; // Clear endDate
    } else if (filterType === "endDate") {
      updatedFilters.endDate = null; // Clear endDate
    }

    // Update component state
    if (filterType === "gender") {
      setSelectedGender("");
    } else if (filterType === "locality") {
      setSelectedLocality([]);
    } else if (filterType === "status") {
      setSelectedStatus("");
    } else if (filterType === "categoryId") {
      setCategoryId("");
    } else if (filterType === "registerationDate") {
      setRegisterationDate(null);
    } else if (filterType === "startDate") {
      setStartDate(null); // Clear startDate from state
    } else if (filterType === "endDate") {
      setEndDate(null); // Clear endDate from state
    }

    // Update local storage with remaining filters
    localStorage.setItem("filters", JSON.stringify(updatedFilters));

    // Trigger the API call after clearing the filter
    setCurrentPage(1);
    refetch();
  };

  // Render selected filters with cross icons
  const renderSelectedFilters = () => {
    const filters = [];

    if (selectedGender) {
      filters.push(
        <div
          key="gender"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Gender: ${selectedGender === "m" ? "Male" : "Female"}`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => clearIndividualFilter("gender")}
          />
        </div>
      );
    }

    if (selectedLocality.length > 0) {
      filters.push(
        <div
          key="locality"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Locality: ${selectedLocality
            .map((loc: any) => loc.label)
            .join(", ")}`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => clearIndividualFilter("locality")}
          />
        </div>
      );
    }

    if (selectedStatus) {
      filters.push(
        <div
          key="status"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Status: ${
            selectedStatus == 0
              ? "Pending"
              : selectedStatus == 1
              ? "Verify"
              : selectedStatus == 2
              ? "Published"
              : selectedStatus == 3
              ? "Unpublished"
              : selectedStatus == 4
              ? "Blocked"
              : selectedStatus == 5
              ? "Deactivate"
              : ""
          }`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => clearIndividualFilter("status")}
          />
        </div>
      );
    }
    // In your rendering logic
    if (categoryId && categoryId.label) {
      filters.push(
        <div
          key="category"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Category: ${categoryId.label}`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => clearIndividualFilter("categoryId")}
          />
        </div>
      );
    }

    if (registerationDate) {
      filters.push(
        <div
          key="date"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Registration Date: ${formatDate(
            new Date(registerationDate),
            "dd/MM/yyyy"
          )}`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => clearIndividualFilter("registerationDate")}
          />
        </div>
      );
    }

    if (startDate && endDate) {
      filters.push(
        <div
          key="startDate"
          className="flex items-center bg-gray-200 p-2 rounded-md"
        >
          <span>{`Start Date: ${startDate} - End Date: ${endDate}`}</span>
          <X
            className="ml-2 text-gray-500 cursor-pointer"
            size={16}
            onClick={() => {
              clearIndividualFilter("startDate");
            }}
          />
        </div>
      );
    }

    return filters;
  };

  return (
    <div className="m-4">
      <div className="mt-4">
        <p className="font-semibold">All Antoilers</p>
        <div className="flex justify-end items-center mb-4">
          <Button
            label="Add Antoilers"
            onClick={handleClick}
            style={{ backgroundColor: "#F15A24", color: "white" }}
            className="rounded-lg flex p-2 items-center"
            icon={FiPlus}
            iconPosition="left"
          />
        </div>
      </div>
      <Search onChange={handleSearch} />

      {/* Display Selected Filters */}

      <div className="flex gap-2 mt-4 flex-wrap">{renderSelectedFilters()}</div>

      <div className="flex justify-end mt-4">
        <button
          onClick={handleExport}
          className="px-6 py-2 bg-green-200 hover:bg-green-300 rounded-md flex items-center justify-center flex-row gap-2 transition duration-300"
        >
          <span className="text-[#119C2B] font-semibold">Export</span>
          <File className="w-5 h-5 text-[#119C2B]" />
        </button>
      </div>
      <AdvanceFilter
        antoilerCount={getAllAntoilers?.data?.totalResults}
        onApplyFilters={handleApplyFilters}
        clearFilter={clearFilter}
      />

      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <Loader />
        </div>
      ) : (
        <>
          <AntoilerTable getAllAntoilers={getAllAntoilers} refetch={refetch} />
          {getAllAntoilers?.data && (
            <Pagination
              totalItems={Number(getAllAntoilers?.data?.totalResults || 0)}
              itemsPerPage={Number(getAllAntoilers?.data?.limit)}
              currentPage={Number(getAllAntoilers?.data?.page)}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AllAntoiler;
