import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { SortIcon } from "../../../common/icons";
import { BASE_IMAGE } from "../../../services/apiService";
import { useDispatch } from "react-redux"; // Import useDispatch
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice"; // Import addBreadcrumb action

const AllCustomerTable = ({ getAllCustomers }: any) => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("user_first_name");
  const [sortedData, setSortedData] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize dispatch

  const BreadCrumbObject: any = {
    title: "User Profile",
    url: `/all-customers`, // Adjust URL to match your case
  };

  useEffect(() => {
    setSortedData(getAllCustomers?.data?.data || []);
  }, [getAllCustomers]);

  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(parseISO(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  const singleUserProfile = (singleId?: string, isNewTab?: boolean) => {
    if (singleId) {
      const path = `/customer-profile?id=${singleId}`;

      if (isNewTab) {
        // Open in new tab
        window.open(path, "_blank");
      } else {
        // Dispatch breadcrumb and navigate within the same tab
        dispatch(
          addBreadcrumb({
            ...BreadCrumbObject,
            url: path,
          })
        );
        navigate(path);
      }
    }
  };

  const handleSort = (field: string) => {
    const isAsc = orderBy === field && sortDirection === "asc";
    const newDirection = isAsc ? "desc" : "asc";

    const sorted = [...sortedData].sort((a, b) => {
      if (a[field] < b[field]) return newDirection === "asc" ? -1 : 1;
      if (a[field] > b[field]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortDirection(newDirection);
    setOrderBy(field);
  };

  if (sortedData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 bg-white rounded-md shadow-md mt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="120"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#0085FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="8" r="4" />
          <line x1="12" y1="12" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
          <line x1="10" y1="16" x2="10" y2="20" />
          <line x1="14" y1="16" x2="14" y2="20" />
        </svg>
        <p className="mt-4 text-lg text-gray-600">No customer data available</p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto rounded-md shadow-md mt-6">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-white">
          <tr>
            {[
              { label: "Name", field: "user_first_name" },
              { label: "Location", field: "user_location_area" },
              { label: "Reg Date & Time", field: "user_reg_date_time" },
              { label: "Last Use", field: "user_last_seen" },
            ].map((header) => (
              <th
                key={header.field}
                onClick={() => handleSort(header.field)}
                className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider cursor-pointer relative"
              >
                <div className="flex items-center">
                  {orderBy === header.field && (
                    <span
                      className={`mr-2 ${
                        sortDirection === "asc"
                          ? "text-gray-600"
                          : "text-gray-400"
                      }`}
                    >
                      <SortIcon />
                    </span>
                  )}
                  <span>{header.label}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedData.map((row: any, index: any) => (
            <tr key={index} className="">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-4 cursor-pointer"
                onClick={(e) => {
                  const isNewTab = e.ctrlKey || e.metaKey || e.shiftKey;
                  singleUserProfile(row?.user_user_id, isNewTab);
                }}
              >
                <img
                  src={`${BASE_IMAGE}${row?.user_profile_pic}`}
                  className="w-10 h-10 rounded-full"
                  alt=""
                />
                {row.user_first_name || "N/A"}
              </td>
              <td className="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                {row.user_location_area || "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(row.user_reg_date_time)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(row.user_last_seen)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllCustomerTable;
