import { useSelector } from "react-redux";
import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

const ActivityLogs = () => {
  const user = useSelector((state: any) => state?.AntoilerReview);

  const { data } = useQuery({
    queryKey: ["activity"],
    queryFn: () =>
      getData(
        `activity-log/get-activity-logs-by-user-id/${user?.data?.user_id}`
      ),
  });

  function formatDate(dateString: any) {
    // Parse the date string and format it as "d MMMM, h:mm a"
    return format(new Date(dateString), "d MMMM, h:mm a");
  }

  console.log("world", { data });

  return (
    <div className="mt-6 bg-white rounded-md shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="font-bold text-xl">Activity logs</h2>
            <p className="text-sm text-gray-500 mt-1">
              Check out the activities happening on the mobile app.
            </p>
          </div>
        </div>

        {data?.data?.map((item: any, i: any) => (
          <div className="bg-gray-50 rounded-md p-4 my-4">
            <div className="flex justify-between items-center mb-3">
              <p className="text-orange-500 font-semibold">
                {item?.type === "Profile Viewed"
                  ? "Profile Viewed"
                  : item?.type === "Profile Contacted!"
                  ? "Contacted an Antoiler"
                  : item?.type === "Profile Shared"
                  ? "Shared an Antoiler"
                  : item?.type === "Profile Hired!"
                  ? "Hired"
                  : item?.type === "Profile Wishlisted!"
                  ? "Profile Wishlisted"
                  : item?.type === "User Rate Updated!"
                  ? "User Rate Updated"
                  : item?.type === "Mobile Number Updated!"
                  ? "Mobile Number Updated"
                  : item?.type === "Feedback given!"
                  ? "Feedback Given"
                  : item?.type === "Visibility Toggled"
                  ? "Visibility Toggled"
                  : item?.type === "Hired an Antoiler!"
                  ? "Hired an Antoiler"
                  : item?.type === "Feedback Status Updated!"
                  ? "Feedback Status Updated"
                  : ""}
              </p>

              <p className="text-gray-500 text-sm">
                {formatDate(item?.datetime)}
              </p>
            </div>

            <div>
              <p className="text-[#7A7A7A] text-sm">{item?.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityLogs;
