import { useState } from "react";
import { ChevronRight, IndianRupee, X } from "lucide-react";
import RateTypeDropdown from "../../domains/AntoilerReview/components/RateTypeDropdown";
import { getData, postData } from "../../services/apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const AdminEditRateCard = ({ globalRateCard, refetch, onClick }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [openModal, setModalOpen] = useState(false);
  const [rateCardSno, setRateCardSno] = useState<number | undefined>(undefined);
  const [selectedProfession, setSelectedProfession] = useState<any>(null);

  // New states for form values, including "type"
  const [formValues, setFormValues] = useState({
    title: "",
    rate: "",
    description: "",
    type: "", // Make sure "type" is included
  });

  // Mutation for deleting the rate card
  const { mutate: deleteRateCard } = useMutation({
    mutationFn: () => {
      if (rateCardSno !== undefined) {
        return postData(`rate-card/delete-rate-card/${rateCardSno}`);
      }
      return Promise.reject("RateCardSno is undefined");
    },
    onSuccess: () => {
      toast.success("Rate card deleted successfully");
      refetch();
    },
    onError: (error) => {
      console.error("Error deleting rate card:", error);
    },
  });

  // Mutation for editing the rate card
  const { mutate: editRateCard } = useMutation({
    mutationFn: () =>
      postData(`rate-card/update-global-rate-card/${rateCardSno}`, {
        ...formValues,
        category_id: selectedProfession?.value,
      }), // formValues includes "type"
    onSuccess: () => {
      toast.success(`Update rate card`);
      refetch();
    },
  });

  const handleDelete = (sno: number) => {
    setRateCardSno(sno);
    deleteRateCard();
    refetch();
  };

  const handleEdit = () => {
    // Validate required fields
    const { title, rate, description, type } = formValues;

    if (!title || !rate || !description || !type || !selectedProfession) {
      toast.error("Please fill in all fields before saving.");
      return; // Exit if any field is empty
    }

    editRateCard(); // Trigger the mutation for editing
    handleModalClose(); // Close modal after editing
    refetch(); // Refetch the data after editing
  };

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleModalOpen = (
    sno: number,
    title: string,
    rate: string,
    description: string,
    type: string, // Now receiving "type"
    category_id: number // Also pass category_id
  ) => {
    setRateCardSno(sno);
    setFormValues({
      title,
      rate,
      description,
      type,
    });

    // Auto-fill the react-select value by finding the matching category
    const selectedCategory = getAllcategoriesValue.find(
      (cat: any) => cat.value === category_id
    );
    setSelectedProfession(selectedCategory || null); // Set the matching profession or null if not found

    console.log("Setting", title, rate, description, type, selectedCategory);
    setModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // This will handle the selection of the rate type in the dropdown
  const handleSelect = (option: string) => {
    console.log("Selected option:", option);
    setFormValues((prev) => ({ ...prev, type: option })); // Update formValues with the selected type
  };

  // This will handle input changes for title, rate, description
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  const getAllcategoriesValue =
    getAllcategories?.data?.data?.map((item: any) => ({
      label: item?.cat_title,
      value: item?.cat_id,
    })) || [];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove the border
      boxShadow: "none", // Remove the default focus outline (optional)
    }),
  };

  return (
    <>
      <div className="space-y-4 p-4 rounded-md">
        {globalRateCard?.data?.map((item: any, index: any) => (
          <div
            key={item.sno}
            className="border border-gray-200 rounded-md overflow-hidden bg-[#F4F4F4]"
          >
            <div className="flex items-center justify-between w-full p-4">
              <button
                className="flex items-center text-left flex-grow"
                onClick={() => handleToggle(index)}
              >
                <ChevronRight
                  className={`w-5 h-5 mr-3 transition-transform duration-200 ${
                    openIndex === index ? "transform rotate-90" : ""
                  }`}
                />
                <span className="flex-grow mr-2 truncate text-gray-900 font-semibold">
                  {item.title}
                </span>
              </button>
              <div className="flex items-center justify-center text-[#F24E1E]">
                <IndianRupee size={15} /> {item.rate} /
                <span className="text-[#F24E1E] font-semibold whitespace-nowrap">
                  <span className="text-black ml-2"> {item.type}</span>
                </span>
              </div>
            </div>
            {openIndex === index && (
              <div className="p-4 bg-white">
                <p className="text-[#7A7A7A]">{item.description}</p>
              </div>
            )}
            <div className="flex items-center gap-4 justify-start p-2">
              <button
                className="text-[#0085FF]"
                onClick={() =>
                  handleModalOpen(
                    item.sno,
                    item.title,
                    item.rate,
                    item.description,
                    item.type, // Passing type to the modal
                    item.category_id // Passing the category id
                  )
                }
              >
                Edit Card
              </button>

              <button
                className="text-[#FF0000]"
                onClick={() => handleDelete(item.sno)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Overlay */}
      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="relative bg-white px-8 py-8 rounded-md shadow-lg w-[40rem]">
            <button
              className="absolute top-2 right-4 text-black hover:text-gray-700"
              onClick={handleModalClose}
            >
              <X className="w-5 h-5" />
            </button>
            <div className="flex justify-between items-center px-2 pt-4">
              <p className="text-sm font-bold">Edit Rate Card</p>
              <button className="text-[#0085FF]" onClick={handleEdit}>
                Save
              </button>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              <div className="border border-gray-300 rounded-md p-2 w-full">
                <input
                  type="text"
                  name="title"
                  value={formValues.title}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium w-full outline-none border-none"
                  placeholder="Title Goes Here"
                />
              </div>
              <div className="border border-gray-300 rounded-md p-2 flex flex-row justify-between w-full">
                <input
                  type="text"
                  name="rate"
                  value={formValues.rate}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium w-full outline-none border-none"
                  placeholder="Rate Goes Here"
                />
                <RateTypeDropdown
                  onSelect={handleSelect}
                  selected={formValues.type} // Passing the selected type to the dropdown
                />
              </div>
              <div className="border border-gray-300 rounded-md p-2">
                <Select
                  options={getAllcategoriesValue}
                  value={selectedProfession} // This will now be auto-filled with the selected profession
                  onChange={(option: any) => setSelectedProfession(option)}
                  placeholder="Select Category"
                  className="w-full rounded-md focus:outline-none"
                  styles={customStyles} // Apply custom styles
                />
              </div>
              <div className="border border-gray-300 rounded-md p-2">
                <textarea
                  name="description"
                  value={formValues.description}
                  onChange={handleInputChange}
                  className="p-1 text-black placeholder:text-black placeholder:font-medium h-52 resize-none w-full outline-none border-none"
                  placeholder="Description Goes Here"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminEditRateCard;
