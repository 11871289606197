import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify"; // For notifications, if you're using react-toastify
import { postData } from "../../../services/apiService";
import { useSelector } from "react-redux";

const AddRateCard = ({ refetch }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      rate: "",
      type: "",
    },
  });

  const user: any = useSelector((state: any) => state.AntoilerReview);

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => postData(`/rate-card/create-new-rate-card`, data),
    onSuccess: () => {
      toast.success("Rate card created successfully");
      refetch();
      reset();
    },
    onError: (error) => {
      toast.error("Error creating rate card");
      //   console.error("Error creating rate card:", error);
    },
  });

  const onSubmit = (data: any) => {
    mutate({ ...data, user_id: user?.data?.user_id });
  };

  return (
    <div className="bg-white p-4 rounded-lg border-gray-200 border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Title</label>
          <input
            {...register("title", { required: "Title is required" })}
            type="text"
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.title ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter title"
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">
            Description
          </label>
          <textarea
            {...register("description", {
              required: "Description is required",
            })}
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.description ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">
              {errors.description.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Rate</label>
          <input
            {...register("rate", { required: "Rate is required" })}
            type="text"
            className={`mt-1 block w-full p-2 bg-gray-100 rounded-md ${
              errors.rate ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter rate"
          />
          {errors.rate && (
            <p className="text-red-500 text-sm mt-1">{errors.rate.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-[#8E8E8E] font-medium">Type</label>
          <select
            {...register("type", { required: "Type is required" })}
            className={`mt-1 block w-full p-2 bg-white border ${
              errors.type ? "border-red-500" : "border-gray-300"
            } rounded-md`}
          >
            <option value="">Select Rate Type</option>
            <option value="Per Hour">Per Hour</option>
            <option value="Per Month">Per Month</option>
            <option value="Per Day">Per Day</option>
            <option value="Per Service">Per Service</option>
          </select>
          {errors.type && (
            <p className="text-red-500 text-sm mt-1">{errors.type.message}</p>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-[#F24E1E] text-white p-2 rounded-md"
            disabled={isPending}
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>

      {/* Uncomment this section if you want to display error or success messages */}
      {/* 
      {mutation.isError && (
        <p className="text-red-500 text-sm mt-2">
          An error occurred. Please try again.
        </p>
      )}
      {mutation.isSuccess && (
        <p className="text-green-500 text-sm mt-2">
          Rate card added successfully!
        </p>
      )} */}
    </div>
  );
};

export default AddRateCard;
