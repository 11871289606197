import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import { format, parseISO } from "date-fns";
import Button from "../../../domains/Dashboard/components/Button";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../common/components/Pagination";
import Loader from "../../../common/Loader/Loader";
import AdvancedFilterInMobile from "../components/AdvanceFilterInMobile";
import MobileSearch from "../components/MobileSearch";

const AllCustomerListViewMobile = () => {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState<any>("");
  const [selectedLocality, setSelectedLocality] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [categoryId, setCategoryId] = useState<any>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [registerationDate, setRegisterationDate] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "antoiler-data",
      currentPage,
      itemsPerPage,
      selectedGender,
      selectedLocality,
      selectedStatus,
      searchTerm,
      registerationDate,
      categoryId, // Add categoryId as part of the queryKey to trigger refetch when it changes
    ],
    queryFn: () => {
      let queryString = `user/get-all-antoilers?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}&gender=${selectedGender}&location_area=${selectedLocality
        .map((loc: any) => loc?.value)
        .join(",")}&profile_status=${selectedStatus}`;

      // Only add registrationDate if it is defined, not null, and not an empty string
      if (registerationDate) {
        queryString += `&reg_date=${registerationDate.toISOString()}`;
      }

      // Only add category_id to the query string if categoryId is defined
      if (categoryId?.value) {
        queryString += `&category_id=${categoryId?.value}`;
      }

      return getData(queryString);
    },
  });

  const handleApplyFilters = (
    gender: string,
    locality: any[],
    status: string,
    categoryId: any,
    registerationDate: any
  ) => {
    setSelectedGender(gender);
    setSelectedLocality(locality);
    setSelectedStatus(status);
    setCategoryId(categoryId);
    setRegisterationDate(registerationDate);
    setCurrentPage(1);
    refetch(); // Refetch the data after applying the filters
  };

  const clearFilter = () => {
    setSelectedGender("");
    setSelectedLocality([]);
    setSelectedStatus("");
    setCurrentPage(1);
    setCategoryId("");
    setRegisterationDate("");
    refetch(); // Refetch the data after clearing the filters
  };

  const formatDate = (isoDateString: string) => {
    const parsedDate = parseISO(isoDateString);
    return format(parsedDate, "dd MMM yyyy"); // Customize the format as needed
  };

  const formatTime = (isoDateString: string | undefined) => {
    if (!isoDateString) return "Invalid Date";
    const parsedDate = parseISO(isoDateString);
    if (isNaN(parsedDate.getTime())) return "Invalid Date";

    return format(parsedDate, "hh:mm a");
  };

  const handleClickBack = () => {
    navigate("/antoiler-create-account");
  };

  const totalItems = data?.data?.totalResults || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate total pages based on total items and items per page

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  return (
    <div className="">
      <div className="flex justify-center mb-4">
        <img src="./logo.png" alt="" className="w-56" />
      </div>
      <p className="block md:hidden text-center font-bold text-3xl md:text-xl mb-4">
        Antoiler<span className="text-orange-500"> List</span>
      </p>
      <div className="flex justify-between items-center mb-6">
        <Button
          label="Back"
          onClick={handleClickBack}
          style={{ color: "blue" }}
          className="rounded-lg flex items-center mt-10"
          iconPosition="left"
        />
        <AdvancedFilterInMobile
          onApplyFilters={handleApplyFilters}
          clearFilter={clearFilter}
        />
      </div>
      <div className="my-6">
        <MobileSearch onChange={handleSearch} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="overflow-x-auto mb-6">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider font-semibold">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.data?.data && data.data.data.length > 0 ? (
                  data.data.data.map((row: any, index: any) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row?.user_first_name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row?.category_cat_title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(row?.user_reg_date_time)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatTime(row?.user_reg_date_time)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Component */}
          {totalPages > 1 && ( // Only show pagination if there are more than 1 page
            <div className="flex justify-end mt-4 w-full">
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllCustomerListViewMobile;
