import React, { useState } from "react";
import { ChevronDownIcon, XIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import {
  Select as SingleSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

const AllFeedbackFilter: React.FC<any> = ({
  filterfeedbackStatus,
  handleClearFilter,
  setStartDate, // Added prop for setting start date
  setEndDate, // Added prop for setting end date
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [appliedStatus, setAppliedStatus] = useState("");
  const [startDate, setStartDateLocal] = useState<any>(null);
  const [endDate, setEndDateLocal] = useState<any>(null);

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };

  const handleApplyFilter = () => {
    setAppliedStatus(selectedStatus);
    filterfeedbackStatus(selectedStatus);
    if (startDate && endDate) {
      setStartDate(startDate); // Pass selected start date to parent
      setEndDate(endDate); // Pass selected end date to parent
    }
    handleModalToggle();
  };

  const clearFilter = () => {
    setSelectedStatus("");
    setAppliedStatus("");
    setStartDateLocal(null);
    setEndDateLocal(null);
    setStartDate(null); // Clear the date in parent
    setEndDate(null); // Clear the date in parent
    filterfeedbackStatus("");
    handleClearFilter();
  };

  // Show clear button only if there is an applied status or selected date range
  const showClearButton = appliedStatus || (startDate && endDate);
  console.log(moment().format("YYYY-MM-DD"));

  return (
    <>
      <div className="mt-10 flex justify-end">
        {showClearButton && (
          <Button
            variant="ghost"
            className="text-blue-500 font-semibold"
            onClick={clearFilter}
          >
            Clear
          </Button>
        )}

        <Button
          variant="ghost"
          className="text-orange-500 font-semibold"
          onClick={handleModalToggle}
        >
          Advanced Filter
          <ChevronDownIcon size={20} className="ml-2" />
        </Button>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="font-semibold">Advanced Filter</p>
            <p
              onClick={handleApplyFilter}
              className="ml-auto cursor-pointer text-blue-500"
            >
              Apply
            </p>
          </div>

          {/* Status Filter */}
          <div className="mt-4">
            <SingleSelect
              onValueChange={handleStatusChange}
              value={selectedStatus}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Reviews" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Pending</SelectItem>
                <SelectItem value="1">Published</SelectItem>
                <SelectItem value="2">Denied</SelectItem>
              </SelectContent>
            </SingleSelect>
          </div>

          {/* Date Range Picker */}
          <div className="mt-4 w-full relative">
            <DatePicker
              maxDate={moment().format("YYYY-MM-DD")}
              range
              value={[startDate, endDate]}
              onChange={(dates: any) => {
                if (dates && dates.length === 2) {
                  const [start, end] = dates;
                  setStartDateLocal(start);
                  setEndDateLocal(end);
                } else {
                  setStartDateLocal(null);
                  setEndDateLocal(null);
                }
              }}
              placeholder="Select a date range"
              style={{
                backgroundColor: "white",
                width: "257%", // Full width
                height: "40px",
                borderRadius: "5px",
                fontSize: "14px",
                padding: "10px",
              }}
            />

            {startDate && endDate && (
              <XIcon
                className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                onClick={() => {
                  setStartDateLocal(null);
                  setEndDateLocal(null);
                }}
                size={20}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllFeedbackFilter;
