import React, { useEffect, useState } from "react";
import CategoryCreateForm from "../components/CategoryCreateForm";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";
import SearchBar from "../../../common/SearchBar/SearchBar";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import ExpandableTable from "../components/ExpandableTable";
import Pagination from "../../../common/components/Pagination";

const CreateCategory: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch();
  };

  // Fetch published parent categories for the form's dropdown
  const { data: publishedParentCategoryData } = useQuery({
    queryKey: ["getPublishedParentCategory"],
    queryFn: () => getData("category/get-published-parent-categories"),
  });

  const handleSearchBar = (value: string) => {
    console.log("categories", value);
    setSearchTerm(value);
  };

  // Fetch categories with child categories for the expandable table
  const {
    data: parentCategoriesWithChildData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getParentCategoriesWithChildCategory", searchTerm, currentPage],
    queryFn: () =>
      getData(
        `category/get-parent-categories-with-child-category?search_term=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}`
      ),
  });

  return (
    <>
      {/* Search bar */}
      <SearchBar
        onChange={handleSearchBar}
        placeholder="Search based on category"
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-hidden">
          {/* Button to open modal */}
          <div className="flex justify-end items-end">
            <Button
              onClick={handleModalToggle}
              className="bg-orange-500 text-white mt-4"
            >
              Create New Category
            </Button>
          </div>

          {/* Modal for creating new category */}
          <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
            <DialogContent className="max-w-2xl h-[40vh] flex items-center justify-center">
              <CategoryCreateForm
                parentCategory={publishedParentCategoryData?.data}
                isModalOpen={isModalOpen}
                closeModal={() => {
                  setModalOpen(false);
                  refetch(); // Refetch data after creating a category
                }}
                refetch={refetch}
              />
            </DialogContent>
          </Dialog>

          {/* Expandable Table */}
          <ExpandableTable
            getParentCategoriesWithChildCategory={
              parentCategoriesWithChildData?.data
            }
            searchTerm={searchTerm}
            refetch={refetch}
          />

          {/* Pagination */}
          {parentCategoriesWithChildData?.data?.totalResults && (
            <Pagination
              totalItems={parentCategoriesWithChildData?.data?.totalResults}
              itemsPerPage={Number(parentCategoriesWithChildData?.data?.limit)}
              currentPage={Number(parentCategoriesWithChildData?.data?.page)}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CreateCategory;
