import { useNavigate } from "react-router-dom";
import AdminRateCardAccordian from "./AdminRateCardAccordian";
import { useState } from "react";
import AdminEditRateCard from "./AdminEditRateCard";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../services/apiService";

const AdminRateCard = () => {
  const [tab, setTab] = useState<string | null>(null); // Track the current tab state
  const navigate = useNavigate();

  const { data, refetch } = useQuery({
    queryKey: ["AdminRateCard"],
    queryFn: () => getData("rate-card/get-all-global-rate-cards"),
  });

  // Navigate to "add card" route
  const handleAddCard = () => {
    navigate("/admin-add-rate-card");
  };

  // Switch to "edit-card" tab
  const handleNavigate = () => {
    setTab("edit-card");
  };

  // Switch back to default accordion view
  const handleBack = () => {
    setTab(null); // Setting tab to null will go back to the default view
  };

  return (
    <>
      <div className="flex justify-end gap-4 mb-4">
        {/* Add Card Button */}
        <p className="text-orange-500 cursor-pointer" onClick={handleAddCard}>
          Add Card
        </p>

        {/* Conditionally render "Edit Card" or "Back" based on the current tab */}
        {tab === "edit-card" ? (
          <p className="text-blue-500 cursor-pointer" onClick={handleBack}>
            Back
          </p>
        ) : (
          <p className="text-blue-500 cursor-pointer" onClick={handleNavigate}>
            Edit Card
          </p>
        )}
      </div>

      {/* Conditionally render components based on the tab state */}
      {tab === "edit-card" ? (
        <AdminEditRateCard globalRateCard={data} refetch={refetch} />
      ) : (
        <AdminRateCardAccordian globalRateCard={data} />
      )}
    </>
  );
};

export default AdminRateCard;
