import { RouteDefinition } from "../common/types";
import Dashboard from "../domains/Dashboard/pages/Dashboard";
import AntoilerReview from "../domains/AntoilerReview/pages/AntoilerReview";
import AllAntoiler from "../domains/AllAntoiler/Pages/AllAntoiler";
import AllCustomer from "../domains/AllCustomer/pages/AllCustomer";
import SingleCustomerProifile from "../domains/AllCustomer/components/CustomerReview";
import AntoilerCreateAccount from "../domains/AntoilerCreateAccount/pages/AntoilerCreateAccount";
import UserFeedback from "../domains/AllAntoiler/Pages/UserFeedback";
import EditFeedBack from "../domains/AllAntoiler/Pages/EditFeedBack";
import Slider from "../domains/Slider/page/Slider";
import AllCustomerListViewMobile from "../domains/AllCustomer/pages/AllCustomerListViewMobile";
import AllFeedback from "../domains/Dashboard/pages/AllFeedback";
import ParticularFeedbackEdit from "../domains/Dashboard/components/ParticularFeedbackEdit";
import RateCard from "../domains/RateCard/AdminRateCard";
import AdminAddRateCard from "../domains/RateCard/AdminAddRateCard";
import Support from "../domains/Support/page/Support";
import Report from "../domains/Report/page/Report";
import Answer from "../domains/Answer/page/Answer";
import CreateCategory from "../domains/createCategory/page/CreateCategory";
import ChildCategoryView from "../domains/createCategory/page/ChildCategoryView";

export const routeDefinitions: RouteDefinition[] = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/all-antoilers", element: <AllAntoiler /> },
  { path: "/all-users", element: <AllCustomer /> },
  { path: "/reviews-management", element: <Dashboard /> },
  { path: "/antoiler-reviews", element: <AntoilerReview /> },
  { path: "/customer-profile", element: <SingleCustomerProifile /> },
  { path: "/all-rate-cards", element: <RateCard /> },
  { path: "/all-sliders", element: <Slider /> },
  { path: "/antoiler-create-account", element: <AntoilerCreateAccount /> },
  { path: "/antoiler-feedback", element: <UserFeedback /> },
  { path: "/edit-feedback", element: <EditFeedBack /> },
  { path: "/all-feedbacks", element: <AllFeedback /> },
  { path: "/particular-feedback-edit", element: <ParticularFeedbackEdit /> },
  { path: "/admin-add-rate-card", element: <AdminAddRateCard /> },
  {
    path: "/all-customer-list-view-mobile",
    element: <AllCustomerListViewMobile />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/answer",
    element: <Answer />,
  },
  {
    path: "/all-category",
    element: <CreateCategory />,
  },
  {
    path: "/child-category",
    element: <ChildCategoryView />,
  },
];
