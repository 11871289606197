import React, { useState } from "react";
import { Link } from "react-router-dom";
import RatingStars from "../../../common/Rating/Rating";
import { BASE_IMAGE, getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import { ChevronRight, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import Loader from "../../../common/Loader/Loader";

const Feedback: React.FC = () => {
  const [reviewID, setReviewID] = useState<number | null>(null);
  const [showMedia, setShowMedia] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<{
    url: string;
    type: "image" | "video";
  } | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useQuery({
    queryKey: ["recentReviews"],
    queryFn: () => getData("review/get-recent-reviews"),
  });

  const { data: getMediaByReviewId, isLoading } = useQuery({
    queryKey: ["getMediaByReviewId", reviewID],
    queryFn: () =>
      reviewID
        ? getData(`media/get-media-by-review-id/${reviewID}`)
        : Promise.resolve(null),
    enabled: !!reviewID,
  });

  const viewPhotos = (id: number) => {
    setReviewID(id);
    setShowMedia((prevState) => !prevState);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const isImage = (url: string) => /\.(jpg|jpeg|png|gif)$/i.test(url);
  const isVideo = (url: string) => /\.(mp4|mov|avi)$/i.test(url);

  const BreadCrumbObject: any = {
    title: "See All Feedback",
    url: "all-feedbacks",
  };

  const handleDispatch = () => {
    dispatch(addBreadcrumb(BreadCrumbObject));
  };

  const openMediaModal = (mediaUrl: string, mediaType: "image" | "video") => {
    setSelectedMedia({ url: mediaUrl, type: mediaType });
  };

  const closeMediaModal = () => {
    setSelectedMedia(null);
  };

  return (
    <div className="bg-white border rounded-lg p-6 w-full h-auto mt-8">
      <div className="flex justify-between px-3">
        <span className="mb-4">
          <h2 className="text-lg font-semibold mb-2">Recent Feedback</h2>
          <h3 className="text-gray-500 text-sm">
            Feedback that needs to be approved based on factssss
          </h3>
        </span>
        <span>
          <Link
            to={"/all-feedbacks"}
            className="text-[#F15A24] flex items-center text-sm whitespace-nowrap"
            onClick={handleDispatch}
          >
            See All Feedback
            <div className="ml-2">
              <svg
                width="7"
                height="9"
                viewBox="0 0 7 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.74414 0.844339L5.2142 4.3144L1.74414 7.78445"
                  stroke="#F15A24"
                  strokeWidth="1.62236"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
        </span>
      </div>

      {data?.data?.length ? (
        data?.data?.map((item: any) => (
          <div key={item.review_id} className="border p-7 rounded-lg mb-4">
            <div className="flex items-start justify-between mb-2">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <RatingStars
                    count={5}
                    value={item.review_overall_review_rate}
                    size={21}
                    color2={"#F24E1E"}
                    edit={false}
                  />
                  <span className="ml-2 font-medium">
                    {item.user_first_name}
                  </span>
                </div>
                <span className="text-sm text-gray-500 mt-1">
                  {formatDate(item.review_rev_date_time)}
                </span>
              </div>
            </div>
            <p className="text-gray-500 text-sm">{item.review_review_txt}</p>

            <div onClick={() => viewPhotos(item.review_review_id)}>
              <Link
                to={""}
                className="text-[#4F46E5] text-sm flex items-center whitespace-nowrap"
              >
                View Photos
                <div className="ml-2">
                  <ChevronRight style={{ color: "#4F46E5" }} size={15} />
                </div>
              </Link>
            </div>

            {showMedia && reviewID === item.review_review_id && (
              <div className="mt-4 p-4 ">
                <div className="flex flex-wrap gap-4">
                  {isLoading ? (
                    <Loader />
                  ) : getMediaByReviewId?.data?.length ? (
                    getMediaByReviewId.data.map((mediaItem: any) => {
                      const mediaUrl = `${BASE_IMAGE}/${mediaItem.normal_img}`;
                      if (isImage(mediaItem.normal_img)) {
                        return (
                          <div
                            key={mediaItem.media_id}
                            className="w-32 h-32 bg-gray-200 rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => openMediaModal(mediaUrl, "image")}
                          >
                            <img
                              src={mediaUrl}
                              alt="Review media"
                              className="object-cover w-full h-full"
                            />
                          </div>
                        );
                      } else if (isVideo(mediaItem.normal_img)) {
                        return (
                          <div
                            key={mediaItem.media_id}
                            className="w-32 h-32 bg-gray-200 rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => openMediaModal(mediaUrl, "video")}
                          >
                            <video
                              src={mediaUrl}
                              poster={`${BASE_IMAGE}${mediaItem.thumb_img}`}
                              className="object-cover w-full h-full"
                            />
                          </div>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <p className="text-gray-500 text-sm">No media available</p>
                  )}
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="text-gray-500 text-sm">No feedback available.</p>
      )}

      {selectedMedia && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative max-w-3xl max-h-3xl">
            {selectedMedia.type === "image" ? (
              <img
                src={selectedMedia.url}
                alt="Enlarged view"
                className="w-full h-96 rounded-md object-contain"
              />
            ) : (
              <video
                src={selectedMedia.url}
                controls
                className="w-full h-96 rounded-md"
              />
            )}
            <button
              onClick={closeMediaModal}
              className="absolute -top-2 -right-2 text-white bg-gray-800 rounded-full p-2"
            >
              <X size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
