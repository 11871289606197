import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Dialog, DialogContent, DialogTitle } from "../../components/ui/dialog";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../services/apiService";
import { toast } from "react-toastify";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";

const SupportTicketModal = ({ isOpen, onClose, ticket, refetch }: any) => {
  // Define all possible status options
  const allStatusOptions = [
    { label: "Open", value: 1, isDisabled: true }, // "Open" is disabled
    { label: "In Progress", value: 2 },
    { label: "Resolved", value: 3 },
    { label: "Closed", value: 4 },
  ];

  // States to handle current status and note
  const [status, setStatus] = useState<any>(null);
  const [statusNote, setStatusNote] = useState<string>("");
  const [statusOptions, setStatusOptions] = useState<any[]>([]);

  // Function to format date strings
  const formatDate = (dateString: string | undefined) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  // Update status options based on the current ticket status
  useEffect(() => {
    if (ticket) {
      const selectedStatus = allStatusOptions.find(
        (option) => option.value === ticket.ticket_status
      );
      setStatus(selectedStatus);

      // Set available status options based on ticket's current status
      switch (ticket.ticket_status) {
        case 1: // "Open" shows all three options
          setStatusOptions(allStatusOptions);
          break;
        case 2: // "In Progress" shows "Resolved" and "Closed"
          setStatusOptions(
            allStatusOptions.filter((option) => option.value > 2)
          );
          break;
        case 3: // "Resolved" shows only "Closed"
          setStatusOptions(
            allStatusOptions.filter((option) => option.value === 4)
          );
          break;
        case 4: // "Closed" no further options
          setStatusOptions([]);
          break;
        default:
          setStatusOptions([]);
      }
    }
  }, [ticket]);

  // Mutation to handle status update
  const { mutate, isPending } = useMutation({
    mutationFn: (data: any) =>
      postData(`support/update-support-status/${ticket.support_id}`, data),
    onSuccess: () => {
      toast.success("Ticket status updated successfully");
      refetch();
      onClose(); // Close the modal on success
    },
    onError: () => {
      toast.error("Failed to update ticket status");
    },
  });

  // Submit handler for status change
  const handleSubmit = () => {
    // If status is Resolved or Closed, make note mandatory
    if ((status?.value === 3 || status?.value === 4) && !statusNote) {
      toast.error(
        "Please provide a status note for Resolved or Closed status."
      );
      return;
    }

    mutate({
      ticket_status: status?.value,
      note: statusNote,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>Support Ticket</DialogTitle>
        {ticket ? (
          <Tabs defaultValue="supportDetails" className="w-full">
            <TabsList>
              <TabsTrigger value="supportDetails">Support Details</TabsTrigger>
              <TabsTrigger value="changeStatus">Change Status</TabsTrigger>
              <TabsTrigger value="statuslogs">Status Logs</TabsTrigger>
            </TabsList>

            {/* Support Details Tab */}
            <TabsContent value="supportDetails">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Name:
                  </label>
                  <input
                    type="text"
                    value={ticket.name || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Phone:
                  </label>
                  <input
                    type="text"
                    value={ticket.phone || "N/A"}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Query:
                  </label>
                  <textarea
                    value={ticket.query || "N/A"}
                    readOnly
                    rows={4}
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Date:
                  </label>
                  <input
                    type="text"
                    value={formatDate(ticket.datetime)}
                    readOnly
                    className="border border-gray-300 rounded-md p-2 w-full bg-gray-100"
                  />
                </div>
              </div>
            </TabsContent>

            {/* Change Status Tab */}
            <TabsContent value="changeStatus">
              <div className="space-y-4">
                {/* Display current status */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Current Status:
                  </label>
                  <div className="border border-gray-300 rounded-md p-2 w-full bg-gray-100">
                    {status?.label || "N/A"}
                  </div>
                </div>

                {/* Select for changing status */}
                {statusOptions.length > 0 && (
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Change Status:
                    </label>
                    <Select
                      options={statusOptions}
                      value={status}
                      onChange={(selected) => {
                        setStatus(selected);
                        setStatusNote(""); // Clear note when status changes
                      }}
                      className="border border-gray-300 rounded-md"
                    />
                  </div>
                )}

                {/* Status Note (mandatory for Resolved/Closed and not closed) */}
                {(status?.value === 3 || status?.value === 4) &&
                  ticket.ticket_status !== 4 && (
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Status Note:
                      </label>
                      <textarea
                        value={statusNote}
                        onChange={(e) => setStatusNote(e.target.value)}
                        rows={4}
                        placeholder="Add a note about this status change"
                        className="border border-gray-300 rounded-md p-2 w-full"
                        required
                      />
                    </div>
                  )}

                {/* Submit button */}
                {statusOptions.length > 0 && (
                  <button
                    onClick={handleSubmit}
                    disabled={isPending}
                    className={`mt-4 w-full ${
                      isPending ? "bg-gray-400" : "bg-red-600"
                    } text-white rounded-md py-2 hover:bg-red-700`}
                  >
                    {isPending ? "Updating..." : "Submit"}
                  </button>
                )}
              </div>
            </TabsContent>

            {/* Status Logs Tab */}
            <TabsContent value="statuslogs">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Status Logs</h3>
                <div className="border rounded-md p-4 bg-gray-50">
                  {ticket.closed_note && (
                    <div className="mb-2">
                      <div className="font-bold">Closed:</div>
                      <div>
                        {formatDate(ticket.closed_date)} - {ticket.closed_note}
                      </div>
                    </div>
                  )}
                  {ticket.resolved_note && (
                    <div className="mb-2">
                      <div className="font-bold">Resolved:</div>
                      <div>
                        {formatDate(ticket.resolved_date)} -{" "}
                        {ticket.resolved_note}
                      </div>
                    </div>
                  )}
                  {ticket.ticket_status === 2 && (
                    <div className="mb-2">
                      <div className="font-bold">In Progress:</div>
                      <div>{formatDate(ticket.inprogress_date)}</div>
                    </div>
                  )}
                  {ticket.ticket_status === 1 && (
                    <div className="mb-2">
                      <div className="font-bold">Open:</div>
                      <div>{formatDate(ticket.datetime)}</div>
                    </div>
                  )}
                </div>
              </div>
            </TabsContent>
          </Tabs>
        ) : (
          <p>No ticket details available.</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SupportTicketModal;
