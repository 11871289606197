import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { BASE_IMAGE, getData } from "../../../services/apiService";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../common/Slice/BreadcrumbsSlice";

interface User {
  name: string;
  address: string;
  date: string;
  time: string;
  profile_pic: string | null;
  location_area: string | null;
}

const RecentAddedUser: React.FC = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useQuery({
    queryKey: ["recentUsers"],
    queryFn: () => getData("user/get-recent-users"),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  const users: User[] = data?.data.map((user: any) => ({
    name: user.first_name + " " + (user.last_name || ""),
    address: user.address,
    date: new Date(user.reg_date_time).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    }),
    time: new Date(user.reg_date_time).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    }),
    profile_pic: user?.profile_pic,
    location_area: user.location_area,
  }));

  const getInitials = (name: string) => {
    return name
      .split(" ")
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  const BreadCrumbObject: any = {
    title: "All Users",
    url: "/all-users",
  };

  const handleDispatch = () => {
    dispatch(setBreadcrumbs(BreadCrumbObject));
  };

  return (
    <div className="max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto bg-white rounded-lg overflow-hidden border border-gray-200">
      <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
        <h2 className="text-md font-semibold text-gray-800 mb-1">
          Recent Added Users
        </h2>
        <p className="text-sm text-gray-600 mb-4">
          Users who joined antoiler app this month
        </p>
        <div className="space-y-4">
          {users.map((user, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-start sm:items-center justify-between"
            >
              {/* {JSON.stringify(user)} */}
              <div className="flex items-center space-x-4 mb-2 sm:mb-0">
                <div className="flex-shrink-0">
                  {user?.profile_pic ? (
                    <img
                      src={`${BASE_IMAGE}${user?.profile_pic}`}
                      alt={`${user.name}'s profile`}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold text-lg">
                      {getInitials(user.name)}
                    </div>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-sm font-medium text-gray-900 truncate">
                    {user.name}
                  </h3>
                  <p className="text-sm text-gray-500 break-words">
                    {user.location_area}
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0 text-left sm:text-right mt-2 sm:mt-0">
                <p className="text-sm font-medium text-gray-900">{user.date}</p>
                <p className="text-xs text-gray-500">{user.time}</p>
              </div>
            </div>
          ))}
        </div>

        <Link to="/all-users" className="block mt-8">
          <div
            className="flex items-center justify-center sm:justify-start"
            onClick={handleDispatch}
          >
            <p className="text-sm tracking-wider text-[#151518]">
              SEE ALL CUSTOMERS
            </p>
            <IoIosArrowForward style={{ color: "#151518" }} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default RecentAddedUser;
