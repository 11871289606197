import React, { useState } from "react";
import Select from "react-select";
import { ChevronDownIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import {
  Select as SingleSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { getData } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

const AdvancedFilterInMobile = ({ onApplyFilters, clearFilter }: any) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLocality, setSelectedLocality] = useState<any>([]);
  const [selectedProfession, setSelectedProfession] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>(undefined);
  const [clearFilterShow, setClearFilterShow] = useState(false);

  const handleModalToggle = () => setModalOpen(!isModalOpen);

  const handleApplyFilter = () => {
    if (
      selectedLocality.length > 0 ||
      selectedProfession.length > 0 ||
      selectedStatus !== "" ||
      selectedGender !== "" ||
      selectedDate !== undefined
    ) {
      setClearFilterShow(true); // Set true if any value is selected
    }
    onApplyFilters(
      selectedGender,
      selectedLocality,
      selectedStatus,
      selectedProfession,
      selectedDate
    );
    handleModalToggle();
  };

  const handleClearFilter = () => {
    setClearFilterShow(false);
    setSelectedGender("");
    setSelectedLocality([]);
    setSelectedProfession([]);
    setSelectedStatus("");
    setSelectedDate("");
    clearFilter(); // Call the parent component's clear filter logic
  };

  const { data: getAllLocation } = useQuery({
    queryKey: ["getAllLocation"],
    queryFn: () => getData("user/get-all-locations"),
  });
  const { data: getAllcategories } = useQuery({
    queryKey: ["getAllcategories"],
    queryFn: () => getData("category/get-all-categories"),
  });

  const locationOptions =
    getAllLocation?.data?.map((location: any) => ({
      label: location,
      value: location,
    })) || [];

  const getAllcategoriesValue =
    getAllcategories?.data?.data?.map((item: any) => ({
      label: item?.cat_title,
      value: item?.cat_id,
    })) || [];

  console.log(selectedDate);

  return (
    <>
      <div className="mt-10 flex justify-between items-center">
        <div className="flex gap-2 items-center">
          {clearFilterShow && (
            <p
              className="text-blue-600 font-semibold cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filter
            </p>
          )}
          <Button
            variant="ghost"
            className="text-orange-500 font-semibold"
            onClick={handleModalToggle}
          >
            Advanced Filter
            <ChevronDownIcon size={20} className="ml-2" />
          </Button>
        </div>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <div className="flex justify-between items-center mt-4">
            <p className="font-semibold">Advanced Filter</p>
            <p
              onClick={handleApplyFilter}
              className="ml-auto cursor-pointer text-[#0085FF]"
            >
              Apply Filter
            </p>
          </div>

          {/* Multi-select with search for Locality */}
          <div className="mt-4">
            <Select
              isMulti
              options={locationOptions}
              value={selectedLocality}
              onChange={setSelectedLocality}
              placeholder="Filter By Locality"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
            />
          </div>

          {/* Profession select */}
          <div className="mt-4">
            <Select
              options={getAllcategoriesValue}
              value={selectedProfession}
              onChange={(option) => setSelectedProfession(option)}
              placeholder="Filter By Profession"
              className="w-full border border-gray-300 rounded-md focus:outline-none"
            />
          </div>

          {/* Single select for Status */}
          <div className="mt-4">
            <SingleSelect
              onValueChange={setSelectedStatus}
              value={selectedStatus}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Current Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Pending</SelectItem>
                <SelectItem value="1">Verify</SelectItem>
                <SelectItem value="2">Published</SelectItem>
                <SelectItem value="3">Unpublished</SelectItem>
                <SelectItem value="4">Blocked</SelectItem>
                <SelectItem value="5">Deactivate</SelectItem>
              </SelectContent>
            </SingleSelect>
          </div>

          {/* Single select for Gender */}
          <div className="mt-4">
            <SingleSelect
              onValueChange={setSelectedGender}
              value={selectedGender}
            >
              <SelectTrigger className="w-full border border-gray-300 rounded-md focus:outline-none">
                <SelectValue placeholder="Filter By Gender" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="m">Male</SelectItem>
                <SelectItem value="f">Female</SelectItem>
              </SelectContent>
            </SingleSelect>
          </div>

          {/* Date input field */}
          <div className="mt-4">
            <input
              type="date"
              placeholder="Filter By Date"
              className="w-full p-2 bg-white rounded-md text-gray-900 outline-none border border-gray-300"
              value={selectedDate ? format(selectedDate, "yyyy-MM-dd") : ""}
              onChange={(e) => {
                const date = new Date(e.target.value);
                if (!isNaN(date.getTime())) {
                  setSelectedDate(date);
                } else {
                  setSelectedDate(undefined);
                }
              }}
              max={new Date().toISOString().split("T")[0]} // Restrict future dates
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdvancedFilterInMobile;
